<template v-if="loaded">
  <div id="wrote_thanks">
    <h3 class="title title_wrote" v-html="$store.students_working_title"></h3>
    <carousel
      :perPageCustom="[
        [300, 2],
        [1024, 7],
      ]"
      :mouse-drag="true"
      :loop="true"
      :navigationEnabled="true"
      :navigationNextLabel="'<div class=right></div>'"
      :navigationPrevLabel="'<div class=left></div>'"
    >
      <slide v-for="data in logos" :key="data.id" class="item">
        <div
          class="pic"
          :style="'background-image:url(' + data.pic_parsed + ')'"
        ></div>
      </slide>
    </carousel>
    <div class="clr"></div>
  </div>
</template>
<script>
export default {
  components: {},
  props: ["logos"],
  data() {
    return {
      loaded:false,
    };
  },
};
</script>
<style lang="scss" scoped>
.item {
  width: 100%;
}
.pic {
  height: 82px;
  background-size: contain;
  background-position: center;
  margin-left: 50px;
  width: 145px;
}
#wrote_thanks {
  & {
    padding: 20px 30px 10px 30px;
    background-image: url(../assets/wrote.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 230px;
  }
  .title_wrote {
    font-size: 28px !important;
    font-weight: 600 !important;
    padding-bottom: 0px;
    color: #4e5074;
  }
}
@media (max-width: 600px) {
  .centered-big {
    max-width: calc(100% - 10px) !important;
  }
}

@media only screen and (max-width: 360px) {
  #wrote_thanks {
    padding: 20px 0 10px;
    .title_wrote{font-size: 25px!important;}
  }
}
</style>
