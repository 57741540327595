<template v-if="loaded">
    <div id="thanks">
        <div class="header">
            <div id="logo_hero"></div>
        </div>
        <div id="thanks_hero">
            <h1>We will speak soon !</h1>
            <div id="man"></div>
        </div>
        <div id="intro">
            <h3>Introduction to the course</h3>
           <div id="vid">
                <Video :video_url="'0_yj8ltrzt'" :pause="true"/>   
           </div>
        </div>
    </div>
</template>
<script>
import Video from './lect_video.vue'

export default {
        components:{Video},

    data(){
        return{
            loaded:false,
            vid:'0_id6xgrou‏‏',
            // vid:'0_wbfdcfn2'
        }
    },
    methods:{
 
    },
    mounted(){
        this.loaded = true
    }
}
</script>
<style lang="scss" scoped>
    #thanks{text-align: center;}
    #logo_hero{position: absolute;width: 162px;height: 55px;background-size: cover;background-image: url(../assets/logo.png);top: 10px;left: 0px;right: 0;margin: 0 auto;}
   .header{background: #1e2229;color: #fff;font-family: sans-serif;position: fixed;top: 0;width: 100%;;height: 80px;z-index: 9999;}
    #thanks_hero{background: #353d4a;color: #fff;margin-top: 80px;text-align: center;padding: 10px 0 30px;}
    #man{height: 350px;width: 100%;background-size: contain;background-image: url(../assets/man.png);background-position: center;margin: 20px 0;}
    #vid{width: 50%;margin: 0 auto;min-width: 400px;}
    h1{font-size: 40px;margin: 30px 0;}
    h2{font-weight: 400;font-size: 34px;margin-top: 50px;}
    h3{color: #30abb7;font-size: 35px;margin: 20px 0;font-weight: 800;}
       @media (max-width: 700px) {
       h1{font-size: 29px;margin: 10px 0;}
       #man{height: 150px;}
       h2{font-size: 20px;margin-top: 20px;}
       h3{font-size: 30px;margin-top: 20px;}
       #vid{width: 90%;margin: 0 auto;}
  }
</style>