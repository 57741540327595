<template>
    <div v-if="loaded" class="centered-big" id="lecturerd_widget">
        <div class="title" v-html="$store.words.home_widget_title_reviews">Here’s how we can help you</div>
        <!-- <div id="tabs" class="centered">
            <a v-for="type in types" :key="type.id" :class="'tab '+(active_item == type.id ? 'active_item' : '')" v-text="type.type" @click="active_item = type.id"></a>
        </div> -->
         <carousel :autoplay="true" perPage="1"  :mouse-drag="true" :loop="true" :autoplayTimeout="5000" :navigationEnabled="!is_mobile()"  :navigationNextLabel="'<div class=right></div>'" :navigationPrevLabel="'<div class=left></div>'">
            <slide v-for="item in items" :key="item.id" class="lecturer_item">
              <!-- <template v-if="active_item == item.id"> -->
                    <!-- <div class="title_item"><div style="width: 95%;" v-html="item.title"></div></div> -->
                    <div class="item_wrapper">
                      <div class="video">
                          <Video :video="item" />
                      </div>
                      <div class="item_data">
                          <div class="logo" :style="'background-image:url('+item.logo_parsed+')'"></div>
                          <div class="content" v-html="item.content"></div>
                          <div class="name_job">
                              <div class="name" v-text="item.name"></div>
                              <div class="job" v-text="item.job"></div>
                          </div>
                      </div>
                    </div>
              <!-- </template> -->
          </slide>    
        </carousel>
          <carousel :perPageCustom="[[300, 2], [1024, 7]]" :autoplay="is_mobile()" :mouse-drag="true" :paginationEnabled="false" :loop="true" :navigationEnabled="false" :navigationNextLabel="'<div class=right></div>'" :navigationPrevLabel="'<div class=left></div>'">
                  <slide v-for="item in logos" :key="item.id" class="item">
                    <div class="pic" :style="'background-image:url('+ item.pic_parsed+')'"></div>
                  </slide>
        </carousel>
    </div>
</template>
<script>
import Video from "./lecturers_widget_video.vue";
export default {
  props:["logos","items"],
  components: { Video },
  data() {
    return {
        loaded:true,
        active_item:1,

    }
  },
  mounted(){
 
  }
}
</script>
<style lang="scss" scoped>
.VueCarousel-inner{padding: 50px;}
.item_wrapper{display: flex;box-shadow: 0 0 14px 0px rgb(0 0 0 / 20%);margin: 0 100px;border-radius: 20px;}
#lecturerd_widget{margin-top: 20px;padding-bottom: 40px;
.title{color: #4e5074;font-weight: 700;margin-bottom: 20px;font-size: 35px !important;}
}

#tabs{
    &{display: flex;flex-wrap: wrap;justify-content: space-between;margin-bottom: 40px;}
    .tab{ text-align: center;color: #4e5074;border-bottom: 4px solid transparent;padding-bottom: 5px;transition: all 0.4 ;text-decoration: none;    transition-duration: 0.3s;}
    .tab.active_item{color: #1480dc;font-weight: 700;border-bottom: 4px solid #1480dc;;border-radius: 3px;}
    .tab:hover{color: #1480dc;font-weight: 700;border-bottom: 4px solid #1480dc;;border-radius: 3px;}
}
.lecturer_item{
    &{display: flex;column-gap: 20px;}
    .title_item{flex: 2;font-family: 'Poppins';font-weight: 900;color: #1727a4;text-transform: uppercase;font-size: 38px;padding-top: 100px;}
    .video{flex: 2;}
    .item_data{flex: 3;margin: 0 45px;padding: 20px 0;
    .logo{    height: 70px; background-size: contain; background-repeat: no-repeat;margin-bottom: 30px;}
    .content{color: #000;font-size: 17px;margin-bottom: 15px;}
    .name{color: #2e2e2e; font-size: 18px;font-weight: 800;line-height: 1;}
    .job{    color: #2e2e2e;line-height: 1;font-size: 14px;margin-top: 5px;}
    }
}
.pic{height: 82px;background-size: contain;background-position: center; width: 160px;margin-right: 30px; height: 50px; margin-top:15px;}
@media(max-width:900px) {
  .item_wrapper{margin: 0 10px;    flex-direction: column;}

  #tabs .tab{ flex-basis: 50%;margin-bottom: 10px;}
  .lecturer_item{flex-direction: column;justify-content: center;text-align: center;margin-bottom: 10px;padding-top: 0px;}
  .lecturer_item .title_item{flex: 1;    padding: 0;font-size: 24px;}
  .lecturer_item .item_data .logo{background-position: center;}
}
</style>