<template>
  <v-app v-if="loaded">
    <v-main>
      <div>
        <!-- <v-snackbar timeout="1055000" v-model="snack">
         <div class="content_bar">
     this website uses cookies. Cookies remember you so we can give you a better online experience.
     </div>
     <div>
        <v-btn  color="blue" text  @click="cancel_snack">
          OKAY, THANKS
        </v-btn>
        </div>
    </v-snackbar> -->

        <!-- <div id="share"></div> -->
        <router-view
          :key="$route.path"
          :menu="menu"
          :menu_homepage="menu_homepage"
          :menu_thanksleadgen="menu_thanksleadgen"
          :menu_instructors="menu_instructors"
          @pop_up="(dialog = true), autofill($event)" 
          @pop_up_contact="(dialog_contact = true)"
          @meta="(meta = $event)"
          @dialog_contact_send_multi="(dialog_contact_send_multi = true)"
          @pop_up_contact_realestate="(dialog_contact_realestate = true)"
        ></router-view>
        
        <div id="pop_up">
          <v-dialog v-model="dialog_contact" width="550">
            <v-card>
              <div class="contact_pop">
                <div class="close" @click="dialog_contact = false">X</div>
                    <h3 v-html="$store.words.popup_title">Download Your Free Syllabus</h3>
                    <p v-html="$store.words.popup_sub_title">And Learn How To Develop Like A Pro</p>
                  <form @submit.prevent="send()">
                    <v-text-field class="input" label="Full Name*" v-model="form.name" outlined></v-text-field>
                    <v-text-field  class="input" label="Email*" v-model="form.email" outlined></v-text-field>
                  <div id="inter" style="margin-bottom:20px">
                      <VuePhoneNumberInput class="input" v-model="form.phone" :required="true"  />
                    </div>
                    <v-text-field  class="input" label="Role And Company*" v-model="form.company" outlined></v-text-field>

                    <input type="submit" :value="$store.words.download_btn" class="btn"/>
                  </form>
                  <div id="approve_text" class="black">By sharing your email,you agree to our <a target="_blank" href="/terms">Privacy Policy</a> and <a target="_blank" href="/terms">Terms of Service</a></div>
              </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_contact_send_multi" width="550">
            <v-card>
              <div class="contact_pop">
                <div class="close" @click="dialog_contact_send_multi = false">X</div>
                    <h3 v-html="$store.words.popup_title">Download Your Free Syllabus</h3>
                    <p v-html="$store.words.popup_sub_title">And Learn How To Develop Like A Pro</p>
                  <form @submit.prevent="send_multi()">
                    <v-text-field class="input" label="Full Name*" v-model="form.name" outlined></v-text-field>
                    <v-text-field  class="input" label="Email*" v-model="form.email" outlined></v-text-field>
                    <div id="inter" style="margin-bottom:20px">
                      <VuePhoneNumberInput class="input" v-model="form.phone" :required="true"  />
                    </div>
                     <v-text-field  class="input" label="Role And Company*" v-model="form.company" outlined></v-text-field>

                    <input type="submit" :value="$store.words.download_btn" class="btn"/>
                  </form>
                  <div id="approve_text" class="black">By sharing your email,you agree to our <a target="_blank" href="/terms">Privacy Policy</a> and <a target="_blank" href="/terms">Terms of Service</a></div>
              </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_contact_realestate" width="550">
            <v-card>
              <div class="contact_pop">
                <div class="close" @click="dialog_contact_realestate = false">X</div>
                    <h3 v-html="$store.words.popup_title">Download Your Free Syllabus</h3>
                    <p v-html="$store.words.popup_sub_title">And Learn How To Develop Like A Pro</p>
                  <form @submit.prevent="send_realestate()">
                    <v-text-field class="input" label="Full Name*" v-model="form.name" outlined></v-text-field>
                    <v-text-field  class="input" label="Email*" v-model="form.email" outlined></v-text-field>
                    <div id="inter" style="margin-bottom:20px">
                      <VuePhoneNumberInput class="input" v-model="form.phone" :required="true"  />
                    </div>
                    <v-text-field  class="input" label="Role And Company*" v-model="form.company" outlined></v-text-field>

                    <input type="submit" :value="$store.words.download_btn" class="btn"/>
                  </form>
                  <div id="approve_text" class="black">By sharing your email,you agree to our <a target="_blank" href="/terms">Privacy Policy</a> and <a target="_blank" href="/terms">Terms of Service</a></div>
              </div>
            </v-card>
        </v-dialog>
    </div>

        <v-dialog v-model="dialog" width="500" content-class="sigup_popup">
          <v-card>
            <div class="header_pop">
              <div class="close" @click="dialog = false">X</div>
              <div class="title">CREATE ACCOUNT</div>
              <p v-if="stripe">TOTAL BILLED TODAY: $588</p>
              <p v-if="stripe">(Tax Included)</p>
            </div>
            <div class="form" v-if="!stripe">
              <h3>Personal Details</h3>
              <v-text-field
                label="Full Name *"
                v-model="form.name"
                outlined
              ></v-text-field>
              <v-text-field
                label="Phone Number *"
                v-model="form.phone"
                outlined
              ></v-text-field>
              <v-text-field
                label="Email Address *"
                v-model="form.email"
                outlined
              ></v-text-field>
              <v-checkbox
                v-model="form.accept_adv"
                :label="`Keep me up to date on classes and new releases`"
              ></v-checkbox>
              <span
                >By sharing your email, you agree to our
                <router-link to="/terms"> Terms of Service</router-link> and
                <router-link to="/privacy">Privacy Policy.</router-link></span
              >
              <v-btn elevation="2" @click="save()">NEXT</v-btn>
              <div class="notes">
                <span>Secured with SSL</span>
                <span>Satisfaction Guaranteed</span>
              </div>
            </div>

            <div class="form" v-if="stripe">
              <h3>
                Card information
                <div id="cards"></div>
              </h3>
              <Stripe
                :user_id="user_id"
                :id_prod="'prod_JyGR2D3z2gKhhv'"
                :link="'/thank-you-yearly-online'"
                :username="form.email"
                @done="dialog = false"
              />
            </div>
            <div class="footer_pop">
              <div class="per">
                <h2>100%<br />Guaranteed</h2>
                <p>
                  we offer a 30-day money back Guarantee if you dont love the
                  annual Membership
                </p>
              </div>
              <div class="btns">
                <div class="strc">
                  <i class="fas fa-lock"></i> Secured with SSL
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import Stripe from "./components/stripe.vue";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
export default {
  components: { Stripe, VuePhoneNumberInput },
  name: "App",

  

  data() {
    return {
      form: {
        name: "",
        email: "",
        company: "",
        accept_adv: 1,
        phone: "",
        source_page: "",
      },
      meta: {},
      dialog_contact_realestate: false,
      dialog_contact_send_multi: false,
      show_side_menu: false,
      user_id: 0,
      loaded: false,
      stripe: false,
      snack: false,
      dialog_contact: false,
      dialog: false,
      menu: [
        { id: 1, name: "Main", selector: "hero" },
        { id: 2, name: "Instructors", selector: "lecturers" },
        { id: 3, name: "Programs", selector: "about" },
        { id: 4, name: "Reviews", selector: "reviews" },
        { id: 5, name: "FAQ", selector: "questions" },
      ],
      menu_homepage: [
        { id: 1, name: "Programs", selector: "courses" ,link: true , url:'/#courses' },
        { id: 2, name: "Instructors", selector: "lecturers_home", link: true , url:'/instructors' },
        { id: 3, name: "Reviews", selector: "reviews_home" , link: true , url:'/reviews'},
        { id: 4, name: "About Us", selector: "about_us", link: true , url:'/about-us' },
        { id: 5, name: "Resources", selector: "courses",link: true , url:'/Resources' },
      ],
      menu_thanksleadgen: [
        { id: 1, name: "Main", selector: "hero" },
        { id: 2, name: "Instructors", selector: "lecturers" },
        { id: 3, name: "Programs", selector: "about_thanks" },
        { id: 4, name: "Reviews", selector: "wrote_thanks" },
        { id: 5, name: "FAQ", selector: "questions" },
      ],
      menu_instructors: [
        { id: 1, name: "Main", selector: "hero" },
        { id: 2, name: "Instructors", selector: "team" },
        { id: 3, name: "Reviews", selector: "say" },
        { id: 4, name: "List", selector: "list_inst" },
      ],
      // courses: [],
      // questions: [],
      // reviews: [],
      // logos: [],
      // lecturers: [],
      // reasons: [],
    };
  },
  // computed:{
  // this.$store.menu = [
  //               {id:1,name:"Main", selector:"hero"},
  //               {id:2,name:"Presantors", selector:"lecturers"},
  //               {id:3,name:"The Course", selector:"list"},
  //               {id:4,name:"Testimonials", selector:"reviews"},
  //               {id:5,name:"FAQ", selector:"questions"},
  //               {id:6,name:"LOG IN", selector:"#list"},

  //           ]
  // },

  methods: {
    autofill(email) {
      this.form.email = email;
    },
        send(){
              var utm = null;
               var link = localStorage.getItem('link');
              if(JSON.parse(localStorage.getItem('utm')))
              {
                  utm = JSON.parse(localStorage.getItem('utm'))
              }
        
            if(this.form.name.length < 1 || this.form.email.length < 1 ||  this.validatePhone(this.form.phone))
            {
               this.alert("Please Fill the form currectly")
            }
           else
            {
              this.form.source_page = 'regular-popup';

                this.net_api({ action: 'contact',data:{form:this.form,utm:utm,link:link} }, function(data){ 
                   console.log(data)
                    window.location = "/thanks"
               });
            }
        },
        send_multi(){
              var utm = null;
               var link = localStorage.getItem('link');
              if(JSON.parse(localStorage.getItem('utm')))
              {
                  utm = JSON.parse(localStorage.getItem('utm'))
              }
        
            if(this.form.name.length < 1 || this.form.email.length < 1 ||  this.validatePhone(this.form.phone))
            {
               this.alert("Please Fill the form currectly")
            }
           else
            {
              this.form.source_page = 'multi-family-popup';
                this.net_api({ action: 'contact',data:{form:this.form,utm:utm,link:link} }, function(data){ 
                   console.log(data)
                    window.location = "/thanks_multifamilylp1"
               });
            }
        },
        send_realestate(){
              var utm = null;
               var link = localStorage.getItem('link');
              if(JSON.parse(localStorage.getItem('utm')))
              {
                  utm = JSON.parse(localStorage.getItem('utm'))
              }
        
            if(this.form.name.length < 1 || this.form.email.length < 1 ||  this.validatePhone(this.form.phone))
            {
               this.alert("Please Fill the form currectly")
            }
           else
            {
              this.form.source_page = 'Finance-popup';
                this.net_api({ action: 'contact',data:{form:this.form,utm:utm,link:link} }, function(data){ 
                   console.log(data)
                    window.location = "/thanks_Realestate_finance"
               });
            }
        },
    cancel_snack() {
      this.snack = false;
      localStorage.setItem("is_snackbar", true);
    },
  },

  mounted() {
     let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://ams.urecenter.com/form/generate.js?id=1')
      document.head.appendChild(recaptchaScript)
     var self = this;
      this.net_api({ action: "general" }, function(data) {
        self.$store.kalendly = data.data.words.kalendly;
        self.$store.price = data.data.words.price;
        self.$store.timer = data.data.words.timer
        self.$store.words = data.data.words;
        self.$store.upcoming_courses = data.data.upcoming_courses;
        self.$store.syllabus = data.data.syllabus;
        self.$store.logos_comps = data.data.logos_comps;
        self.$store.featured_logos = data.data.featured_logos;
        self.$store.resource = data.data.resource;
        self.$store.estate_school = data.data.estate_school;
        self.$store.team = data.data.team;
        self.$store.instructors_page = data.data.instructors_page;
        self.$store.lecturers = data.data.lecturers;
        self.$store.reviews_instructors = data.data.reviews_instructors;
        self.$store.buttons_company = data.data.buttons_company;
        self.$store.buttons_programs = data.data.buttons_programs;
        self.$store.article = data.data.article;
        self.loaded = true;
      });

      
  
    // localStorage.clear('is_snackbar');
    if (this.isNull(localStorage.getItem("is_snackbar")))
      setTimeout(() => {
        this.snack = true;
      }, 1500);

    //  this.net_api({ action: 'pay_link' }, function(data){
    //    self.pay_link = data.data
    //   });
    // this.snack = true
  },
};
</script>

<style lang="scss" scoped>
#inter{padding:0px; margin-top:5px}
#share {
  background-image: url(./assets/logo_share.png);
  height: 50px;
  width: 50px;
}
.header_pop {
  background: #363c4a;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  position: relative;
}
.header_pop {
  .close {
    position: absolute;
    right: 20px;
    font-size: 22px;
    cursor: pointer;
  }
  .title {
    font-size: 20px !important;
    text-align: left;
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
    color: #d2d8df;
  }
}
.form {
  padding: 10px 20px;
  text-align: center;
}
.form {
  .notes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 15px 0;
    color: #aeaeae;
    font-size: 20px;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    background: #30abb7;
    color: #fff;
    width: 100%;
    border-radius: 9px !important;
  }
  h3 {
    text-align: left;
    font-size: 17px;
    margin-bottom: 10px;
    position: relative;
  }
  #cards {
    background-image: url(./assets/credit_cards.png);
    position: absolute;
    right: 0;
    height: 41px;
    width: 137px;
    background-size: contain;
    top: 3px;
  }
}
.footer_pop {
  background: #363c4a;
  color: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: space-around;
}
.footer_pop {
  .per {
    & {
      text-align: center;
    }
    h2 {
      line-height: 34px;
      font-size: 30px;
      margin-bottom: 7px;
      text-transform: uppercase;
    }
    p {
      color: #d2d8df;
      font-size: 16px;
    }
  }
  .per,
  .btns {
    flex: 1;
  }
  .btns {
    padding: 50px 0 0 50px;
  }
  .strc {
    padding: 10px;
    border: 1px solid #686868;
    border-radius: 5px;
    text-align: center;
  }
}
@media only screen and (max-width: 600px) {
  .footer_pop .per h2 {
    line-height: 25px;
    font-size: 20px;
    margin-bottom: 0px;
  }
  .footer_pop .per p {
    font-size: 12px;
    margin-bottom: 0;
  }
  .form .notes {
    font-size: 14px;
  }
  .v-input__control {
    height: 63px;
  }
  .v-text-field--filled > .v-input__control > .v-input__slot,
  .v-text-field--full-width > .v-input__control > .v-input__slot,
  .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 40px !important;
  }
}
.contact_pop{
    padding:30px; background:#e9f0f8; position: relative; border-radius: 50px;
    h3{text-align: center; color:#3d3d3d; font-size: 25px;}
    p{text-align: center; color:#3d3d3d; font-weight: 700;}
    .close{right:25px; position:absolute; top:15px; color:#c3c3c3; font-weight: 700; cursor: pointer; font-size: 20px;}
    input{background:#fff; width:100%; height:40px; margin-top:15px; border-radius: 5px; padding-left: 15px; font-size: 15px; outline:none}
    .btn{background:#1480dc; width:100%; padding:10px 0; color:#fff; text-transform: uppercase; font-weight: 500; margin-top:25px;line-height: 10px;}
}
</style>
