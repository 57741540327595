<template v-if="loaded">
    <div id="counter">
      <div class="wrppaer_coun">
        <h3>Price increase in</h3>
        <div class="count">
          <!-- {{$store.timer}} -->
           <Countdown :end="timer"></Countdown>
        </div>
        <button class="btn" v-if="!is_mobile()"><a @click="$emit('signup',contact)" v-html="$store.words.fotter_btn">Get Free Brochure</a></button>
        <button class="btn" v-if="is_mobile()"><a @click="$emit('signup',contact)" v-html="$store.words.fotter_btn_mobile">Get Free <br>Brochure</a></button>
        
      </div>
  </div>
</template>

<script>
import Countdown from 'vuejs-countdown'
 
export default {
  components: { Countdown },
  props: ["timer"],
    data(){
    return{
      loaded: false,
    }
  }
}
</script>

<style lang="scss" >
#counter{ background:#1c3a58; position: fixed;  bottom:0px; width:100%; z-index: 99999;
/*background-image: url(/img/wave.532aad03.png);*/ background-position: center; background-size: cover; background-repeat: no-repeat; background-blend-mode: multiply;
  .wrppaer_coun{display: flex; justify-content: center; padding:8px;align-items: center; 
    h3{padding-right:30px; color:#fff; font-size: 25px;  margin-top: 10px; font-family: "Nunito", sans-serif}
    .vuejs-countdown .digit {margin:0px!important;color: #fff; font-weight: 800;}
    .vuejs-countdown .text{margin:0px!important; color: #fff; font-weight: 700; text-transform: none; font-size: 15px!important; line-height: 4px;}
    .vuejs-countdown li {margin:0 12px;}
    .vuejs-countdown li:after{color: #fff; top:-4px!important}
    button{background: #1480dc!important;padding: 0px 60px!important;font-size: 15px;font-weight: 700; height: 40px!important; border-radius: 4px !important;padding: 0 60px;margin-bottom: 0; margin-left: 30px; margin-top: 10px; text-transform: uppercase;}
    button a{color:#fff; text-decoration: none;}
    .vuejs-countdown li:after {right:-17px!important;}
  }
  ul{padding:0px; margin:0px;}
}
@media only screen and (max-width: 900px){
  .vuejs-countdown .text{font-size: 14px; line-height: 5px!important;}
}
@media only screen and (max-width: 788px) and (min-width:415px){
  #counter{height: 95px!important;}
  .count {top:44%!important;}
  #counter .wrppaer_coun h3{display:block!important; margin-top: 0px!important; font-size: 20px;}
  .count{position:absolute; left:22px; top: 45%;}
  .vuejs-countdown .digit{font-size: 16px!important; line-height: 2!important;}
  .vuejs-countdown .text{line-height: 11px; font-size: 15px!important;}
  #counter .wrppaer_coun button{width: 158px;position: absolute; text-align: center; right: 30px; font-size: 12px;top: 33%!important;padding:0px 0px!important; height: 45px!important; line-height: 18px;}
  .vuejs-countdown li:after {top:5px!important; font-size: 18px!important;}
}
@media only screen and (max-width: 414px){
  #counter{height: 95px!important;}
  .count {top:18%!important;}
  #counter .wrppaer_coun h3{display:block!important; margin-top: 0px!important; font-size: 20px;}
  .count{position:absolute; left:22px; top: 45%;}
  .vuejs-countdown .digit{font-size: 16px!important; line-height: 2!important;}
  .vuejs-countdown .text{line-height: 11px; font-size: 15px!important;}
  #counter .wrppaer_coun button{width: 100px;position: absolute; text-align: center; right: 35px; font-size: 13px;top: 33%!important;padding:0px 0px!important; height: 45px!important; line-height: 18px;}
  .vuejs-countdown li:after {top:5px!important; font-size: 18px!important;}
}
@media only screen and (max-width: 375px){
  #counter{height: 95px!important;}
  .count {top:18%!important;}
  #counter .wrppaer_coun h3{display:block!important; margin-top: 0px!important; font-size: 20px;}
  .count{position:absolute; left:22px; top: 45%;}
  .vuejs-countdown .digit{font-size: 16px!important; line-height: 2!important;}
  .vuejs-countdown .text{line-height: 11px; font-size: 15px!important;}
  #counter .wrppaer_coun button{width: 100px;position: absolute; text-align: center; right: 13px!important; font-size: 13px;top: 33%!important;padding:0px 0px!important; height: 45px!important; line-height: 18px;}
  .vuejs-countdown li:after {top:5px!important; font-size: 18px!important;}
}
</style>