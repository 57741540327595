<template>
    <div id="learn">
        <div class="line_learn"></div>
        <div class="centered_h">
            <div class="info_learn col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h2 v-html="$store.words.learn_title"></h2>
                <p v-html="$store.words.learn_text"></p>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="pic_learn"></div>
            </div>
            <div class="clr"></div>
        </div>
    </div>
</template>

<script>
  export default {   
    data() {
    return {

    }
  },
}
</script>

<style scoped lang="scss">
#learn{padding:30px 0px;
    .line_learn{height:1px; width: 80%; margin: 0 auto; background:#cccccc;}
    .centered_h{padding-top:30px;}
    .info_learn{padding-top:50px;
        h2{color:#4e5074; font-size: 32px; padding-bottom:10px;font-family: 'Poppins', sans-serif;line-height: 45px;}
        p{color:#4e5074; line-height: 30px; font-size: 16px;}
    }
    .pic_learn{background-image:url(../../assets/learn.png); background-size: contain; background-position: center; background-repeat: no-repeat; width:100%; height: 450px;}
}
@media(max-width:1275px){
    #learn .line_learn{width:97%}
}
@media(max-width:600px){
    #learn .info_learn{padding-top:10px; text-align: center;}
}
@media(max-width:600px){
      #learn .pic_learn{height: 320px;}

    #learn .info_learn{padding-top:10px; text-align: center;
        h2{font-size: 25px; padding-bottom:0px; line-height: 30px;}
        .pic_learn{height:390px}
    }
}
</style>
