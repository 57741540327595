<template v-if="loaded">
  <div id="join">
    <div class="centered_j">
      <div class="pic_col col-md-5">
        <div :class="'pic_letter '+(lp2 ? 'lp2' : (realestate ? 'realestate' : ''))"></div>
      </div>

      <div class="text_j col-md-7">
        <h2 v-html="title_1"></h2>
        <div class="line_j"></div>
        <p id="text" v-html="title_2"></p>
      </div>
      <div class="clr"></div>
    </div>
  </div>
</template>

<script>
export default {
  props:["lp2","title_1","title_2","realestate"],
  data(){
    return{
      loaded: false,
    }
  }
};
</script>

<style lang="scss" scoped>
#join {
  background: #e9f0f8;
  padding-bottom: 40px;
  .pic_col {
    padding-top: 90px;
    .pic_letter {
      background-image: url(../assets/letter_new.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 335px;
    }
    .pic_letter.lp2{background-image: url(../assets/letter_lp2.png);}
    .pic_letter.realestate{background-image: url(../assets/real_letter.png);}
  }
  .text_j {
    padding-top: 80px;
    h2 {
      color: #4e5074;
      line-height: 30px;
    }
    .line_j {
      height: 0.5px;
      background: #bac0c6;
      width: 80%;
      margin: 35px 0px 30px 0px;
    }
    #text {
      width: 70%;
      color: #4e5074;
      line-height: 30px;
      font-size: 20px !important;
      margin-bottom: 0px !important;
      font-weight: 400;
    }
  }
}

@media only screen and (max-width: 1400px) and (max-width: 1300px) {
  #join .text_j .line_j {
    width: 90%;
  }
  #join .text_j #text {
    width: 90%;
  }
}
@media only screen and (max-width: 1200px) {
  #join .pic_col .pic_letter {
    width: 90%;
  }
}
@media only screen and (max-width: 1275px) {
  #join .pic_col .pic_letter {
    height: 300px !important;
    width: 95%;
  }
}
@media only screen and (max-width: 900px) {
  #join .pic_col {
    padding-top: 50px;
  }
  #join .text_j {
    padding-top: 25px;
    text-align: center;
    margin: 0 auto;
  }
  #join .text_j .line_j {
    width: 100%;
  }
  #join .text_j #text {
    width: 90%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 414px) {
  #join .pic_col .pic_letter {
    height: 200px !important;
  }
  #join .text_j h2 {
    font-size: 25px;
    line-height: 30px;;
  }
  #join .text_j #text {
    font-size: 18px !important;
  }
}
</style>
