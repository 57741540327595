<template v-if="loaded">
<div id="questions">
    <!-- <div class="centered-big"> -->
    <div class="centered_l">
      <div class="title t_ques">Frequently Asked Question</div>

        <div class="col-md-12 col-sm-12">
            <div class="col-md-6 col-sm-6" v-for="(group, i) in articleGroups" :key="i.id">
            <!-- <div v-for="(group, i) in articleGroups" :key="i.id"> -->
    <v-expansion-panels popout>
      <v-expansion-panel v-for="article in questions.slice(i * itemsPerRow, (i + 1) * itemsPerRow)" :key="article.id">
        <v-expansion-panel-header><h3>{{article.question}}</h3></v-expansion-panel-header>
        
        <v-expansion-panel-content>
          <div class="line_q"></div>
            {{article.answer}}        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    </div>
       
    <div class="clr"></div>
    </div>
        <div class="clr"></div>

</div>
  </div>
</template>


<script>
// @ is an alias to /src

export default {
 
    props:["questions"],
  data() {
    return {
      itemsPerRow: 4,
      loaded:false,
    }
  },
   computed: {
    articleGroups () {
      return Array.from(Array(Math.ceil(this.questions.length / this.itemsPerRow)).keys())
    }
   },
  
}
</script>
<style lang='scss' scoped>
.v-application p {margin-bottom:16px!important;}
.t_ques{margin-bottom:0px!important}
.title{color: #595b7d; margin:0 auto; text-align: center; font-weight: 700!important;}
.v-application .title {font-size: 35px !important;}
#questions{background-color: #fff;padding: 50px 0}
.theme--light.v-expansion-panels .v-expansion-panel{background-color: #fff;color: #fff;margin: 5px 0; border-radius: 15px; min-height: 80px; padding-top:10px; margin-top:22px}
h3{color: #595b7d; line-height: 30px; font-family: "Nunito", sans-serif}
.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
    border: none;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)>.v-expansion-panel--next-active{border-bottom-left-radius: 15px;border-bottom-right-radius:15px}
.v-expansion-panels--popout>.v-expansion-panel--active{max-width: calc(100% - 32px);}
.line_q{height: 1px; background: #cccccc; width: 100%; margin: 0px 0px 24px 0px;}
.v-expansion-panel-content {max-width: 100%;color: #595b7d!important;font-weight: 600; padding-bottom: 20px;}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)>.v-expansion-panel--active {border-radius: 15px;}
.v-expansion-panel:before{box-shadow: 0px 0px 14.56px 1.44px rgba(1, 1, 1, 0.11);}

@media only screen and (max-width: 414px){
  #questions .title{font-size: 25px!important;}
}
@media only screen and (max-width: 360px){
  h3{line-height: 24px; font-size: 18px!important;}
  #questions .t_ques{font-size: 25px!important;}
} 
</style>