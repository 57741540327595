<template v-if="loaded"> 
  <div id="lectures_thanks">
    <div class="it centered_l">
      <carousel
        :autoplay="is_mobile()"
        :perPage="4"
        :scrollPerPage="false"
        :paginationEnabled="false"
        :mouse-drag="true"
        :loop="true"
        :navigationEnabled="true"
        :navigationNextLabel="'<div class=right_lecturers></div>'"
        :navigationPrevLabel="'<div class=left_lecturers></div>'"
      >
        <slide v-for="data in lecturers" :key="data.id" class="item">
          <v-hover :open-delay="210" >
            <v-card
              :class="'mx-auto ' + (data.video ? 'has_video' : '')"
              color="grey lighten-4"
            >
              <v-img
                :aspect-ratio="16 / 9"
                class="pic"
                :style="'background-image:url(' + data.pic_parsed + ')'"
              >
                <div class="hover_play" v-if="data.video" @click="open_video(data)" >
                  <i class="far fa-play-circle" ></i>
                </div>
                <div class="liner on_hover  white--text" style="height: 100%;">
                  <div
                    class="content"
                   @click="(data.video ? open_video(data) : '')"
                  >
                    <h4>{{ data.name }}</h4>
                    <h5>{{ data.job }}</h5>
                    <p class="p_desc">{{ data.description }}</p>
                  </div>
                </div>
              </v-img>
              <v-card-text class="wrap" style="position: relative;">
                <div class="dets">
                  <div
                    class="logo"
                    :style="'background-image:url(' + data.logo_parsed + ')'"
                  ></div>
                  <div id="line_lec"></div>
                  <div class="h7">{{ data.name }}</div>
                  <h3>{{ data.job }}</h3>
                </div>
              </v-card-text>
            </v-card>
          </v-hover>
     
        </slide>
      </carousel>
      
    </div>
         <v-dialog v-model="dialog" width="600" content-class="lect_vid" :key="ref">
            <v-card>
              <div class="header_pop">
                <div class="close" @click="dialog = false">X</div>
                <Video :video_url="video" :pause="dialog" />
              </div>
            </v-card>
          </v-dialog>
  </div>
</template>
<script>
import Video from "./lect_video.vue";
export default {
  components: { Video },
  props: ["lecturers"],
  data() {
    return {
      more: true,
      dialog: false,
      video: '',
      ref:0,
      loaded:false,
      // courses: [],
      // questions: [],
      // reviews: [],
      // logos: [],
      // lecturers: [],
      // reasons: [],
    };
  },
  methods: {
    open_video(data)
    {
      this.ref++
      this.video = data.video;
      this.dialog = true;
    },
    dialog_play() {
      this.$refs[`plyr`].stop();
    },
    close() {
      this.$refs[`plyr`].fullscreen.exit();
    },
  },
};
</script>

<style lang="scss" scoped>


  .VueCarousel-inner{flex-basis: 233.25px}
  .v-responsive{max-width: 215px!important;}
  .pic{margin: 0 auto!important;}

.VueCarousel-slide {
  padding: 15px;
  margin-bottom: 20px;
}
.header_pop {
  & {
    background: #363c4a;
    height: 0;
  }
  video {
    margin: 0 auto;
    display: block;
    height: 350px;
    margin-top: -20px;
    z-index: 9;
  }
  .close {
    position: absolute;
    right: 20px;
    font-size: 22px;
    cursor: pointer;
    color: #fff;
    z-index: 10;
    top: 10px;
  }
}
p.p_desc{color:#fff;}
.has_video {
  cursor: pointer;
}
#line_lec {
  margin: 20px 0 10px 0;
  height: 0.5px;
  background: rgb(228, 227, 227);
}
// .v-dialog__container{cursor:pointer}
.hover_play {
  text-align: center;
  font-size: 30px;
  margin-top: 20px;
  position: absolute;
  left: 10px;
  margin: 0 auto;
  color: #fff;
  bottom: 10px;
  bottom: 5px;
}
.trailer {
  text-align: center;
  font-size: 14px;
  color: #000;
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: -16px;
  margin-left: -16px;
  position: relative;
  font-weight: 600;
  cursor: pointer;
}
.trailer::after {
  position: absolute;
  top: 0;
  right: 10px;
  left: 10px;
  height: 1px;
  background: #000;
  content: "";
}
.trailer:hover {
  background: #000;
  color: #fff;
}
.dets {
  text-align: center;
  width: 100%;
  padding-top: 5px;
  margin-bottom: 5px;
  min-height: 75px;
}

.logo {
  background-position: center;
  background-size: contain;
  height: 40px;
  width: 100%;
  margin-top: 10px;
  background-position: center;
}
.pic {
  background-position: center;
  background-size: cover;
  height: 230px;
}

.v-card {
  margin-bottom: 20px;
  padding: 10px;
  margin: 10px;
}
.v-responsive__content {
  p {
    font-size: 14px;
    display: block;
    text-align: center;
    line-height: 30px;
    width: 90%;
    margin: 0 auto;
    line-height: 15px;
  }
  h4 {
    font-size: 21px;
    display: block;
    text-align: center;
    line-height: 30px;
    padding-top: 30px;
  }
  h5 {
    font-size: 15px;
    display: block;
    text-align: center;
    line-height: 30px;
  }
  .liner {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 35%,
      rgba(0, 0, 0, 0.4) 100%
    );
  }
}
// .v-sheet.v-card{margin: 10px  !important;}
.on_hover {
  opacity: 0;
}
.v-card:hover .on_hover {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}
.v-sheet.v-card {
  border-radius: 0;
  padding: 0;
  box-shadow: none !important;
}
.v-application .grey.lighten-4 {
  background: #f6f9fd !important;
}
.v-card--reveal {
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
  padding: 5px;
}
.content {
  border: 1px solid #30abb7;
  height: 100%;
  width: 100%;
}
.h7 {
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #4e5074 !important;
  line-height: 23px;
  margin-bottom: 4px !important;
}
h3 {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #68696c !important;
  line-height: 21px;
}
.wrap {
  padding-top: 0 !important;
  padding-bottom: 0;
}
.v-card {
  min-height: 390px;
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.v-card__text {
  padding: 0px;
}
.v-application .title {
  margin-bottom: 50px !important;
}

@media only screen and (max-width: 900px) {
  .it:nth-child(2n + 1) {
    clear: both;
  }
  .v-card {
    min-height: 380px !important;
  }
}
@media (max-width: 600px) {
  .hover_play {
    font-size: 30px;
    left: 5px;
    bottom: 0;
  }
  video {
    height: 200px !important;
  }
  .dets {
    min-height: 95px !important;
  }
  .dets .h7 {
    font-size: 16px !important;
  }
  h3 {
    font-size: 13px !important;
  }

  .v-responsive__content {
    p {
      font-size: 12px;
      line-height: 20px;
    }
    h4 {
      font-size: 12px;
      line-height: 20px;
      padding-top: 10px;
    }
    h5 {
      font-size: 12px;
      line-height: 20px;
    }
  }
  .v-card {
    min-height: 330px;
  }
}
@media only screen and (max-width: 414px) {
  .it {
    width: 100%;
  }
  .v-card {
    min-height: 350px !important;
  }
}
</style>
