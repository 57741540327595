<template>
<div>
   
     <div id="courses_page" v-if="is_mobile()">

        <div id="hero" :style="'background-image:url('+info.pic_parsed+')'">
            <p v-html="info.course_subject"> </p>
            <h3 v-text="info.name"></h3>
            <p v-text="info.short_text"></p>
        </div>
     
               <v-tabs
          v-model="tab"
          align-with-title
        >
          <v-tabs-slider color="yellow"></v-tabs-slider>

          <v-tab
            v-for="item in items"
            :key="item.count"
          >
            {{ item.name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in items"
        :key="item">
        <template v-if="item.name == 'Sections'">
             <div class="item" v-for="sec in cats" :key="sec.id" :id="'item'+sec.id">
                <div class="course_inner_content" >
                  
                    <div @click="sec.show_more = !sec.show_more;item.count++" :key="sec.show_more">
                    <i class='fas fa-chevron-down' v-if="!sec.show_more" ></i>
                    <i class='fas fa-chevron-up' v-if="sec.show_more"></i>
                    <div class="flex_items">
                        <div class="left">
                             <div class="content" v-text="sec.name"></div>
                        </div>
                         <div class="right">
                        <div class="series_pic" :style="'background-image:url('+sec.pic_parsed+')'"></div>
                        <div class="amount">
                            <span>{{sec.num_episodes_whatched}}/{{sec.items.length}}</span>
                            <label> {{time_convert(sec.length_total)}}</label>
                        </div>
                    </div>
                </div>
                </div>
                 <transition name="slide" mode="out-in">
                    <div class="items" v-if="sec.show_more">
                        <div  v-for="lesson in sec.items" :key="lesson.id">
                        <a :href="'/course/'+lesson.id" class="lesson" >
                            <div class="col-md-5">
                               <div class="wrap" v-if="lesson.watched == 1"> <i class="fas fa-check"></i></div>
                                <div class="pic" :style="'background-image:url('+ lesson.pic_parsed+')'"></div>
                            </div>
                            <div class="col-md-7">
                                <p v-text="lesson.title" class="dets"></p>
                                <span v-text="lesson.length_video + ' minutes'"></span>
                            </div>
                            <div class="clr"></div>
                        </a>
                        </div>
                    </div>
                </transition>
                </div>
            </div>
        </template>
        <template v-else>
 <div id="about_course">
            <div  class="centered_courses"> 
                          <h3 v-html="info.what_learn"></h3>
            <p v-html="info.about"></p>
            <div class="col-md-3">
                <div class="circle"><div class="icon" :style="(info.icon_a > 0 ? 'background-image:url('+info.icon_a_parsed+')' : '')"></div></div>
                <div class="details">
                    <span v-text="info.title_a">תעודה הסמכה</span>
                    <label v-text="info.pros_a"></label>
                </div>
            </div>
            <div class="col-md-3">
                <div class="circle"><div class="icon" :style="(info.icon_b > 0 ? 'background-image:url('+info.icon_b_parsed+')' : '')"></div></div>
                <div class="details">
                    <span v-text="info.title_b">תעודה הסמכה</span>
                    <label v-text="info.pros_b"></label>
                </div>
            </div>
            <div class="col-md-3">
                <div class="circle"><div class="icon" :style="(info.icon_c > 0 ? 'background-image:url('+info.icon_c_parsed+')' : '')"></div></div>
                <div class="details">
                    <span v-text="info.title_c">תעודה הסמכה</span>
                    <label v-text="info.pros_c"> </label>
                </div>
            </div>
            <div class="col-md-3">
                <div class="circle"><div class="icon" :style="(info.icon_d > 0 ? 'background-image:url('+info.icon_d_parsed+')' : '')"></div></div>
                <div class="details">
                    <span v-text="info.title_d">תעודה הסמכה</span>
                    <label v-text="info.pros_d"></label>
                </div>
            </div>
            <div class="clr"></div>
        </div>
        </div>
    <div class="centered_courses">
        <Lecturers :writers="writers" :title="info.lec_title"/>
    </div>
        </template>
      </v-tab-item>
    </v-tabs-items>
       
    </div>
    </div>


    
</template>

<script>
import Lecturers from './Lecturers.vue';
export default { 
   props: ["user_data",'info','cats','writers'],
    components: {   Lecturers},

    data(){
        return {
                      menu:[
                {id:1,name:"Main Course", link:'/course_index/158'},
                {id:2,name:"About", link:"/course_index/158"},
                {id:3,name:"Speakers", link:"/course_index/158#lecturers_area"},
            ],
          showDialog:false,
            id: this.$route.params.id,
            profile_data: {},
            experties: [],
             tab: true,
        items: [
         
          {name:'About The Course',count:1},
           {name:'Sections',count:2}
        ],
 
      
            loaded: false,
            lead_name:"",
            lead_phone:"",
            users: [],
   
     
            courses: [],

        }
    },
  methods: {

  },
  mounted(){

let parent = document.querySelector('.sticky').parentElement;

while (parent) {
    const hasOverflow = getComputedStyle(parent).overflow;
    if (hasOverflow !== 'visible') {
        console.log(hasOverflow, parent);
    }
    parent = parent.parentElement;
}
         
  }
    } 
   
</script>
<style lang="scss" scoped>
#courses_page{background: #fff;}
.cat:nth-child(even){
    .col-md-6:nth-child(1){padding-right: 60px;}
    .col-md-6{float: left;}
    .course_pic::after{right: unset !important;left: -30px;}
}
#hero{  
     &{height: 50vh;text-align: center;color: #fff;padding-top: 120px;;background-position: center;background-size: cover;background-repeat: no-repeat;width: 100%;}
     p{font-size: 30px;margin-bottom: 10px;}
     h3{    font-size: 45px; width: 100% ;line-height: 45px; margin: 0 auto; margin-bottom: 10px;}
      }
      #about_course{
          .details{display: inline-block;width: 70%;vertical-align: middle;margin-left: 5px;text-align: left;}
          .details span{display: block;    padding-right: 10px;font-weight: 600;}
          .details label{    font-size: 15px;padding-right: 10px;;}
          .circle{padding: 10px;border: 1px solid;border-radius: 100%;height: 55px;width: 55px;display: inline-block;vertical-align: middle;}
          .icon{background-image: url(../../assets/diploma.png);height: 100%;width: 100%;background-size: contain;background-repeat: no-repeat;}
          &{text-align: center;padding-top: 50px;background: #fff;padding-bottom: 50px;}
          h3{font-size: 30px;margin-bottom: 30px;}
          p{    width: 70%;margin: 0 auto;font-size: 20px;margin-bottom: 30px;}
      }
//#app_left_side{display: none;}
//#app_right_side{display: none;}
.centered_courses{width: 100%;max-width: 1250px;position: relative;margin: 0 auto;}

    #hero{    padding-top: 40px;background-attachment: fixed;background-size: initial;background-position: top;}
    #hero h3{width: 100%;    font-size: 26px;line-height: normal;}
    #hero p{width: 90%;margin: 0 auto;;}
    #about_course{
        .col-md-3{margin-bottom: 10px;}
    }

.item{background: #fff;padding: 20px 10px;box-shadow: 0px 10px 20px rgb(0 0 0 / 20%);margin-bottom: 10px;cursor: pointer;position: relative;min-height: 140px;}
.item h3{font-size: 16px;font-weight: 600;}
.item .fa-chevron-down{position: absolute;right: 10px; top: 20px;}
.item .fa-chevron-up{position: absolute; right: 10px; top: 20px;}
.flex_items{
    &{padding-left: 15px;display: flex;padding-right: 20px;}
    .right{flex-basis: 35%;height: 100%;}
    .left{flex-basis: 65%;height: 100%;}
    }

.item .series_pic{height: 100px;     width: 100%;; background-size: cover; background-position: center;border-radius: 5px;}
      .writer{color: #fff;font-size: 22px;margin: 0 5px;}
.item{
    .content{width: 69%;font-weight: 600;font-size: 17px;;}
    .items{
        .wrap{
            &{    position: absolute;top: 30px; right: 3px; background: #30abb7; color: #fff;border-radius: 100%;padding: 3px;}
             .fa-check{color: #fff;font-size: 20px;    padding-top: 4px;}
        }
        .col-md-7{padding-top: 0px;}
        .lesson{padding: 20px 0;border-top: 2px solid #f3f4f8;width: 100%;margin: 0 auto;}
        span{display: inline-block;font-size: 15px;}
        &{margin-top: 45px;}
        .dets{color: #000;    font-size: 16px;font-weight: 700;margin: 10px 0 0;}
        .pic{height: 80px;width: 90%;background-position: center;background-size: cover;display: inline-block;vertical-align: middle;margin: 0 20px;}
        p{margin-top: 0;}
    }
}

.items_wrapper{height: calc(100vh - 100px);overflow: auto;padding: 10px;}
.btn{padding: 8.9px 20.5px 12.1px 17.5px;object-fit: contain;border-radius: 23px;background-color: #f68b35 !important;line-height: 17px;margin: 0 20px;color: #fff;font-weight: 600;}


@media only screen and (max-width: 600px) 
{
    .menu{min-width: 100%;}
    .item .items .pic{height: 150px;background-size: cover;margin: 0 auto;padding: 0;width: 100%;}
    .item .items .lesson{background: #f7f7f7;padding: 0;}
    .inner_course{padding-top: 0;flex-wrap: wrap;}
    .video_page{flex-basis: 100%;padding-top: 210px;}
    .items_wrapper{height: 100%;    padding: 0px;}
    .questions .question{width: 100%;}
    .section{padding: 30px 0;}
    .section::after{display: none;}
    .messages p{width: 100%;}
    .options{
        &{text-align: center;}
        .pop_btn { height: 36px;line-height: 15px;max-width: 100%;margin: 0 3px;padding: 0 8px !important;margin-bottom: 10px; position: initial;font-size: 15px; border-radius: 50px; width: 50%;}
        .btn{ height: 36px;line-height: 36px;max-width: 100%;margin: 0 3px;padding: 0 8px !important;margin-bottom: 10px;  width: 45%;  }
    }
}

.ep-legend--container span:nth-child(2){    font-size: 15px; width: 70px; display: block; color: #fff; text-align: center;font-weight: 600;font-family: 'almoni-dl';}
    .ep-legend--value span{    font-size: 26px;font-weight: 600;font-family: 'almoni-dl';}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
 .slide-leave-active,
.slide-enter-active {
  transition: 0.6s;
}
.slide-enter {
  transform: translate(0%, 0px);
}
.slide-leave-to {
  transform: translate(0%, 0px);
}
.arrow{position: absolute;top: 5px;left: 10px;}
.amount{
    &{text-align: right;padding-top: 10px;}
    span{font-size: 15px;font-weight: 600;display: inline-block;margin-left: 5px;}
    label{color:#30abb7;font-size: 16px;font-weight: 600;}
}
</style>