<template v-if="loaded">
  <div id="footer">
    <div class="centered_h">
      <div class="col-md-12 col-sm-12">
        <div class="col-md-3 col-sm-3">
          <a href="/"><div class="logo_f"></div></a>
          <h3 id="col_first">Programmes</h3>
          <ul v-for="item in $store.buttons_programs" :key="item.id">
            <a :href="item.link">
             <li>{{item.name}}</li>
            </a>
          </ul>
        </div>
        <div class="bottom mob col-md-3 col-sm-3">
          <h3>Company</h3>
          <ul v-for="item in $store.buttons_company" :key="item.id">
            <a :href="item.link" >
              <li><a>{{item.name}}</a></li>
            </a>
          </ul>
        </div>
        <div class="bottom mob col-md-3 col-sm-3">
          <h3>Community</h3>
          <ul>
            <li class="facebook" v-html="$store.words.footer_facebook"></li>
            <li class="linkedin" v-html="$store.words.footer_linkedin"></li>
            <li class="instagram" v-html="$store.words.footer_instagram"></li>
          </ul>
        </div>
        <div class="last_col col-md-3 col-sm-3">
          <h3>Contact us</h3>
          <ul>
            <li class="email" v-html="$store.words.footer_email"></li>
            <li class="tel"><a href="tel:+1(929)205-1682" v-html="$store.words.footer_tel"></a></li>
            <a href="mailto:support@urecenter.com"><button class="btn">contact us</button></a>
          </ul>
        </div>
      </div>
      <div class="clr"></div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      loaded: false,
    }
  }
};
</script>

<style lang="scss" scoped>
#footer {
  background: #fff;
  width: 100%;
  min-height: 480px;
  padding: 30px 0 30px 0;
  .centered_h{border-top:1px solid #e5e5e5;}
  .logo_f {
    background-image: url(../../assets/logoDarkNew.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 130px;
    height: 80px;
    cursor: pointer;
  }
  .logo_f:hover{opacity: 0.5;}
  .con {
    color: #5d6b7d;
    margin-bottom: 5px !important;
    font-size: 14px;
  }
  #col_first{padding-top:46px;}
  h3 {
    color: #34465c;
    font-weight: 800;
    padding-top: 50px;
    font-size: 20px;
    padding-bottom:10px;font-family: "Nunito", sans-serif;
  }
  ul {
    padding: 0px; color:#5d6b7d;
  }
  ul li {
    padding-top: 15px; color:#5d6b7d; font-size: 14px; cursor: pointer;
  }
  li a {
    color: #5d6b7d;
    font-size: 14px;
  }
  .md-theme-default a:not(.md-button):hover{color:#5d6b7d}
  .bottom {
    padding-top: 87px;
    .facebook{padding-left: 30px;
      a{color: #5d6b7d!important;}
    }
    .facebook::before{content:""; position:absolute; background-image:url(../../assets/facebook_newFooter.png); background-position: center;background-size: contain; background-repeat: no-repeat; width: 20px; height: 20px; left: 15px;}
    .linkedin{padding-left: 30px;
      a{color: #5d6b7d!important;}
    }
    .linkedin::before{content:""; position:absolute; background-image:url(../../assets/linkedin_newFooter.png); background-position: center;background-size: contain; background-repeat: no-repeat; width: 20px; height: 20px; left: 15px;}
    .instagram{padding-left: 30px;
      a{color: #5d6b7d!important;}
    }
    .instagram::before{content:""; position:absolute; background-image:url(../../assets/inst_newFooter.png); background-position: center;background-size: contain; background-repeat: no-repeat; width: 20px; height: 20px; left: 15px;}
  }
  .last_col {
    padding-top: 87px;
  }
p {
    color: #5d6b7d;
    width: 70%;
    font-size: 14px;
}

button {
    margin-top: 5px; border-radius: 4px; background: #85859f;  color: #fff; text-transform: uppercase; font-size: 16px;   font-weight: 700; width: 56%; height: 40px; line-height: 25px;
  }
  button.btn a {
    color: #fff;
  }
  button.btn a:hover {
    text-decoration: none;
  }
}
@media(max-width:1275px){
    #footer  button {  font-size: 16px;}
}
@media only screen and (max-width: 1200px) {
    #footer {width:90%;margin: 0 auto; 
    button {
      font-size: 12px;
    }
  }
}
@media only screen and (max-width: 1150px) {
    #footer  button {
      font-size: 11px;
      width: 70%;
    }
}
@media only screen and (max-width: 1275px) {
  #footer .wraper_con button {
    font-size: 11px;
    width: 65%;
  }
}
@media only screen and (max-width: 1000px) {
  #footer .wraper_con button {
    font-size: 11px;
    width: 85%;
  }
}
@media only screen and (max-width: 933px) {
  #footer  button {
      width: 85%;
    }
}
@media only screen and (max-width: 900px) {
  #footer .last_col {
    padding-top: 38px;
  }
  .wraper_con {
    flex-direction: column;
  }
  .wraper_con p {
    width: 80% !important;
    margin-bottom: 20px !important;
    line-height: 25px !important;
  }
  #footer .wraper_con button {
    font-size: 11px;
    width: 65%;
  }
}
@media only screen and (max-width: 414px) {
  #footer h3{padding-top:17px;}
  #footer .bottom{padding-top:0px;}
  #footer .last_col{padding-top:0px;}
}
@media only screen and (max-width: 360px) {
  #footer h3{padding-top:17px;}
  #footer .bottom{padding-top:0px;}
}
</style>
