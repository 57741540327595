<template v-if="loaded">
    <div id="hero">
        <div class="centered_h">
            <div class="first_box col-md-6 col-sm-6 col-xs-12">
                <h3 v-html="title_1"></h3>
                <h1 v-html="title_2"></h1>
                <!-- <div class="line"></div> -->
                <p class="p_hero" v-html="title_3"></p>
             <div id="form_hero">  
            <v-text-field  label="Your Email Address *" v-model="email" outlined v-if="!lp"></v-text-field>
                <v-btn depressed @click="$emit('signup',email)" v-if="!lp" :disabled="!isFormValid" >  Enroll Now </v-btn>
                <!-- <v-btn depressed  @click="$emit('sign')" v-if="lp" class="btn">Get Free Brochure</v-btn> -->
                <!-- <a href="https://admin.urecenter.com/themes/MNS/images/newsill.pdf" target="_blank" class="brochure_btn btn">Get Free Brochure</a> -->
                <a @click="$emit('sign')" class="brochure_btn btn"  v-html="$store.words.new_hero_btn">Get Free Brochure</a>
               
          <!-- <span v-if="!lp">${{$store.price}}/per month (billed annually)</span> -->
    <!-- <div class="rating">
        <div id="rating"></div>
        <span>Based on 120+ Reviews</span>
    </div> -->
            </div>
             <div id="marks" v-if="!NewHero">
                    <h4 class="mrk" v-html="$store.words.check_hero_one"></h4>
                    <h4 class="mrk" v-html="$store.words.check_hero_two"></h4>
                    <h4 class="mrk" v-html="$store.words.check_hero_three"></h4>
                </div> 
            </div>
            <div class="sec_box col-md-6 col-sm-6 col-xs-12">
                <div style="text-align:center" class="player"> 
                <!-- <button  v-if="!hide && !pause" @click="play();hide = true;pause = true" class="play"><i class="fas fa-play-circle"></i></button>  -->
                
                <iframe :src="video" height="500px" width="100%"></iframe>
                
                </div>       
                      </div>
        </div>
        
        </div>
</template>
<script>
export default {
    props:["hero_title","hero_description","lp", "hero_check","NewHero","title_1","title_2","title_3","multi","video"],
    
       data(){
        return {
          checkbox:true,
          pause:false,
          rating:5,
          hide:false,
          email:'',
          isFormValid:false,
          loaded: false,
        }
    },
   watch: {
    email: {
            handler: function(data) {
               if(this.validateEmail(data))
               {
                   this.isFormValid = true
               }
               else{
                 this.isFormValid = false

               }
            },
            deep: true
    }
  },
   mounted(){
       var ua = navigator.userAgent.toLowerCase();
var is_safari = (ua.indexOf("safari/") > -1 && ua.indexOf("chrome") < 0);
if(is_safari) {
    var video = document.getElementById('#video1');
    setTimeout(function() {
       video.play();
    }, 50);
}    

   }
}
</script>
<style lang="scss" scoped>
iframe{height:300px !important;border: none;}
#hero{ padding: 30px 0 50px 0!important;background: #edf5ff !important;
     #marks{font-family: "Nunito", sans-serif;gap:40px;
        &{padding-left: 22px; display: flex; }
        .mrk{letter-spacing: 0.3px;font-size: 14px;font-weight: 600;position: relative; color:#4e5074; }
        .mrk::before{content: '';position: absolute;left:-24px;height: 18px;width: 18px; -webkit-mask-size: contain;-webkit-mask-image: url(../assets/v_icon.png); background:#4e5074;  -webkit-mask-repeat: no-repeat;;}
    }
    #form_hero{width:fit-content;text-align: center;}
    &{display: flex;margin-top: 0px;padding: 30px 0;}
    .rating{text-align: center; width: 100%; margin-top: 65px;}
    .fa-star{color: #FFCC0F;}
    .rating{
        span{    color: #000;font-weight: 600;font-size: 14px;margin-bottom: -8px;margin-top: 5px;}
        label{color: #000;font-size: 12px;}
    }
    .player{  width: 100%;height: 100%;top: 0;left: 0;}
    .play{position: absolute;top: 40%;font-size: 80px;z-index: 2;left: 0;right: 0;margin: 0 auto;color: #fff;}
    &{color: #000;font-family: sans-serif;position: relative;}
    h3{font-weight: 600;font-size: 23px; color:#4e5074; font-family: "Nunito", sans-serif!important;}
    h1{font-weight: 600; font-size: 38px;color: #4e5074;line-height: 45px!important;width: 85%; padding-top: 5px; padding-bottom:15px;font-family: 'Poppins', sans-serif;}
    p:nth-child(1){    font-weight: 600;font-size: 18px;}
    p:nth-child(2){    font-size: 18px;}
    p{width: 85%; color:#4e5074; font-size: 16px;line-height: 25px; font-weight: 600;font-family: "Nunito", sans-serif}
    

    .first_box,.sec_box{height: 100%;flex-basis: 50%;position: relative;}
    // video{border-radius: 10px 0 0 10px}
    .brochure_btn{ color: #fff;background: #1480dc; padding: 20px 40px; border-radius: 4px;line-height: 0px;text-transform: uppercase;font-size: 16px;font-weight: 600; margin-bottom: 20px; text-decoration: none;margin-top: 10px;}
    .sec_box{flex-basis: 75%; }
    .first_box:nth-child(1){padding-top: 25px;padding-left: 15px;padding-bottom: 10px;}
    .sec_box:nth-child(2){padding-top: 50px;padding-bottom: 0;padding-right: 0;}
    span{display: block;color: #fff;font-size: 17px;font-weight: 700;margin: 10px 0;text-align: center;}
    .v-btn{text-transform: none;background: #1480dc;color: #fff;padding: 0px 40px;margin-top: 15px;font-size: 15px;font-weight: 600; height: 47px; text-transform: uppercase;
    border-radius: 4px !important;padding: 0 70px;margin-bottom: 0;width: 100%; margin-bottom: 30px; font-family: "Nunito", sans-serif}
    .v-btn--disabled{background:#1cd2ad !important;color: #fff !important;}
    #rating{height: 30px;width: 100px;background-image: url(../assets/rating.png);    background-size: cover;background-position: center;margin: 0 auto;}
}
@media only screen and (max-width: 1750px) {
        .first_box{flex-basis: 50% !important;}
        .sec_box{flex-basis: 70% !important;}
  
}
@media only screen and (max-width: 1600px) {
        .first_box{flex-basis: 30% !important;}
        .sec_box{flex-basis: 80% !important;}
        #form_hero{width: 90% !important;}
        #hero span{margin: 0px auto;}
    #hero .rating{margin-top: 3px;}
}
@media only screen and (max-width: 1450px) {

        .first_box{flex-basis: 40% !important;padding-top: 0 !important;}
        .sec_box{flex-basis: 80% !important;}
}
@media (max-width:1448px){
}
@media only screen and (max-width: 1350px) {

        .first_box{flex-basis:40% !important;}
        .sec_box{flex-basis: 60% !important;}
        #hero h1{font-size: 24px;}
        #hero  p {font-size: 16px;margin-bottom: 0;}
        .line{margin: 10px 0;}
        #hero .v-btn{height:32px !important ;width: 50% !important;}

}
@media (max-width:1291px){
   #hero #marks{flex-direction: column; margin-top:0px;}
}
@media only screen and (max-width: 1050px) {
        .first_box{flex-basis: 80% !important;}
        .sec_box{flex-basis: 80% !important;}
 
}

@media only screen and (max-width: 620px) {
    iframe{height:200px !important;}

        #marks{    flex-direction: column; align-items: center; justify-content: center;gap: 0;}

     #form_hero{width:fit-content !important;margin:0 auto;}
    .sec_box{order: -1;margin-top: 45px;}
    #hero p{width: 100%;}
    #hero .first_box:nth-child(1){  padding: 20px 10px 20px !important;text-align: center;}
    .line{margin: 15px auto;}
    #hero{flex-direction: column;}
}

@media only screen and (max-width: 1400px) and (max-width: 1300px){
    #hero h1{line-height: 32px !important;}
    #hero h3{font-size: 20px;}
    #hero #form_hero {text-align: left;}
    #hero .sec_box:nth-child(2) {padding-top: 20px;}
}
@media (max-width: 1300px){
    #hero .first_box{padding-left:50px!important;}
    #hero .sec_box{padding-right:20px!important;}
}
@media only screen and (max-width: 1200px){
    #hero[data-v-9d9a21ac] {padding: 30px 0 25px 0 !important;}
}
@media only screen and (max-width: 1000px) and (min-width:750px){
}
@media only screen and (max-width: 1275px){
    #hero .v-btn{ height: 40px !important;}
}
@media only screen and (max-width: 900px){
    #hero .v-btn{margin-bottom: 5px; margin-top:0px;}
    .sec_box{padding-right:15px!important;}
    #hero h1{line-height: 25px !important;}
}

</style>