<template v-if="loaded">
    <div id="wroteOnUs" class="centered-big">
        <h3 class="title" v-html="$store.words.featured_in" style="font-size: 23px; font-weight: 500;"></h3>
                <carousel :perPageCustom="[[300, 2], [1024, 7]]" :autoplay="is_mobile()" :mouse-drag="true" :loop="true" :navigationEnabled="true" :navigationNextLabel="'<div class=right></div>'" :navigationPrevLabel="'<div class=left></div>'">
                  <slide v-for="item in logos" :key="item.id" class="item">
                    <div class="pic" :style="'background-image:url('+ item.pic_parsed+')'"></div>
                  </slide>
                </carousel>
      <div class="clr"> </div>
    </div>
</template>
<script>



  export default {
        components: {  },
        props:["logos"],
    data () {
      return {
        loaded:false
      } 
    },
  }
</script>
<style lang="scss" scoped>
.centered-big{max-width:calc(100% - 300px) !important;}
.item{width: 100%;}
.title{margin-bottom: 0 !important ; color:#626585!important; font-size: 22px !important; font-weight: 400!important;font-family: "Nunito", sans-serif;}
p span{font-size: 22px !important; font-weight: 400!important;font-family: "Nunito", sans-serif; }
.pic{height: 82px;background-size: contain;background-position: center; width: 160px;margin-right: 30px; height: 50px; margin-top:15px;}
#wroteOnUs{
  &{padding: 17px 0px 38px;;}
}
  @media (max-width: 600px) 
{
.centered-big{max-width:calc(100% - 10px) !important;}

}
@media only screen and (max-width: 414px){
    #wroteOnUs{padding:10px 0 10px}
}
</style>