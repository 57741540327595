<template v-if="loaded">
  <div id="app" :class="show_side_menu ? 'menu_open' : ''" v-if="loaded">
    <div id="header_mobile" v-if="is_mobile()">
      <a class="logo" href="/"></a>
      <div id="menu_hamburger" v-on:click="show_side_menu = !show_side_menu">
        <div id="nav-icon3">
          <span></span><span></span><span></span><span></span>
        </div>
      </div>
      <a id="mobile_logo" href="/"></a>
    </div>
    <div id="side_menu" v-if="is_mobile()">
      <ul class="sharing_list">
        <li
          class="menu"
          v-for="item in menu_thanksleadgen"
          :key="item.id"
          @click="
            scroll(item.selector);
            show_side_menu = false;
          "
        >
          <a v-text="item.name"></a>
        </li>
        <!-- <li><a href="/login">Log in</a></li> -->
        <li><a @click="scroll('contact')">Signup</a></li>
      </ul>
    </div>
    <div class="home">
      <Header
        @scrollTo="scroll($event)"
        :menu_thanksleadgen="menu_thanksleadgen"
        v-if="!is_mobile()"
        @sign="scroll('wroteOnUs')"
      />
      {{$store.words.courses_title}}
      <heroThanks
        :thanks_hero_title="$store.words.words_hero_title"
        :hero_description="$store.words.words_hero_description"
        :lp="true"
      />

      <Info_thanks/>

      <div class="lecturers" id="lecturers">
        <div class="centered-big">
          <h6 v-html="$store.words.lecturers_subtitle">
            The most successful entrepreneurs in the business
          </h6>
          <div class="title title_big" v-html="$store.words.lecturers_title">
            Showing you how to do what they do
          </div>
          <lecturers_thanks :lecturers="lecturers" />
        </div>
        <div class="clr"></div>
        <a href="/calendly" target=_blank>
          <div class="sign" @click="scroll('plan')">
            Schedule a call
          </div>
        </a>
      </div>
    </div>

    <estate  @pay="scroll('plan')" />
    <Join />
    <aboutThanks :mudule="reasons" />
    <eventThanks :event_thanks="event_thanks" />
    <!-- <plan @pay="$emit('pop_up')" :timerPlan="timerPlan"/> -->
    <wroteThanks :logos="logos" />

    <questions :questions="questions" />

    <Footer
      v-if="is_mobile()"
      @scroll_top="scroll('hero')"
      @open_pop="scroll('wroteOnUs')"
    />
    <FooterHome/>
    <CountDownThanks :countDownThanks="countDownThanks" />
    <v-snackbar v-model="snack_active">
      <div
        id="snack_pic"
        :style="'background-image:url(' + cur_pur.image + ')'"
      ></div>
      <div id="snack_name">{{ cur_pur.name }}</div>
      <div id="snack_time">{{ cur_pur.time }}</div>
    </v-snackbar>
  </div>
</template>

<script>
import FooterHome from "../components/main-home/footerHome.vue";
import Footer from "../components/Footer.vue";
import Header from "../components/header.vue";
import heroThanks from "../components/hero_thanks.vue";
import Info_thanks from "../components/info_thanks.vue";
import lecturers_thanks from "../components/lecturers_thanks.vue";
import estate from "../components/estate.vue";
import wroteThanks from "../components/wrote_thanks.vue";
import aboutThanks from "../components/about_thanks.vue";
import questions from "../components/questions.vue";
import Join from "../components/join.vue";
import eventThanks from "../components/event_thanks.vue";
import CountDownThanks from "../components/countDownThanks.vue";

export default {
  name: "thanksFull",
  components: {
    heroThanks,
    CountDownThanks,
    lecturers_thanks,
    questions,
    Header,
    Footer,
    FooterHome,
    Join,
    eventThanks,
    aboutThanks,
    Info_thanks,
    estate,
    wroteThanks,
  },
  props: ["menu_thanksleadgen"],

  data() {
    return {
      loaded: false,
      signupDialog: false,
      courses: [],
      questions: [],
      join: "",
      cur_pur: [],
      icons: [],
      purchase: [],
      logos: [],
      timerPlan: "",
      snack_active: false,
      show_more: false,
      show_side_menu: false,
      snack: false,
      reasons: [],
      lecturers_thanks: "",
      hero_thanks: "",
      estate: "",
      event_thanks: "",
      countDownThanks: "",
      join_title: "",
    };
  },
  mounted() {
    // localStorage.clear('is_snackbar')
    //  alert(localStorage.getItem('is_snackbar'))

    localStorage.setItem("link", window.location.href);
    if (this.$route.query) {
      localStorage.setItem("utm", JSON.stringify(this.$route.query));
    }

    var self = this;
    this.net_api({ action: "general" }, function(data) {
      self.$store.students_working_title = data.data.words.students_working_title;
    
      self.courses = data.data.courses;
      self.questions = data.data.questions;
      self.icons = data.data.icons;
      self.purchase = data.data.purchase;
      self.logos = data.data.logos;
      self.reasons = data.data.reasons;
      self.lecturers = data.data.lecturers;
      self.about_us_text = data.data.words.words_about_us;
  
   


      self.loaded = true;
    });
    var counter = 0;

    setInterval(function() {
      self.pop_up(counter);
      counter++;
    }, 5000);
  },
  methods: {
    handle() {
      this.$router.push({
        path: `/pricing`,
      });
    },
    pop_up(id) {
      if (id < this.purchase.length) {
        setTimeout(() => {
          this.snack_active = true;
          this.cur_pur = this.purchase[id];
        }, 1000);
      }
    },
    signup_popup() {
      this.signupDialog = true;
    },

    scroll(value) {
      var elmnt = document.getElementById(value);
      elmnt.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
<style lang="scss" scoped>
#snack_name {
  color: #4d4b70;
  padding-left: 20px;
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  line-height: 20px;
}
#snack_time {
  color: #c7c6cb;
  font-weight: 400;
  font-size: 13px;
  position: absolute;
  left: 48px;
  bottom: 14px;
  padding-left: 36px;
  line-height: 10px;
  padding-bottom: 5px;
}
#snack_pic {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 70px;
  height: 80px;
  border-radius: 20px!important;
}
.lecturers {
  background: #f6f9fd;
  padding: 40px 0 60px 0;
}
// #lecturers{
//   .col-md-15:nth-child(5n+1){clear: both;}
// }
.lecturers .title_big {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  font-weight: 700 !important;
  margin-top: 13px;
  color: #4e5074;
  line-height: 25px;
}
.lecturers h6 {
  text-align: center;
  font-size: 22px !important;
  font-weight: 600 !important;
  color: #4e5074;
  text-transform: none !important;
}
.lecturers .md-theme-default a:not(.md-button), .md-theme-default a:not(.md-button):hover{text-decoration: none;}
// .it:nth-child(4n+3){clear: both;}
.home {
  max-width: 100%;
  overflow: hidden;
  padding-bottom:0px;
}
.v-application .grey.lighten-4[data-v-7dd1f4c4] {
  background-color: #eeeeee !important;
}
.v-card[data-v-7dd1f4c4] {
  min-height: 450px;
}
.v-application p {
  line-height: 20px;
  margin-bottom: 50px !important;
}
#header_mobile {
  position: fixed;
  height: 50px;
  top: 0;
  background: #fff;
  left: 0;
  width: 100%;
  z-index: 99999;
}
.sign {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  background: #1480dc;
  width: 180px;
  height: 40px;
  margin: 0 auto;
  border-radius: 4px;
  color: #fff;
  line-height: 42px;
  text-transform: uppercase;
  margin-top: 10px;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
}
.sign:hover {
  background: #13505b !important;
}

li[data-v-29e8c3c6]:hover {
  color: #13505b;
}
@media only screen and (max-width: 1000px) {
  .lecturers .title_big {
    line-height: 40px !important;
  }
}
@media only screen and (max-width: 414px) {
  .lecturers .title_big {
    line-height: 30px !important;
  }
  .lecturers h6 {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 900px) {
  .v-application p {
    line-height: 40px !important;
  }
}
@media only screen and (max-width: 360px) {
  .v-application p {
    line-height: 30px !important;
    margin-bottom: 10px !important;
  }
  .lecturers h6 {
    font-size: 18px !important;
  }
  .v-application .title {
    font-size: 25px !important;
  }
  .left {
    display: none;
  }
  .right {
    display: none;
  }
}
</style>
