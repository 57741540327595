<template v-if="loaded">
    <div id="app" :class="show_side_menu ? 'menu_open' : ''">
        <div id="header_mobile" v-if="is_mobile()">
            <a class="logo" href="/"></a>
            <div id="menu_hamburger" v-on:click="show_side_menu = !show_side_menu">
                <div id="nav-icon3">
                <span></span><span></span><span></span><span></span>
                </div>
            </div>
            <a id="mobile_logo" href="/"></a>
        </div>
        <div id="side_menu" v-if="is_mobile()">
            <ul class="sharing_list">
                <li
                class="menu"
                v-for="item in menu_homepage"
                :key="item.id"
                @click="
                    scroll(item.selector);
                    show_side_menu = false;
                "
                >
                <a v-text="item.name"></a>
                </li>
                <!-- <li><a href="/login">Log in</a></li> -->
                <li><a @signup="scroll('contact_pop')">Signup</a></li>
            </ul>
        </div>
        <div id="instructors_page">
            <Header
            @scrollTo="scroll($event)"
            :menu_homepage="menu_homepage"
            v-if="!is_mobile()"
            @sign="$emit('pop_up_contact')"
        />

        <div id="hero" v-for="item in $store.instructors_page" :key="item.id">
            <div class="pic_hero" :style="'background-image:url('+item.pic_parsed+')'">
                <h1>{{item.title}}</h1>
                <div class="video">
                    <video id="video1" width="550px" height="150px" ref="myid" autoplay muted loop playsinline>
                        <source src="https://admin.urecenter.com/themes/MNS/videos/newYork_new_mp4.mp4" type="video/mp4" />
                        <source src="https://admin.urecenter.com/themes/MNS/videos/newYork_new_mp4.mp4" type="video/webm" />
                        Your browser does not support HTML video.
                    </video>
                </div>
            </div>
        </div>

<div id="team">
    <div class="centered_l">
        <h2 v-html="$store.words.Instructors_title"></h2>
       
        <div class="item col-lg-3 col-md-3 col-sm-6 col-xs-6" v-for="item in lecturers" :key="item.id">
        <v-hover  :open-delay="210" >
            <a :href="'/instructors/'+item.link">
        <v-card :class="'mx-auto ' " color="white" >
        <div class="rotate">
        <v-img :aspect-ratio="16/9"  class="pic" :style="'background-image:url('+item.pic_parsed+')'">
        </v-img>
        </div>
        <v-card-text class="wrap" style="position: relative;" >
            <div class="dets">
                <div class="wrapper">
                    <h3>{{item.name}}</h3>
                    <p>{{item.job}}</p>
                </div>
                <div id="line_lec"></div>
                <div class="logo" :style="'background-image:url(' + item.logo_parsed+ ')'"></div>
            </div>

        </v-card-text>
        </v-card>
        </a>

        </v-hover>
        </div>
        <div class="clr"></div>
    </div>
</div>

<div id="say">
    <div class="centered_l" v-for="item in $store.reviews_instructors" :key="item.id">
        <h2 class="title">{{item.main_title}}</h2>
        <div class="col-md-6">
            <div class="review_one">
                <div class="wrap">
                    <!-- <div class="rotate"> -->
                        <div class="pic" :style="'background-image:url('+item.pic_parsed_1+')'"></div>
                    <!-- </div> -->
                    <div class="wrapper_name">
                        <h3>{{item.section_1_name}}</h3>
                        <p>{{item.section_1_job}}</p>
                    </div>
                </div>
                <div class="wrapper_review">
                    <div class="review_text" v-html="item.section_1_review"></div>
                    <div id="triangle_up"></div>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="review_two" v-for="item in $store.reviews_instructors" :key="item.id">
                <div class="wrapper_review">
                    <div class="review_text" v-html="item.section_2_review"></div>
                    <div id="triangle_down"></div>
                </div>
                <div class="wrap">
                    <div class="wrapper_name">
                        <h3>{{item.section_2_name}}</h3>
                        <p>{{item.section_2_job}}</p>
                    </div>
                    <!-- <div class="rotate"> -->
                        <div class="pic" :style="'background-image:url('+item.pic_parsed_2+')'"></div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
        <div class="clr"></div>

        <div class="from_section col-md-12" v-for="item in $store.reviews_instructors" :key="item.id">
            <div class="text_side col-md-6">
                <h2>{{item.title}}</h2>
                <p v-html="item.text"></p>
            </div>
            <div class="col-md-6">
                <div class="wrapper_form">
                    <h4 v-html="$store.words.certified_title"></h4>
                    <form @submit.prevent="send()">
                        <v-text-field class="input" label="Full name*" v-model="form.name" outlined></v-text-field>
                        <v-text-field  class="input" label="Email*" v-model="form.email" outlined></v-text-field>
                        <!-- <v-text-field type="number"  class="input" label="Phone number*"  v-model="form.phone" outlined></v-text-field> -->
                        <div id="inter">
                            <VuePhoneNumberInput v-model="form.phone"/>
                        </div>
                        <input type="submit" value="see upcoming courses" class="btn"/>
                    </form>
                    <p>By sharing your email, you agree to our <span><a href="https://urecenter.com/privacy" target="_blank">Privacy Policy</a></span> and <span><a href="https://urecenter.com/terms" target="_blank">Terms of Servies</a></span></p>
                </div>
            </div>
        </div>
        <div class="clr"></div>
    </div>
</div>

<div id="list_inst">
    <h2 class="title" v-html="$store.words.list_instructors_title"></h2>
    <div class="wrapper_background">
        <div class="centered_l">
            <div class="wrapper_title col-md-12 paddingbottom">
                <div class="title col-md-4">
                    <h3>Name</h3>
                    <div class="line_title"></div>
                </div>
                <div class="title col-md-4">
                    <h3>Headline</h3>
                    <div class="line_title"></div>
                </div>
                <div class="title col-md-4">
                    <h3>Company</h3>
                    <div class="line_title"></div>
                </div>
            </div>
                <div class="wrapper_list col-md-12 " v-for="item in lecturersData" :key="item.id">
                    <div class="col-md-4">
                        <router-link :to="'/instructors/'+item.link">
                        <div class="wrapper_name">
                            <div class="pic" :style="'background-image:url('+item.pic_parsed+')'"></div>
                            <h3 class="name">{{item.name}}</h3>
                        </div>
                        </router-link>
                    </div>
                    <div class="job_col col-md-4 ">
                        <router-link :to="'/instructors/'+item.link">
                        <p class="job">{{item.job}}</p>
                        </router-link>
                    </div>
                    <div class="col-md-4 ">
                        <a :href="item.link_logo">
                            <div class="logo" :style="'background-image:url(' + item.logo_parsed+ ')'"></div>
                        </a>
                    </div>
                </div>
            <div class="clr"></div>

              <div class="text-center">
                <v-pagination
                v-model="page"
                :length="pagination_length"
                circle
                ></v-pagination>
            </div>
        </div>
    </div>
</div>

<div class="newsletter">
    <p>Sign up to our newsletter to get the most updated news and ideas here!</p>
    <div class="wrapper_input">
        <input type="text" placeholder="Email" >
        <input type="submit" value="SEND" class="btn">
    </div>
</div>

<FooterHome/>

        </div>
    </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import Header from "../components/header.vue";
import FooterHome from "../components/main-home/footerHome.vue";
export default {
    components:{Header, FooterHome,VuePhoneNumberInput},
    props: ["menu_homepage"],
        data() {
            return {
                form: {
                name: "",
                email: "",
                phone: "",
                },
                loaded: false,
                show_side_menu: false,
                page:1,
                lecturers:[],
                
            };
        },
        computed:{
            lecturersData(){
                return this.lecturers.slice((this.page * 10 - 10),((this.page == 1 ? 0 : this.page) * 10 + 10))
             },
             pagination_length(){
                 return  Math.ceil(this.lecturers.length / 10)
             }
        },
        mounted(){
             this.net_api({ action: "instructors",data:{} }, (data) => {
            this.lecturers = data.data.lecturers
           
        });
        },
        methods:{
            autofill(email) {
      this.form.email = email;
    },
      send(){
                var utm = null;
                var link = localStorage.getItem('link');
                if( JSON.parse(localStorage.getItem('utm')))
                {
                    utm = JSON.parse(localStorage.getItem('utm'))
                }
                // && this.validatePhone(this.form.phone)
                if(this.form.name.length < 1 || this.form.email.length < 1 ||  this.isNull(this.form.phone))
                    // if(this.form.name.length > 1 && this.validateEmail(this.form.email) && this.validatePhone(this.form.phone))
                    {
                       this.alert("Please Fill the form currectly")
                    }
                else
                    {
                        this.net_api({ action: 'contact',data:{form:this.form,utm:utm,link:link} }, function(data){ 
                            console.log(data)
                            window.location = "/thanks"
                        });
                    }
            },
        },
}
</script>

<style lang="scss" scoped>
#header_mobile {
  position: fixed;
  height: 50px;
  top: 0;
  background: #fff;
  left: 0;
  width: 100%;
  z-index: 99999;
}

.sign {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  background: #1480dc;
  width: 180px;
  height: 40px;
  margin: 0 auto;
  border-radius: 4px;
  color: #fff;
  line-height: 42px;
  text-transform: uppercase;
  margin-top: 10px;
}
.sign:hover {
  background: #13505b !important;
}

li[data-v-29e8c3c6]:hover {
  color: #13505b;
}
    #instructors_page{padding-top:80px;
        #hero{
            .pic_hero{width:100%; min-height: 600px; background-position: center; background-size: cover; background-repeat: no-repeat;    background-blend-mode: overlay; background-color: #44445e;}
            h1{color:#fff; text-align: center; padding-top:80px; text-transform: capitalize; font-size: 40px; width:40% ; margin: 0 auto; line-height:45px}
            .video{margin: 0 auto; text-align: center; margin-top:50px;}
            video{border-radius: 20px;}
        }
        #team{ padding:40px 0; background:#f1f7ff;
            h2{text-transform: capitalize; color:#3d3d3d; text-align: center;  padding-bottom:20px; font-size: 36px;}
            .v-responsive__content p{ font-size: 16px!important; display: block; text-align: center; width: 80%;margin: 0 auto; line-height: 18px!important;position:absolute;  top:25px; left:0px; right:0px; margin:0 auto;}
            .wrapper{height: 76px;
                h3{font-size: 18px; font-weight: 700; color:#3d3d3d}
                p{font-size: 13px;}
            }
            #line_lec{margin:20px 0 30px 0; height: 0.5px; background:rgb(228, 227, 227)}
            .logo{background-position: center;background-size: contain;height: 35px; width: 100%; margin-top: 10px; background-position: center;}
            .rotate{width: 150px;position: relative;height: 150px;box-shadow: 0 0 21px 4px rgb(0 0 0 / 14%);margin: 0 auto;border-radius: 46px;overflow: hidden;transform: rotate(45deg);margin-top: 5px;}
            .pic{max-width: 1000px;transform: rotate(-45deg);position: absolute;left: -13px;top: -13px;bottom: -13px;right: -13px;background-size: cover;background-position: top;background-repeat: no-repeat;}
            .v-card{min-height:400px; border-radius: 40px!important; padding-top: 25px!important;}
            .wrap{margin-top:15px}
            .dets{text-align: center;}
            .v-responsive__content .liner[data-v-7dd1f4c4] {
                background: linear-gradient(
            90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 35%, rgba(0, 0, 0, 0.4) 100%);
            }
            .dets{text-align: center;width: 100%;padding-top: 10px;margin-bottom: 5px;min-height: 75px;}
            .v-card {margin-bottom: 20px;padding: 10px;margin: 10px;  box-shadow: 0 0 31.9px 6.1px rgba(0, 0, 0, 0.08);}
            .on_hover{opacity: 0;}
            .v-sheet.v-card{border-radius: 0;padding: 0; }
            .v-application .grey.lighten-4 {background-color: #f6f9fd!important;}
            .v-card--reveal {  bottom: 0; justify-content: center; position: absolute; width: 100%;padding: 5px;}
            .content{height: 100%;width: 100%; }
            .wrap{padding-top: 0 !important;padding-bottom: 0;}
        }

        #say{padding:40px 0;
            .title{text-align: center; color:#3d3d3d; margin-bottom:50px; font-weight: 700;}
            .review_one{
            .wrap{display:flex;width: 65%;}
            .rotate{width: 120px;position: relative;height: 120px;box-shadow: 0 0 21px 4px rgb(0 0 0 / 14%);margin: 0 auto;border-radius: 46px;overflow: hidden;transform: rotate(45deg);margin-top: 5px;}

            // .pic{transform: scale(1.4) rotate(-45deg);background-size: cover;background-position: top;background-repeat: no-repeat;display: block;width: 100%; height: 100%;}
            .pic{background-size: cover;background-position: top;background-repeat: no-repeat;display: block;width: 70px; height: 70px; border-radius: 50px; margin-right:10px;}

                .wrapper_name{padding-top: 10px;
                    p{color:rgba(61, 61, 61, 0.5) ; font-size: 14px;}
                }
                .wrapper_review{background:#1480dc; width:100%; min-height:200px; border-radius: 30px; margin-top:45px;
                    .review_text{color:#fff; padding:30px; font-size: 16px; position: relative;;}
                    #triangle_up{width: 0; height: 0; border-left: 30px solid transparent; border-right: 30px solid transparent;  border-bottom: 40px solid #1480dc; position:absolute; top:112px; left:25px}
                }
            }
            .review_two{
                .wrap{display:flex; justify-content: flex-end}
                .rotate{width: 120px;position: relative;height: 120px;box-shadow: 0 0 21px 4px rgb(0 0 0 / 14%);margin: 0 auto;border-radius: 46px;overflow: hidden;transform: rotate(45deg);margin-top: 5px;}
                // .pic{transform: scale(1.4) rotate(-45deg);background-size: cover;background-position: top;background-repeat: no-repeat;display: block;width: 100%; height: 100%;}
                .pic{background-size: cover;background-position: top;background-repeat: no-repeat;display: block;width: 70px; height: 70px; border-radius: 50px; margin-left:10px;}

                .wrapper_name{padding-top: 10px;
                    p{color:rgba(61, 61, 61, 0.5) ; font-size: 14px; text-align: right}
                }
                .wrapper_review{background:#242424; width:100%; min-height:200px; border-radius: 30px; margin-bottom:30px;
                    .review_text{color:#fff; padding:30px; font-size: 16px; position: relative;;}
                    #triangle_down{ width: 0; height: 0; border-left: 30px solid transparent; border-right: 30px solid transparent; border-top: 40px solid #242424; position:absolute; bottom:102px; right:25px;}
                }
            }
            .from_section{padding-top:40px;
                .text_side{padding-top:80px;
                    h2{color:#3d3d3d; line-height:40px; width:75%; margin-bottom:30px; font-size: 36px;}
                    p{width:80%; color:#4e5074}
                }
                .wrapper_form{width:100%; height:380px; background:rgba(0, 0, 0, 0.07); border-radius: 30px; padding:30px;  box-shadow: -4.1px 10.2px 14.2px 1.8px rgba(0, 0, 0, 0.13);
                    h4{text-align:center; margin-bottom:15px; font-weight: 800;}
                    #inter{padding-right:0px; padding-left:0px; margin-bottom:8px;}
                    .btn{width:100%; margin-top:10px; color:#fff; border-radius: 4px; padding:25px 0; line-height: 0px; text-transform: uppercase; font-size: 16px; font-weight: 700;}
                    p{font-size: 12px; width:100%; color:#74769c; font-weight: 600; margin-top:20px; text-align: center; font-style: italic;
                        span{font-weight: 800;}
                        a{color: #74769c;}
                    }
                }
            }
        }

        #list_inst{padding:30px 0 0 0;
            .wrapper_background{background:#f3f6fa; padding:0 0 40px 0;}
            .wrapper_title{
                .title{text-align: left; width: 90%; font-weight: 700; color:#3d3d3d}
            }
            h2{text-transform: capitalize; color:#3d3d3d; text-align: center; padding-bottom:20px;font-weight: 700;}
            .col-md-12{display: flex; align-items: center;}
            .wrap{border-bottom:1px solid #ccc;}
            .wrapper_name{display:flex; align-items: center}
            h3{font-size: 19px;}
            .line_title{height: 1px; width:100%; background:#ccc;}
            .pic{width: 70px; height: 70px; background-size: cover; background-position: center; background-repeat: no-repeat; border-radius: 100%; margin-right:10px;}
             .logo{background-position: center;background-size: contain;height: 35px; width: 45%; background-position: center; text-align: left; cursor: pointer;}
             .name{text-decoration: underline;}
             .job{color:#878787; font-weight: 700;}
        }

        .newsletter{width:100%; min-height:100px; background-image: url(../assets/pic_building.jpg); background-position: initial; background-size: cover; background-repeat: no-repeat; background-blend-mode: overlay;padding:30px; display:flex; justify-content: center; align-items: center;  background-color: #293845;
            p{color:#fff; margin-right:20px; font-size: 22px; margin-bottom:0px;}
            input{background:#fff; padding-left: 15px; margin-right:20px; border-radius: 5px; height: 30px; font-size: 16px; height: 34px; outline:none;}
            .btn{background:#1480dc; color:#fff; padding: 0px 36px; height: 34px; font-weight: 500;}
        }
        @media(max-width:1545px) and (min-width:988px){
            #hero{ 
                h1{width:70%}
                .video{margin-top:30px}
            }
        }
        @media(max-width:987px) and (min-width:600px){
            #hero{ 
                h1{width:80%}
                .video{margin-top:30px}
            }
        }
        @media(max-width:1148px){
            .newsletter{flex-direction: column;
                p{line-height: 25px; text-align: center; margin-bottom:20px}
                .wrapper_input{display: flex;margin: 0 auto;
                    input{margin-bottom:10px;}
                    .btn{width:50%;margin: 0 auto;}
                }
            }
        }
        @media(max-width:414px){
            padding-top:0px;
            #hero{ 
                .pic_hero{min-height: 410px;}
                h1{width:80%; font-size: 30px; line-height: 35px;padding-top: 25px;}
                .video{margin-top:30px}
                video{width:360px}
            }
            #team {
                h1{font-size: 25px;}
                .rotate{width:130px; height:130px}
            }
            #say{
                .from_section .text_side{padding-top:0px;
                    h1{width: 100%; text-align: center; font-size: 25px; line-height:30px}
                    p{width: 100%; text-align: center;}
                }
                .wrapper_name{
                    h3{line-height: 20px;}
                }
                .review_one .wrapper_review{margin-top:25px;}
                .review_one .wrapper_review #triangle_up{top:90px; left:40px}
                .review_two .wrapper_review #triangle_down{bottom:90px; right: 36px;}
                .from_section .wrapper_form{
                     p{margin-top:10px;}
                     .btn{font-size: 15px;}
                    #inter .input-tel__input{width:177px!important}
                }
            }
            #list_inst{
                .pic{height: 55px;}
                .name{font-size: 16px;}
                .job_col{padding-bottom:0px;
                    p{font-size: 12px;}
                }
                .logo{width:100%;}

            }
            .newsletter{flex-direction: column;
                p{line-height: 25px; text-align: center; font-size: 18px; margin-bottom:10px}
                .wrapper_input{display: flex;
                    input{margin-bottom:10px;}
                    .btn{width:50%;margin: 0 auto;}
                }
            }
        }
        @media(max-width:360px){
            #hero{ 
                .pic_hero{min-height: 380px;}
                h1{width:90%; font-size: 25px; line-height: 35px;padding-top: 20px;}
                .video{margin-top:20px}
                video{width:330px}
            }    
        }
    }
</style>