<template v-if="loaded">
<div id="app" :class="show_side_menu ? 'menu_open' : ''">
        <div id="header_mobile" v-if="is_mobile()">
            <a class="logo" href="/"></a>
            <div id="menu_hamburger" v-on:click="show_side_menu = !show_side_menu">
                <div id="nav-icon3">
                <span></span><span></span><span></span><span></span>
                </div>
            </div>
            <a id="mobile_logo" href="/"></a>
        </div>
        <div id="side_menu" v-if="is_mobile()">
            <ul class="sharing_list">
                <li
                class="menu"
                v-for="item in menu_homepage"
                :key="item.id"
                @click="
                    scroll(item.selector);
                    show_side_menu = false;
                "
                >
                <a v-text="item.name"></a>
                </li>
                <!-- <li><a href="/login">Log in</a></li> -->
                <li><a @signup="scroll('contact_pop')">Signup</a></li>
            </ul>
        </div>
        <div id="blog">
            <Header
            @scrollTo="scroll($event)"
            :menu_homepage="menu_homepage"
            v-if="!is_mobile()"
            @sign="$emit('pop_up_contact')"
        />

        <div id="hero">
            <div class="banner_hero">
                <div class="main_title">
                    <p>welcome to</p>
                    <h1> Urban Real Estate Center Blog</h1>
                </div>
            </div>
            <div class="col-md-12">
                <div class="col-md-2" v-for="item in articles.slice(0, 6)" :key="item.id">
                    <router-link :to="item.link" >
                        <div class="item">
                            <div class="pic" :style="'background-image:url('+item.pic_parsed+')'">
                                <div class="wrapper">
                                    <!-- <div class="sub_title">{{item.sub_title}}</div> -->
                                    <h2>{{item.title}}</h2>
                                    <!-- <p v-html="item.short_desc"></p> -->
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="clr"></div>
        </div>
        <div class="clr"></div>
        
        
        <div class="centered_h">

        <div class="latest col-lg-8 col-md-8 col-xs-12">
            <div class="title_posts">
                <h2>Latest Articles</h2>
                <!-- <p>view all</p> -->
            </div>
                <div class="col-lg-6 col-md-6 col-xs-12" v-for="item in articles.slice(0,2)" :key="item.id">
                    <a :href="item.link" target="_blank">
                        <div class="item">
                            <div class="pic" :style="'background-image:url('+item.pic_parsed+')'"></div>
                            <div class="wrapper">
                                <div class="text">
                                    <div v-if="item.category" class="sub_title">{{item.category.name}}</div>
                                    <h2>{{item.title}}</h2>
                                    <!-- <p v-html="item.short_desc"></p> -->
                                </div>

                                <div class="wrapper_bottom">
                                    <div class="pic" :style="'background-image:url('+item.writer.pic_parsed+')'"></div>
                                    <p v-if="item.writer">{{item.writer.name}}</p>
                                    <p class="date">{{item.date_created}}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
        </div>
 
         <div class="side col-lg-4 col-md-4 col-xs-12">
            <!-- <div class="wrapper_cates">
                <h2>Categories</h2>
                <div class="wrapper" v-for="cat in categories" :key="cat.id">
                    <p>{{cat.name}}</p>
                    <p class="cate">{{cat.id}}</p>
                </div>
            </div> -->
            <div class="wrapper_popular">
                <h2>popular Articles</h2>
                <div class="wrapper" v-for="item in articles.slice(2, 5)" :key="item.id" :href="item.link">
                    <div class="pic_popular" :style="'background-image:url('+item.pic_parsed+')'"></div>
                    <div class="info_wrapp">
                    <p>{{item.title}}</p>
                    <p class="date">{{item.date_created}}</p>
                    </div>
                </div>
            </div>
            <div class="wrapper_news">
                <h2>Join Our Newsletter</h2>
                <div class="wrapper">
                   <p>Join +10K Real Estate Proffesional who get updated</p>
                   <div class="wrapper_input">
                    <input type="text" placeholder="Your Email Address"> 
                    <input type="submit" value="SIGN UP"  class="btn">
                   </div>
                </div>
            </div>
            <!-- <div class="banner"></div> -->
        </div>
        <div class="clr"></div>
        <div id="cat" class="col-lg-4 col-md-4 col-sm-4 col-xs-12" v-for="item in articles" :key="item.id">
            <div class="title_posts">
                <div class="">
                    <a :href="item.link" target="_blank">
                        <div class="item">
                            <div class="pic" :style="'background-image:url('+item.pic_parsed+')'"></div>
                            <div class="wrapper">
                                <div class="text">
                                    <div class="sub_title" v-if="item.category.name">{{item.category.name}}</div>
                                    <h2>{{item.title}}</h2>
                                    <p v-html="item.short_desc" v-if="item.short_desc"></p>
                                </div>
                                <div class="wrapper_bottom">
                                    <div class="pic" :style="'background-image:url('+item.writer.pic_parsed+')'"></div>
                                    <p v-if="item.writer">{{item.writer.name}}</p>
                                    <p class="date">{{item.date_created}}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div> 
            </div>
            </div>

        <div class="clr"></div>

        </div>


        <div class="newsletter">
            <p>Sign up to our newsletter to get the most updated news and ideas here!</p>
            <div class="wrapper_input">
                <input type="text" placeholder="Email" >
                <input type="submit" value="SEND" class="btn">
            </div>
        </div>

    <FooterHome/>

    </div>
</div>
</template>

<script>
import Header from "../components/header.vue";
import FooterHome from "../components/main-home/footerHome.vue";
export default {
    components:{Header, FooterHome},
    props: ["menu_homepage"],
    data(){
        return{
            loaded: false,
            show_side_menu: false,
            id: this.$route.params.id,
            categories: [],
            articles: [],
            instructor:[],
        }
    },
    mounted() {
        //  alert(this.id)
        this.net_api({ action: "article/blog",data:{id:this.id} }, (data) => {
            this.categories = data.data.categories
            this.articles = data.data.articles
        });

        this.net_api({ action: "instructor",data:{id:this.id} }, (data) => {
            this.instructor = data.data.instructor
        });
    }

}
</script>

<style lang="scss" scoped>
#header_mobile {
  position: fixed;
  height: 50px;
  top: 0;
  background: #fff;
  left: 0;
  width: 100%;
  z-index: 99999;
}

.sign {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  background: #1480dc;
  width: 180px;
  height: 40px;
  margin: 0 auto;
  border-radius: 4px;
  color: #fff;
  line-height: 42px;
  text-transform: uppercase;
  margin-top: 10px;
}
.sign:hover {
  background: #13505b !important;
}

li[data-v-29e8c3c6]:hover {
  color: #13505b;
}

#blog{padding-top:80px;

    #hero{width: 100%; min-height: 400px;margin-bottom:55px;
    .banner_hero{background-image:url(../assets/background_inst2.png); background-position: center; background-size:cover; background-repeat:no-repeat; width:100%; height:220px; margin: 0 auto; text-align: center; color:#fff;
    .main_title{padding-top:70px;}
        p{text-transform: uppercase; margin-bottom:0px;}
    }
        .col-md-12{padding:0px;}
        .col-md-2{padding:0px;}
        .item{max-height: 560px;
            .pic{width:100%; height:400px; background-position: initial; background-size: cover; background-repeat: no-repeat; background-blend-mode: overlay;  background-color: #282833;}
            .wrapper{padding:120px 20px 0px 20px; color:#fff;
                h2{margin-bottom:20px;}
                p{overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 3; line-clamp: 3; -webkit-box-orient: vertical;}
            }
        }
    }

    .latest{
        .col-lg-6:nth-child(2n+2){clear: both;}
        a{text-decoration: none;}
        .title_posts{display: flex;justify-content: space-between; align-items: stretch;
            h2{text-transform: uppercase; font-size: 20px;color:#3d3d3d}
            p{color:#1480dc; font-size: 18px; font-weight: 800; cursor: pointer;}
        }
        .item{border:1px solid #e0e0e0; margin-bottom:30px;cursor: pointer; max-height: 600px; width: 100%;
        .pic{background-size: cover; background-position:center; background-repeat: no-repeat; width:100%; height: 300px;}
            .wrapper{ 
                .text{padding:0 10px;min-height:230px;
                    .sub_title{color:#1480dc; font-size: 18px; font-weight: 700; padding:12px 0 10px 0;}
                    h2{color:#3d3d3d; line-height:35px}
                    p{color:#3d3d3d; font-size: 20px; line-height: 30px; margin:20px 0 0 0;;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 3; line-clamp: 3; -webkit-box-orient: vertical;}
                }
                .wrapper_bottom{display:flex; justify-content:center;align-items: center; border-top:1px solid #e0e0e0; padding: 10px 0;
                    .pic{width:35px; height:35px; border-radius: 50%;}
                    p{margin-bottom:0px;color:#3d3d3d; font-size:14px; padding-left: 5px;}
                    .date{margin-bottom:0px;color:#3d3d3d; font-size:14px; position: relative; margin-left: 40px;;}
                    .date::before{content:""; position:absolute; background-image: url(../assets/timeGrey.png); background-size:contain; background-position: center; background-repeat: no-repeat; width: 20px; height: 20px; left: -20px; top: 0px;}
                }
            }
        }
    }

    .side{padding-right:40px;
        .wrapper_cates{margin-bottom:30px;
            h2{text-transform: uppercase;padding-bottom:30px; color:#3d3d3d;font-size: 25px;}
            .wrapper{display:flex; justify-content: space-between; 
                p{color:#3d3d3d; font-size: 18px; text-transform: capitalize; font-weight: 800;}
                .cate{background:#171e3c; width:25px; height: 25px; border-radius:50%; color:#fff; text-align: center; line-height:25px; font-size: 15px;}
            }
            
        }
        .wrapper_popular{margin-bottom:30px;
            h2{text-transform: uppercase;padding-bottom:10px; color:#3d3d3d; font-size: 25px;}
            .wrapper{display:flex;align-items: center; margin-bottom:30px;
                .pic_popular{background-size: cover; background-position: center; background-repeat:no-repeat; min-width:75px; height:75px; border-radius: 50%;} 
                p{color:#3d3d3d; font-size: 18px; text-transform: capitalize; font-weight: 800; padding-left: 20px; margin-bottom:0px;;}
                .date{font-size: 16px; color:#3d3d3d; font-size: 14px; font-weight: 400; position: relative;padding-left: 45px; margin-top:5px;}
                .date::before{content:""; position:absolute; background-image: url(../assets/timeGrey.png); background-size:contain; background-position: center; background-repeat: no-repeat; width: 20px; height: 20px; left: 18px; top: 0px;}
            }
        }
        .wrapper_news{margin-bottom:10px;
            h2{text-transform: uppercase;padding-bottom:10px; position: relative; color:#3d3d3d; font-size: 25px;}
            h2::after{content:""; position:absolute; top:0px; right:0px; width:50px}
            .wrapper{ padding:0 10px 20px 0;
                p{color:#3d3d3d; font-size: 15px; text-transform: capitalize;}
                .wrapper_input{display:flex; align-items: center;}
                input{border:1px solid #e0e0e0; padding-left:15px; padding:10px 20px; border-radius: 6px; outline: none;}
                .btn{background: #1480dc; color:#fff; height: 50px; margin-left: 10px; font-weight: 600;}
            }
        }
        .banner{width: 100%; height:350px; background:#ccc;margin-bottom:30px;}
    }

    #cat{
        &:nth-child(3n+1){clear:both}
        a{text-decoration: none;}
        .title_posts{justify-content: space-between; align-items: stretch;
            h2{text-transform: uppercase; font-size: 20px;color:#3d3d3d}
            p{color:#1480dc; font-size: 16px !important; font-weight: 600; cursor: pointer;}
        }
        .item{border:1px solid #e0e0e0; margin-bottom:30px;cursor: pointer; max-height: 600px; width: 100%;
        .pic{background-size: cover; background-position:center; background-repeat: no-repeat; width:100%; height: 300px;}
            .wrapper{
                .text{padding: 0 10px;
                    .sub_title{color:#1480dc; font-size: 18px; font-weight: 700; padding:12px 0 10px 0;}
                    h2{color:#3d3d3d; line-height:35px}
                    p{color:#3d3d3d; font-size: 20px; line-height: 30px; margin:20px 0 0 0;;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 3; line-clamp: 3; -webkit-box-orient: vertical;}
                }
                .wrapper_bottom{display:flex; justify-content:center;align-items: center; border-top:1px solid #e0e0e0; padding: 10px 0;
                    .pic{width:35px; height:35px; border-radius: 50%;}
                    p{margin-bottom:0px;color:#3d3d3d; font-size:14px; padding-left: 5px;}
                    .date{margin-bottom:0px;color:#3d3d3d; font-size:14px; position: relative; margin-left: 40px;;}
                    .date::before{content:""; position:absolute; background-image: url(../assets/timeGrey.png); background-size:contain; background-position: center; background-repeat: no-repeat; width: 20px; height: 20px; left: -20px; top: 0px;}
                }
            }
        }
    }

   

    .newsletter{width:100%; min-height:100px; background-image: url(../assets/pic_building.jpg); background-position: initial; background-size: cover; background-repeat: no-repeat; background-blend-mode: overlay;padding:30px; display:flex; justify-content: center; align-items: center;  background-color: #293845;
        p{color:#fff; margin-right:20px; font-size: 22px; margin-bottom:0px;}
        input{background:#fff; padding-left: 15px; margin-right:20px; border-radius: 5px; height: 30px; font-size: 16px; height: 34px; outline:none;}
        .btn{background:#1480dc; color:#fff; padding: 0px 36px; height: 34px; font-weight: 500;}
    }

    @media(max-width:414px){ 
        .title_posts p{padding-right:15px;}
        .newsletter{flex-direction: column;
            p{line-height: 25px; text-align: center; font-size: 18px; margin-bottom:10px}
            .wrapper_input{display: flex;
                input{margin-bottom:10px;}
                .btn{width:50%;margin: 0 auto;}
            }
        }
    }
}

</style>