<template>
    <div id="lecturers_home">
        <h2 v-html="$store.words.home_lectures_title"></h2>

        <div class="it centered_l">
      <carousel
        :autoplay="false"
        :perPage="(is_mobile() ? 2.2 : 4)"
        
        :scrollPerPage="false"
        :paginationEnabled="false"
        :mouse-drag="true"
        :rtl="false"
        :loop="false"
        :navigationEnabled="true"
        :navigationNextLabel="'<div class=right_lecturers></div>'"
        :navigationPrevLabel="'<div class=left_lecturers></div>'"
      >
        <slide v-for="data in lecturers" :key="data.id" class="item">
          <v-hover :open-delay="210" >
            <v-card 
              :class="'mx-auto ' + (data.video ? 'has_video' : '')"
              color="grey lighten-4"
            >
              <v-img 
                :aspect-ratio="16 / 9"
                class="pic" 
                :style="'background-image:url(' + data.pic_parsed + ')'" 
              >
                <div class="hover_play" v-if="data.video" @click="open_video(data)" >
                  <i class="far fa-play-circle" ></i>
                </div>
                <div class="liner on_hover  white--text" style="height: 100%;">
                  <div
                    class="content_card"
                   @click="(data.video ? open_video(data) : '')"
                  >
                    <!-- <h4>{{ data.name }}</h4>
                    <h5>{{ data.job }}</h5>
                    <div class="logo" :style="'background-image:url('+data.logo_parsed+')'"></div>
                    <p class="p_desc">{{ data.description }}</p> -->
                  </div>
                </div>
              </v-img>
              <v-card-text class="wrap" style="position: relative;">
                <div class="dets">
                  <div id="line_lec"></div>
                  <div class="h7">{{ data.name }}</div>
                  <h3>{{ data.job }}</h3>
                </div>
              </v-card-text>
            </v-card>
          </v-hover>
            <div class="logo" :style="'background-image:url(' + data.logo_parsed + ')'"></div>
        </slide>
      </carousel>
      
    </div>
    
         <v-dialog v-model="dialog" width="600" content-class="lect_vid" :key="ref">
            <v-card>
              <div class="header_pop">
                <div class="close" @click="dialog = false">X</div>
                <Video :video_url="video" :pause="dialog" />
              </div>
            </v-card>
          </v-dialog>

        <div class="wrapper_btn">
          <!-- <button class="btn"><a @click="$emit('courses',compingCourses)">see upcoming courses</a></button> -->
        </div>
    </div>

</template>

<script>
import Video from "@/components/lect_video.vue";
export default {
  components: { Video },
  props: ["lecturers"],
  data() {
    return {
      more: true,
      dialog: false,
      video: '',
      ref:0,
    };
  },
  methods: {
    open_video(data)
    {
      this.ref++
      this.video = data.video;
      this.dialog = true;
    },
    dialog_play() {
      this.$refs[`plyr`].stop();
    },
    close() {
      this.$refs[`plyr`].fullscreen.exit();
    },
  },
};
</script>


<style lang="scss" scoped>
.header_pop {
  & {
    background: #363c4a;
    height: 0;
  }
  video {
    margin: 0 auto;
    display: block;
    height: 350px;
    margin-top: -20px;
    z-index: 9;
  }
  .close {
    position: absolute;
    right: 20px;
    font-size: 22px;
    cursor: pointer;
    color: #fff;
    z-index: 10;
    top: 10px;
  }
}
#lecturers_home{padding:50px 0px; background:#f6f9fd;
    h2{text-align: center; color:#4e5074; padding-bottom:35px; font-family: 'Poppins', sans-serif; font-size: 36px;}
    .wrapper_btn{margin: 0 auto; text-align: center;
        button{color:#fff; border-radius: 4px; text-transform: uppercase; font-weight: 600; padding:5px 20px; line-height: 25px; font-size: 14px; background:#1480dc; margin-top:50px;
          a{color:#fff; text-decoration: none;}
        }
    }

    .v-card{border-radius: 15px; width: 90%; padding-top: 12px;background:#ffffff!important; min-height: 360px;}
    .logo{background-position: center;background-size: contain;height: 40px;  width: 100%; margin-top: 10px; background-position: center; margin-top:20px}
    .pic{background-size: cover; height: 260px!important; background-position: center; width:100%; margin:0 auto; border-radius: 0px; cursor: pointer;}
    .content_card{display:block; height: 500px;}
    .fa-play-circle{position: absolute; color: #fff; bottom: 15px;left: 15px; font-size: 30px; cursor: pointer;}
    .dets{
        .h7{text-align: center; font-weight: 700;font-size: 20px; color:#4e5074}
        h3{text-align: center; font-weight: 500;font-size: 14px; color:#a6a7b9;line-height: 22px;}
    }
}
@media(max-width:600px){
  .it{width:100%}
  .VueCarousel-inner{flex-basis: 400px;}
}
@media(max-width:414px){
  #lecturers_home{padding:30px 0px}
  #lecturers_home h2{font-size: 25px;}
  .it{width:100%}
  .VueCarousel-inner{flex-basis: 230px;}
  #lecturers_home .pic{height: 190px!important;}
  #lecturers_home .v-card{min-height: 330px;}
}
</style>