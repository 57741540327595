<template>
    <div id="login">
        <div class="centered">
            <div class="popup">
                <div class="form half">
                    <div id="logo"></div>
                    <h3 v-if="!forgot_pass">Log in to the</h3>
                    <h1 v-if="!forgot_pass">Urban real estate center</h1>
                    <h1 v-if="forgot_pass">Forgot your password?</h1>
                    <form v-if="!forgot_pass" @submit.prevent="login" class="main_form">
                         <input
                            v-model="email"
                            label="Your Email Adress"
                            background-color="#353d4a"
                            color="#fff"
                            placeholder="Your Email Address"
                        >
                        <i class="fas fa-envelope"></i>
                         <input
                            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show3 ? 'text' : 'password'"
                            name="input-10-2"
                            placeholder="Password"
                            hint="At least 8 characters"
                            v-model="password"
                            background-color="#353d4a"
                            color="#fff"
                            class="input-group--focused "
                            @click:append="show3 = !show3"
                        >
                        <span :key="forgot_pass" v-if="!forgot_pass">
                        <i class="fas fa-lock" ></i>
                        </span>
                         <!-- <v-text-field
                            v-model="email"
                            label="Your Email Adress"
                            background-color="#353d4a"
                            color="#fff"
                            ></v-text-field> -->
                             <!-- <v-text-field
                            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show3 ? 'text' : 'password'"
                            name="input-10-2"
                            label="Password"
                            hint="At least 8 characters"
                            v-model="password"
                            background-color="#353d4a"
                            color="#fff"
                            class="input-group--focused "
                            @click:append="show3 = !show3"
                        ></v-text-field> -->
                          <!-- <v-checkbox
                                v-model="ex4"
                                label="indigo"
                                color="white"
                                value="indigo"
                                hide-details
                                ></v-checkbox> -->
                           <input type="submit" class="btn" value="SIGN IN >>" />
                    </form>
                     <form v-if="forgot_pass" @submit.prevent="reset_password" class="main_form">
                          <input
                            v-model="email"
                            label="Your Email Adress"
                            background-color="#353d4a"
                            color="#fff"
                            placeholder="Your Email Address"
                        >
                        <i class="fas fa-envelope"></i>
                           <input type="submit" class="btn" value="SEND RESET LINK >>" />
                    </form>
                    <div class="bottom">
                        <input type="checkbox"
                            v-model="ex4"
                            label="indigo"
                            color="white"
                            value="indigo"
                            hide-details
                        >
                        <p class="remember_me">Remember me</p>
                        <p class="forg_password" v-if="!forgot_pass" @click="forgot_pass = true">Forgot your passord?</p>
                    </div>
                     <a href="/pricing" v-if="!forgot_pass">Create an account</a>
                </div>
                 <div class="pic half" v-if="!is_mobile()"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return{
                email:'',
                forgot_pass:false,
                show3:false,
                password:'',
                ex4:true
        }
    },
    methods:{
         login()
    {
      var self = this;
      self.is_loading = true;


    const formData = { 
    password: this.password,
    username: this.email 
    };

    this.net_api({ 'action': 'login', data: formData}, function(data){
       
        if (self.isNull(data) || self.isNull(data.data))
        {
            self.is_loading = false;
            self.show_error = true;
            self.alert("We didn't find your account, please contact us at: support@urecenter.com ");
        }
        else
        {
            localStorage.setItem('user_token', data.data);
            document.location ='/course_index/158';

            //alert(data.jwt)
            //captainnemo
        }
        
    });

    },
    reset_password(){
        if(!this.isNull(this.email))
        {
            var self = this
        this.net_api({ 'action': 'reset_password', data: {username:this.email}}, function(data){
            if(data.data){
                     self.alert("The password sent to your email!");

            }
            else{
                self.alert("We didn't find your account, please contact us at: support@urecenter.com ");

            }

            });
        }
           
    }
    }

}
</script>
<style lang="scss" scoped>
a{color:#698ffb !important ;}
#login{background: #353d4a;height: 100vh;overflow: hidden;padding-top: 100px;}
    #logo{width: 162px;height: 55px;background-size: cover;background-image: url(../assets/logo.png);top: 10px;left: 50px;margin: 0 auto 20px;}
.btn{background: #30abb7;margin-top: 10px;color: #fff;font-weight: 700;}
p{margin-top: 10px;font-size: 16px;text-decoration: underline;color: #698ffb;cursor: pointer;}
.popup{
    .v-label{color: #fff !important;}
    .pic{background-size: cover;border-radius: 0 12px 12px 0;background-repeat: no-repeat;background-position: center;background-image: url(../assets/log-in.jpg);height: 500px;}
    &{display: flex;flex-wrap: wrap;background: #1e2229;border-radius: 12px;}
    .half{flex-basis: 50%;}
    .form{ 
        &{padding: 20px;text-align: center;color: #fff;}
        h3{    font-weight: 600; font-size: 20px; line-height:40px}
        h1{    font-size: 25px;text-transform: uppercase;line-height: 30px;}
        form{    width: 65%;margin: 0 auto;}
    }

    .main_form{display: flex; flex-direction: column; align-items: center; position: relative;
        input{background: #2b313a; color:#ccc; border: 1px solid #ccc; border-radius: 4px; height: 35px; width: 300px; margin-bottom: 10px; margin-top: 20px;padding-left: 35px;} 
        input::placeholder{color: #fff;}
        .fa-envelope{position: absolute; top: 28px; left: -30px; font-size: 20px; color: #30abb7;}
        .fa-lock{position: absolute; top: 92px; left: -30px; font-size: 20px; color: #30abb7;}
        .btn{background: #30abb7;margin-top: 20px; border: none; border-radius: 10px; outline: none;color: #fff !important;}
    }
    .bottom{display: flex; flex-direction: row; align-items: baseline; justify-content: center;
        .remember_me{color: #fff; text-decoration: none;}
        .forg_password{margin-left:20px;}
    }
}
 @media only screen and (max-width: 700px) {
    .half{flex-basis: 100% !important;height: 100vh;} 
    #login{padding-top: 0px;}
     form{    width: 95% !important;margin: 0 auto;}
    .pic{order: 1;height: 300px !important;border-radius: 0 !important }
    .main_form .fa-envelope{left: 38px !important;}
    .main_form .fa-lock{left: 38px !important;}
 }
</style>