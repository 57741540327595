<template>
    <div id="header">
              <a id="logo" href="https://urecenter.com/course_index/158"></a>
            <div class="menu">
                <ul>
                    <li v-for="item in menu" :key="item.id" @click="$emit('scroll', item.link)">{{item.name}}</li> 
                   </ul>
            </div>
            <!-- <div id="login">Login</div> -->
        </div>
</template>
<script>
export default {
  props:["menu"],
     data () {
      return {
        type:'selector',
          

      }},
      computed: {
      

      
}
}
</script>
<style lang="scss" scoped>
// #login{background-color: #30abb7;color: #fff;font-size: 30px;padding: 0px 20px;position: absolute;width: fit-content;right: 0;top: 20px;font-weight: 600;border-radius: 4px 0 0 4px;}
    #header{background: #353d4a;color: #fff;font-family: sans-serif;position: fixed;top: 0;width: 100%;;height: 90px;z-index: 9999;}
    #logo{position: absolute;width: 162px;height: 55px;background-size: cover;background-image: url(../../assets/logo.png);top: 20px;left: 50px;}
.menu{width: 100%; margin-left: 250px;}
ul{padding-top: 30px;}
li{display: inline-block; font-size: 17px; font-weight: 700; padding: 0 30px;cursor: pointer;color: #fff ;}
li a{color: #fff !important;}
</style>