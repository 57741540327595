<template>
<div v-if="loaded">
  <MobileCourse :info="info" :writers="writers" :cats="cats"  v-if="is_mobile()"/>
     <div id="courses_page" v-if="!is_mobile()" >
                    <Header :menu="menu" @scroll="scroll($event)"></Header>
        <div id="hero" :style="'background-image:url('+info.pic_parsed+')'">
            <p v-html="info.course_subject"> </p>
            <h3 v-text="info.name"></h3>
            <p v-text="info.short_text"></p>
        </div>
        <div id="about_course">
            <div  class="centered_courses"> 
                          <h3 v-html="info.what_learn"></h3>
            <p v-html="info.about"></p>
            <div class="col-md-3">
                <div class="circle"><div class="icon" :style="(info.icon_a > 0 ? 'background-image:url('+info.icon_a_parsed+')' : '')"></div></div>
                <div class="details">
                    <span v-text="info.title_a">תעודה הסמכה</span>
                    <label v-text="info.pros_a"></label>
                </div>
            </div>
            <div class="col-md-3">
                <div class="circle"><div class="icon" :style="(info.icon_b > 0 ? 'background-image:url('+info.icon_b_parsed+')' : '')"></div></div>
                <div class="details">
                    <span v-text="info.title_b">תעודה הסמכה</span>
                    <label v-text="info.pros_b"></label>
                </div>
            </div>
            <div class="col-md-3">
                <div class="circle"><div class="icon" :style="(info.icon_c > 0 ? 'background-image:url('+info.icon_c_parsed+')' : '')"></div></div>
                <div class="details">
                    <span v-text="info.title_c">תעודה הסמכה</span>
                    <label v-text="info.pros_c"> </label>
                </div>
            </div>
            <div class="col-md-3">
                <div class="circle"><div class="icon" :style="(info.icon_d > 0 ? 'background-image:url('+info.icon_d_parsed+')' : '')"></div></div>
                <div class="details">
                    <span v-text="info.title_d">תעודה הסמכה</span>
                    <label v-text="info.pros_d"></label>
                </div>
            </div>
            <div class="clr"></div>
        </div>
        </div>
    <div class="centered_courses">
        <Lecturers :writers="writers" :title="info.lec_title"/>
        <CourseSection  v-for="cat in cats" :key="cat.id" :courses="cat.items" :cat="cat"/>

    </div>
    </div>
    </div>


    
</template>

<script>
import CourseSection from './course_section.vue';
import Lecturers from './Lecturers.vue';
import MobileCourse from './MobileCourse.vue';
import Header from './course_header.vue';



export default { 
   props: ["user_data"],
    components: {  CourseSection ,Lecturers,Header,MobileCourse},

    data(){
        return {
                      menu:[
                {id:1,name:"Main Course", link:'hero'},
                // {id:2,name:"About", link:"about_course"},
                {id:3,name:"Speakers", link:"lecturers_area"},
            ],
          showDialog:false,
            id: this.$route.params.id,
            profile_data: {},
            experties: [],
            loaded: false,
            lead_name:"",
            lead_phone:"",
            scripts: [],
            users: [],
            cats: [],
            writers: [],
            courses: [],
            info: []
        }
    },
  methods: {
     scroll(value) {  
      var elmnt = document.getElementById(value);
        elmnt.scrollIntoView({behavior:'smooth'});
        },
        scripts_ins(){    
            for(let i in this.scripts)
            {
            let Script = document.createElement('script')
                Script.setAttribute('src', this.scripts[i].script)
                document.head.appendChild(Script)
            }
        },
    init_general(data)
    {
      var self = this;
        
        self.user_data = data.user;
        self.$store.user = data.user;
           if (!(self.user_data.id > 0))
        {
         document.location = '/login'
        }


        self.$pnSubscribe({ channels: ['user_cnl_' + self.user_data.id] });
        self.$pnGetMessage('user_cnl_' + self.user_data.id, self.parse_user_channel);

        self.generalLoaded = true;
      }
  },
  mounted(){

      var self = this;
       this.net_api({ action: 'get_user' }, function(data) { self.init_general(data.data); } );
         this.net_api({ action: 'course/get', data: { id: this.id} }, function(data){ 
        
        self.info = data.data;
         for (let i in data.data.series)
                {
                        data.data.series[i].show_more = false;
                }
        self.cats = data.data.series;
        self.writers = data.data.writers;
        self.scripts = data.data.scripts;
        self.loaded = true;
setTimeout(() =>  self.scripts_ins(),2200)
     

 } );

    
 

 
      // categories 
    

         
  }
    } 
   
</script>
<style lang="scss" scoped>
#courses_page{background: #fff;}
.cat:nth-child(even){
    .col-md-6:nth-child(1){padding-right: 60px;}
    .col-md-6{float: left;}
    .course_pic::after{right: unset !important;left: -30px;}
}
#hero{  
     &{height: 50vh;text-align: center;color: #fff;padding-top: 120px;;background-position: center;background-size: cover;background-repeat: no-repeat;width: 100%;}
     p{font-size: 30px;margin-bottom: 10px;}
     h3{    font-size: 45px; width: 100% ;line-height: 45px; margin: 0 auto; margin-bottom: 10px;}
      }
      #about_course{
          .details{display: inline-block;width: 70%;vertical-align: middle;margin-left: 5px;text-align: left;}
          .details span{display: block;    padding-right: 10px;font-weight: 600;}
          .details label{    font-size: 15px;padding-right: 10px;;}
          .circle{padding: 10px;border: 1px solid;border-radius: 100%;height: 55px;width: 55px;display: inline-block;vertical-align: middle;}
          .icon{background-image: url(../../assets/diploma.png);height: 100%;width: 100%;background-size: contain;background-repeat: no-repeat;}
          &{text-align: center;padding-top: 50px;background: #fff;padding-bottom: 50px;}
          h3{font-size: 30px;margin-bottom: 30px;}
          p{    width: 70%;margin: 0 auto;font-size: 20px;margin-bottom: 30px;}
      }
//#app_left_side{display: none;}
//#app_right_side{display: none;}
.centered_courses{width: 100%;max-width: 1250px;position: relative;margin: 0 auto;}
@media only screen and (max-width: 600px) {
    #hero{padding-top: 40px;}
    #hero h3{width: 100%;}
    #hero p{width: 90%;margin: 0 auto;;}
    #about_course{
        .col-md-3{margin-bottom: 10px;}
    }
}
</style>