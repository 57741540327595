<template>
    <div id="logos_home">
        <div class="">
            <div class="wrapper">
                <h3>Our Students work at</h3>
            </div>
            <div id="logos_wrapper">
                <div class="logos"  v-for="item in $store.logos_comps" :key="item.id">
                    <div class="wrapper_logos">
                        <div class="logo" :style="'background-image:url('+item.pic_parsed+')'"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clr"></div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
#logos_wrapper{display: grid; grid-template-columns: repeat(10, 1fr);gap: 10px;grid-auto-rows: 1fr;}
.VueCarousel {
 width: 100%;
}
#logos_home{padding:40px 0px; 
    .wrapper{margin-bottom: 10px;;
        h3{text-transform: uppercase; color:#4e5074; font-weight: 800;text-align: center;font-size: 25px;margin-bottom: 20px;}
    }
    .logos{
        .wrapper_logos{
            .logo{background-size: contain; background-position: center; background-repeat: no-repeat; width:100%; height: 40px;}
        }
    }
}
@media(max-width:600px){
    #logos_wrapper{display: grid; grid-template-columns: repeat(3, 1fr);gap: 2;grid-auto-rows: 1fr;}
  #logos_home{padding:20px 0px;
    .wrapper{align-content: center; justify-content: center; align-items: center;flex-direction: column;
        h3{width:100%; text-align: center; height: 70px;}
    }
  }
}
@media(max-width:414px){
  #logos_home{padding-bottom:0px;}
  #logos_home .wrapper{flex-direction: column; align-items: center; align-content: center;}
  #logos_home .wrapper h3{text-align:center; width:100%; height: 0px; padding-bottom:20px}
  #logos_home .logos .wrapper_logos{display: flex;justify-content: center; }
}
</style>