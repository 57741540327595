<template v-if="loaded">
    <div id="who_for">
        <div class="centered_l">
            <div class="title">Who Are The Courses For?</div>
            <div class="line_w"></div>
            <div class="col-md-3 col-sm-3 col-xs-12" v-for="a in icons" :key="a.id">
                <div class="pic" :style="'background-image:url('+ a.pic_parsed+')'"></div>
                <h4>{{a.name}}</h4>
                <p class="p_desc">{{a.description}}</p>   
            </div>
            <div class="clr"></div>
        </div>
    </div>
</template>
<script>



  export default {
        props:["icons"],
    data () {
      return {
        loaded:false,
      }
    },
  }
</script>
<style lang="scss" scoped>
#who_for{
    &{background: #36393d;padding: 50px 0 35px 0px; min-height: 330px; text-align: center;color: #fff; background-image:url(../assets/who_for.png); background-position: center; background-size: cover; background-repeat: no-repeat; background-blend-mode: overlay;}
    .icon{font-size: 60px;}
    .title{font-weight: 600; font-family: "Poppins", sans-serif !important;; font-size: 35px !important; margin-bottom: 15px!important;}
    .line_w{height: 4px; width:60px; background:#1480dc; margin: 0 auto; border-radius: 50px; margin-bottom: 45px;}
    h4{font-size: 22px;margin-bottom: 5px;}
    p{font-size: 14px;margin-bottom: 26px;}
    .pic{height: 70px;margin: 0 auto;background-size: contain;background-position: center;
    margin-bottom: 25px;}
    .p_desc{font-style: italic;}
}
@media only screen and (max-width: 900px){
  .p_desc{line-height: 25px!important;}
} 
@media only screen and (max-width: 414px){
  #who_for .title{font-size: 25px!important;}
  #who_for .line_w{margin-bottom: 20px;}
  #who_for p{margin-bottom:10px}
} 
@media only screen and (max-width: 360px){
  #who_for .title{font-size: 25px!important;}
  #who_for h4{font-size: 20px!important;}
} 
</style>