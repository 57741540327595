<template v-if="loaded">
    <div id="estate_info">
        <div class="centered_h">
            <div class="title">Real estate school reinvented</div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 item_wrap"  v-for="item in $store.estate_school" :key="item.id">
              <div class="no_hover">
                <div class="icon icon_fir" :style="'background-image:url('+item.pic_parsed+')'"></div>
              </div>
              <div class="hover">
                <div class="icon icon_fir_hover" :style="'background-image:url('+item.hover_parsed+')'" ></div>
              </div>
                <h4>{{item.title}}</h4>
                <p class="p_desc"> {{item.text}}</p> 
            </div>
            <div class="clr"></div>
        </div>
    </div>
</template>
<script>
  export default {
    data () {
      return {
        loaded:false,
      }
    },
  }
</script>
<style lang="scss" scoped>
#estate_info{background:#f6f9fd; padding:50px 0px;
    .title{text-transform: capitalize; color:#4e5074; font-weight: 700; font-size: 20px; padding-bottom:20px}
    .icon{background-size: contain; background-position: center; background-repeat: no-repeat; width:70px; height: 70px; margin-bottom:20px;}
    .item_wrap:hover .no_hover{display:none}
    // .hover{display:none}
    .hover{display:none}
    .item_wrap:hover .hover{display:block !important}
    
    h4{color:#4e5074; padding-bottom:6px; font-size: 21px;}
    .p_desc{font-size: 16px; color:#4e5074; line-height: 25px; font-weight: 300; width: 90%;}
} 
@media(max-width:600px){
  #estate_info .icon{width:100%;}
  #estate_info h4{text-align: center;}
  #estate_info p{text-align: center;}
  #estate_info .p_desc{width:100%}
}
@media(max-width:414px){
  #estate_info{padding:30px 0;}
  #estate_info .title{padding-bottom:0px}
  #estate_info .icon{width:100%;}
  #estate_info h4{text-align: center;}
  #estate_info p{text-align: center;}
}
</style>