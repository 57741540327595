<template v-if="loaded">
  <div id="about">
      <div class="centered_about">
        <h3>Gain first-hand knowledge</h3>
        <h2>About The Fast-Paced World Of Real Estate And Get Inspired To Take Action</h2>
        <div class="b_col col-md-12">
            <div class="wrapper col-md-6" style="padding-left:40px;">
                <div class="wrapper_l">
                    <div v-for="(item,i) in mudule" :key="i" class="wrapper_p">
                         <p class="p_about" v-if="i < 10"><span>{{item.title}}:</span> <strong>{{item.presentor}}</strong></p>
                    </div>
                </div>
            </div>
            <div class="wrapper col-md-6">
                <div class="wrapper_r">
                    <div v-for="(item,i) in mudule" :key="i" class="wrapper_p">
                         <p class="p_about" v-if="i >= 10"><span>{{item.title}}:</span> <strong>{{item.presentor}}</strong></p>
                           
                    </div>
                </div>
            </div>
        </div>
        <div class="clr"></div>

        <div class="wrpper_btn">
            <button class="btn" @click="$emit('scrollTo','contact2')">download full curriculum</button>
        </div>
        
      </div>
  </div>
</template>

<script>
export default {
    props:["mudule"],
  data(){
    return{
      loaded: false,
    }
  }

}
</script>

<style lang="scss" scoped>
    #about{background:#f6f9fd; padding-top: 50px;
        // .wrapper_p{border-bottom:1px solid #e5e5e5}
        h3{color: #4e5074; text-align: center; font-weight: 600; margin-bottom:5px; font-size: 20px;font-family: "Nunito", sans-serif;}
        h2{color: #4e5074; text-align: center; width: 60%; margin: 0 auto; line-height: 1.3; font-size: 35px;}
        .b_col{width: 100%; min-height: 350px; background:#fff; border-radius: 15px; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; margin-top: 35px; margin-bottom:30px;}
        p.p_about{margin-bottom:0px!important; padding: 12px; color:#4e5074; font-size: 16px; line-height:5px; font-weight: 600; border-bottom: 1px solid #e5e5e5; width: 95%;}
        span{font-weight: 300;}
        // .line_a{height: 1px; background: #e5e5e5; width: 95%;}
        .wrpper_btn{margin: 0 auto; text-align: center; padding-bottom: 10px}
        button{text-transform: uppercase; background:#1480dc; padding:15px 20px; color:#fff; font-size: 15px; font-weight: 600; border-radius: 4px; line-height: 8px; height: 40px;}
        button:hover{background:#34465c}
    
        p.p_about strong{line-height: 20px;}

        .wrapper .wrapper_l .wrapper_p:nth-child(10) .p_about{border-bottom:none;}
        .wrapper .wrapper_r .wrapper_p:nth-child(20) .p_about{border-bottom:none;}

    }
    @media only screen and (max-width: 1200px){
        #about p.p_about{line-height: 25px;}
    @media only screen and (max-width: 900px){
       #about h2{width:85%}
       #about .b_col{text-align: center;}
       .wrapper_l .p_about:last-child {border-bottom:1px solid #e5e5e5; width:95%; padding-right:0px;}
       .col-md-6{padding-top:0px}
    } 
    @media only screen and (max-width: 414px){
        .wrapper {padding-left:15px!important}
        .b_col{padding: 12px}
       #about h2{font-size: 25px;}
       #about p.p_about{text-align: left;}

       #about .wrapper .wrapper_l .wrapper_p:nth-child(11) .p_about{border-bottom: 1px solid #e5e5e5;}
    } 
    @media only screen and (max-width: 360px){
       #about h2{font-size: 25px;}
    } 
}
</style>