<template v-if="loaded">
  <div id="app" :class="show_side_menu ? 'menu_open' : ''">
    <div id="header_mobile" v-if="is_mobile()">
      <a class="logo" href="/"></a>
      <div id="menu_hamburger" v-on:click="show_side_menu = !show_side_menu">
        <div id="nav-icon3">
          <span></span><span></span><span></span><span></span>
        </div>
      </div>
      <a id="mobile_logo" href="/"></a>
    </div>
    <div id="side_menu" v-if="is_mobile()">
      <div class="logo_open"></div>
      <ul class="sharing_list">
        <li
          class="menu"
          v-for="item in menu"
          :key="item.id"
          @click="
            scroll(item.selector);
            show_side_menu = false;
          "
        >
          <a v-text="item.name"></a>
        </li>
        <!-- <li><a href="/login">Log in</a></li> -->
        <li><a @click="$emit('pop_up_contact')">Signup</a></li>
      </ul>
    </div>
    <div class="home" v-if="loaded">
      <!-- <Stripe v-if="!signupDialog"/> -->
         <Header
        @scrollTo="scroll($event)"
        :menu="menu"
        v-if="!is_mobile()"
        @sign="$emit('pop_up_contact')"
        
      />
      <!-- <NewHero @sign="$emit('pop_up_contact_lp')"/> -->
   

    <Hero
        :check_hero="check_hero"
        :hero_title="hero_title"
        :video="$store.words.lp1_video"
        :hero_description="hero_description"
        :title_1="$store.words.up_title_hero"
        :title_2="$store.words.hero_title"
        :title_3="$store.words.hero_description"
       @sign="$emit('pop_up_contact')"
        :lp="true"
      />
      <!-- <Contact /> -->
      <WroteOnUs :logos="logos" />
        

      <div class="lecturers" id="lecturers">
        <div class="centered-big">
          <h6 v-html="$store.words.lecturers_subtitle">
            The most successful entrepreneurs in the business
          </h6>
          <div class="title" v-html="$store.words.lecturers_title">
            Showing you how to do what they do
          </div>
          <div v-if="!show_more">
            <Lecturer
              class="col-lg-15 col-md-15 col-sm-15 col-xs-6"
              v-for="item in lecturers.slice(0, 15)"
              :key="item.id"
              :data="item"
            />
          </div>
          <div v-else>
            <Lecturer
              class="col-md-15"
              v-for="item in lecturers"
              :key="item.id"
              :data="item"
            />
          </div>
          <div class="clr"></div>
          <!-- <div class="show_more" v-if="!show_more" @click="show_more =!show_more">Show more</div> -->
        </div>
      </div>
      <Contact :lp="1"/>
      <Join :join="join" :title_1="$store.words.join_title" :title_2="$store.words.join_text"/>
      <Event :event="event" :time="$store.words.event_time_info" :date="$store.words.event_date_info" :location="$store.words.event_location_info" :length="$store.words.event_meeting_info"/>
      <lecturerswidget :logos="logos_widget" :items="items" />
      <Reviews :reviews="reviews" />
      <Estate @scrollTo="scroll('contact')" :title="$store.words.estate_title" :text="$store.words.estate_title_text"/>
      <About :mudule="reasons" @scrollTo="scroll('contact')" />
      <!-- <List :resons="reasons"/> -->
      <div id="contact2" style="padding-top:40px; background: #f6f9fd;">
        <Contact :lp="1"/>
      </div>
      <!-- <House @signup="scroll('wroteOnUs')"/>  -->
      <!-- <Jumpfooter @Signup="scroll('wroteOnUs')" v-if="!is_mobile()"/> -->
      <!-- <Mobile @signup="scroll('wroteOnUs')" :price="false"/> -->
      <Whofor :icons="icons" />
      <!-- <Contact/> -->

      <!-- <Courses :courses="courses"/> -->
      <questions :questions="questions" />
    
      <!-- <WheAreWe :text="about_us_text"/> -->
      <Footer
        v-if="is_mobile()"
        @scroll_top="scroll('hero')"
        @open_pop="$emit('pop_up_contact')"
      />
      <FooterHome/>
      <CountDown :timer="timer" v-if="timer_active" @signup="scroll('contact')" />
      <!-- <FooterDesktop v-if="!is_mobile()" /> -->
    </div>

      <v-snackbar v-model="snack_active" v-if="cur_pur">
      <div
        id="snack_pic"
        :style="'background-image:url(' + cur_pur.image + ')'"
      ></div>
      <div id="snack_name">{{ cur_pur.name }}</div>
      <div id="snack_time">{{ cur_pur.time }}</div>
    </v-snackbar>

  </div>
</template>

<script>
// @ is an alias to /src
// import Stripe from '../components/stripe.vue';
// import Jumpfooter from '../components/jump_footer.vue';
import FooterHome from "../components/main-home/footerHome.vue";
// import FooterDesktop from '../components/FooterDesktop.vue';
import lecturerswidget from "../components/lecturers_widget.vue";
import Footer from "../components/Footer.vue";
import Contact from "../components/Contact.vue";
import Header from "../components/header.vue";
import Hero from "../components/hero_video_iframe.vue";
// import NewHero from "../components/NewHero.vue";
import Lecturer from "../components/lecturer.vue";
import WroteOnUs from "../components/WroteOnUs.vue";
import About from "../components/about.vue";

import Whofor from "../components/who_for.vue";
import Reviews from "../components/Reviews.vue";
import questions from "../components/questions.vue";
import Estate from "../components/estate.vue";
import Join from "../components/join.vue";
import Event from "../components/event.vue";
import CountDown from "../components/countDown.vue";

export default {
  name: "Home",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    pay_link: "",
    title: "Real Estate Decvelopment Master Class ",
    // all titles will be injected into this template
    titleTemplate: "Real Estate Development Master Class",
    meta: [
      { name: "description", content: "Real Estate Development Master Class " },
      {
        property: "og:title",
        content: "Real Estate Development Master Class ",
      },
      { property: "og:site_name", content: "urecenter" },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://urecenter.com/img/logo.6874f727.png",
      },
      { name: "robots", content: "index,follow" },
    ],
  },
  components: {
    // NewHero,
    lecturerswidget,
    Hero,
    Lecturer,
    WroteOnUs,
    Reviews,
    questions,
    Whofor,
    Header,
    Footer,
    Contact,
    FooterHome,
    Estate,
    Join,
    Event,
    About,
    CountDown,
  },
  props: ["menu"],

  data() {
    return {
      timer: '',
      timer_active: '',
      signupDialog: false,
      courses: [],
      questions: [],
      reviews: [],
      join: [],
      icons: [],
      logos: [],
      show_more: false,
      show_side_menu: false,
      loaded: false,
      reasons: [],
      lecturers: [],
      thanks: [],
      event: [],
      logos_widget: [],
      types: [],
      items: [],
      // timer: [],
      cur_pur:{},
      purchase: [],
      snack_active: false,
      snack: false,
    };
  },
  mounted() {
    // localStorage.clear('is_snackbar')
    //  alert(localStorage.getItem('is_snackbar'))
    localStorage.setItem("link", window.location.href);
    if (this.$route.query) {
      localStorage.setItem("utm", JSON.stringify(this.$route.query));
    }

    var self = this;
    this.net_api({ action: "general" }, function(data) {
      self.join = data.data.join;
      self.event = data.data.event;
      self.purchase = data.data.purchase;
      self.courses = data.data.courses;
      self.questions = data.data.questions;
      self.reviews = data.data.reviews;
      self.icons = data.data.icons;
      self.logos = data.data.logos;
      self.reasons = data.data.reasons;
      self.lecturers = data.data.lecturers;
      self.timer_active = data.data.words.timer_active;
      self.timer = data.data.words.timer_new;
      self.about_us_text = data.data.words.words_about_us;
      self.hero_title = data.data.words.words_hero_title;
      self.hero_description = data.data.words.words_hero_description;
      self.check_hero = data.data.words.check_hero;
      
        self.logos_widget = data.data.logos_widget;
        self.items = data.data.items;
      self.loaded = true;

    });
      var counter = 0;
      setInterval(function() {
      self.pop_up(counter);
      counter++;
    }, 5000);
  },
  methods: {
    signup_popup() {
      this.signupDialog = true;
    },
    pop_up(id) {
      if (id < this.purchase.length) {
        setTimeout(() => {
          this.snack_active = true;
          this.cur_pur = this.purchase[id];
        }, 1000);
      }
    },
    scroll(value) {
      var elmnt = document.getElementById(value);
      elmnt.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
<style lang="scss" scoped>

#snack_name {
  color: #4d4b70;
  padding-left: 20px;
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  line-height: 20px;
}
#snack_time {
  color: #c7c6cb;
  font-weight: 400;
  font-size: 13px;
  position: absolute;
  left: 48px;
  bottom: 14px;
  padding-left: 36px;
  line-height: 10px;
  padding-bottom: 5px;
}
#snack_pic {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 70px;
  height: 80px;
  border-radius: 20px!important;
}
.lecturers {
  background: #f6f9fd;
  padding: 40px 0 60px 0;
  .title{margin-top:0px!important;}
}

// @media(max-width:978px) and (min-width:750px){
//     .col-md-15:nth-child(2n + 1) {
//     clear: both;
//   }
// }
.lecturers .title {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  font-weight: 700 !important;
  margin-top: 13px;
  color: #4e5074;
}
.lecturers h6 {
  text-align: center;
  font-size: 35px !important;
  font-weight: 600 !important;
  color: #4e5074;
  line-height: 1.4;
  text-transform:capitalize!important;}
// .it:nth-child(4n+3){clear: both;}
.home {
  padding-top: 80px;
  max-width: 100%;
  overflow: hidden;
}
.v-application .grey.lighten-4 {
  background-color: #eeeeee !important;
}
.v-card[data-v-7dd1f4c4] {
  min-height: 450px;
}
.v-application p {
  line-height: 20px;
  margin-bottom: 50px !important;
}
#header_mobile {
  position: fixed;
  height: 50px;
  top: 0;
  background: #fff;
  left: 0;
  width: 100%;
  z-index: 99999;
}
.show_more {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  background: #1cd2ad;
  width: 230px;
  height: 40px;
  margin: 0 auto;
  border-radius: 4px;
  color: #fff;
  line-height: 42px;
  text-transform: uppercase;
  margin-top: 10px;
}
.show_more:hover {
  background: #13505b !important;
}

li[data-v-29e8c3c6]:hover {
  color: #13505b;
}

@media only screen and (max-width: 900px) {
  
  .v-application p {
    line-height: 40px !important;
  }
}
@media only screen and (max-width: 414px) {
  .lecturers {
    padding: 40px 0 0px 0;
  }
  #lecturers {
 
  }
  .menu_open #side_menu{z-index: 99;}
  .contact_pop{z-index: 9999999;}
}
@media only screen and (max-width: 600px) {
 
  .v-application p {
    line-height: 30px !important;
    margin-bottom: 10px !important;
  }
  .lecturers h6 {
    font-size: 18px !important;
  }
  .v-application .title {
    font-size: 25px !important;
  }
  .left {
    display: none;
  }
  .right {
    display: none;
  }
  #lecturers {
  
  }
}
</style>

