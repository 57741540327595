<template>
    <div id="new_hero">
        <div class="centered1">
            <div class="first_title" v-html="hero_title_1"></div>
            <div class="title" v-html="hero_title_2"></div>
            <p v-html="hero_title_3"></p>
            <a @click="$emit('sign')" class="brochure_btn btn" v-html="(btn && btn != '' ? btn : $store.words.new_hero_btn)">Get Free Brochure</a>
             <div id="marks">
                    <h4 class="mrk" v-html="$store.words.check_hero_one"></h4>
                    <h4 class="mrk" v-html="$store.words.check_hero_two"></h4>
                    <h4 class="mrk" v-html="$store.words.check_hero_three"></h4>
                </div>  
        </div>
    </div>
</template>
<script>
export default {
    props:["hero_title_3","hero_title_2","hero_title_1","btn"]
}
</script>
<style lang="scss" scoped>
.first_title{font-weight: 600;font-size: 27px;color: #ffffff;font-family: "Nunito", sans-serif !important;}
#new_hero{ padding: 130px 0 50px 0!important;text-align: center;background-image: url(../assets/newhero.webp);background-size: cover;
background-position: center;min-height: 60vh;color: #fff;
    #marks{font-family: "Nunito", sans-serif;
        &{padding-left: 22px; display: flex;justify-content: center;     gap: 40px;}
        .mrk{letter-spacing: 0.3px;font-size: 20px;font-weight: 400;position: relative;margin-bottom: 14px; color:#fff; padding-left: 35px;line-height: 1.1;}
        .mrk::before{content: '';position: absolute;left:0;height: 25px;width: 25px; -webkit-mask-size: contain;-webkit-mask-image: url(../assets/v_icon.png); background:#fff;  -webkit-mask-repeat: no-repeat;}
    }
    .brochure_btn{ color: #fff;background: #1480dc; padding: 20px 40px; border-radius: 4px;line-height: 0px;text-transform: uppercase;font-size: 16px;font-weight: 700; margin: 50px auto 60px; text-decoration: none;}
p{width: 50%; margin: 0 auto;}
}

@media only screen and (max-width: 1450px) {
    
    #marks{
        .mrk{margin-bottom: 5px !important;}
        .mrk::before{content: '';position: absolute;left:0px;height: 25px;width: 25px;background-size: contain;background-image: url(../assets/mark.png);}
    }
}

@media only screen and (max-width: 620px) {
    p{width: 90% !important}
    #new_hero{    margin-top: -110px;padding: 130px 0 0px 0 !important;}
    #new_hero .first_title{font-size: 22px !important;}
    #new_hero .title{font-size: 27px !important;}
      #marks{
          &{   margin-bottom: 15px !important;margin-top: 15px;gap: 10px !important;padding-bottom: 20px;}
        .mrk{font-size: 15px !important;;margin-bottom: 10px !important;width: 70%;margin: 0 auto;text-align: left;}
        .mrk::before{content: '';position: absolute;left:0px;height: 25px;width: 25px;background-size: contain;background-image: url(../assets/mark.png);}
    }
}


@media only screen and (max-width: 900px){
    #new_hero #marks .mrk{padding-right: 10px; width: 100%;}
}
@media (max-width: 414px){
    #new_hero #marks{flex-wrap: wrap;flex-direction: column;}
    #new_hero #marks p{margin-bottom: 0 !important;}
    #new_hero #marks{margin-top:0px;}
    #new_hero #marks .mrk{margin: 0 auto; text-align: center;width: fit-content;margin-bottom: 0 !important;}
    #marks .mrk:nth-child::before{left:0!important;}

}

</style>