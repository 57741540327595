<template>
    <div>
        <Header/>
        <div class="centered" style="padding-top:100px" v-html="text"></div>
        
    </div>
</template>
<script>
import Header from '../components/header.vue';

export default {
    components:{Header},
    data(){
        return{
            text:''
        }
    },
    mounted(){
         var self = this
     this.net_api({ action: 'terms' }, function(data){ 
        
         self.text = data.data.terms
     
      } );

    }
}
</script>