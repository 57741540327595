<template>
    <div id="courses">
        <h2>Our Upcoming Courses</h2>
        <div class="centered_l">
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" v-for="item in $store.upcoming_courses" :key="item.id">
               <div id="wrapper" :class="'active'" >
                <div class="icon_fir"  :style="'background-image:url('+item.pic_parsed+')'">
                  <h4>{{item.title}}</h4>
                  <div class="line_cor"></div>
                  <div class="wrapper">
                    <p class="date">{{item.date}}</p>
                    <p class="classes">{{item.classes}}</p>
                  </div>
                  <div class="line_cor"></div>
                  <p class="text_open">{{item.text}}</p>
                  <a :href="item.button" v-if="item.button"><button class="btn">SHOW ME MORE</button></a>
                </div>
              </div>
            </div>   
        </div>
        <div class="clr"></div>
    </div>
</template>

<script>
export default {
    data () {
      return {
        active_item:this.$store.upcoming_courses.id,
      }
    },
}
</script>

<style lang="scss" scoped>
#courses{padding:35px 0px; background:#fff; 
    h2{margin:0 auto; text-align:center; color:#4e5074; padding-bottom:40px;font-family: 'Poppins', sans-serif; font-size: 36px;}
    #wrapper{ margin: 0 auto; text-align: center; width: 100%; min-height: auto; background:#fff; border-radius: 15px;
        .icon_fir{background-image:url(../../assets/courses_one.png); margin-right: 30px;background-size: cover !important;; background-repeat: no-repeat; width:100%; min-height: 200px; border-radius: 15px; border:none; color:#fff; padding-top:85px;background-blend-mode: overlay; background: #282833; background-position: center;}
        h4{font-weight: 600; font-size: 21px;}
        button{color:#fff; border-radius: 4px; text-transform: uppercase; font-size: 13px; font-weight: 600; padding:5px 35px; line-height: 28px;background:#1480dc; margin-top:30px;}
    }
    .active{min-height: auto; margin-top:-20px!important;
      .icon_fir{padding-top:0px!important; padding: 25px 15px 25px 15px!important;background-blend-mode: overlay; background: #373741;}
      .line_cor{height:2px; width:90%; background:#fff; margin:0 auto; margin-top:15px; margin-bottom:15px}
      .wrapper{display:flex; justify-content: center; font-size: 16px; justify-content: space-around;
        .date{padding-right: 20px; margin-bottom:0px; position: relative; padding-left: 28px;}
        .date::before{content:""; position: absolute; background-image:url(../../assets/date_pic.png); background-size: contain;background-position: center; background-repeat: no-repeat; width:20px; height:20px; left:0px; top:2px}
        .classes{margin-bottom:0px; position: relative; padding-left: 28px;}
        .classes::before{content:""; position: absolute; background-image:url(../../assets/classes.png); background-size: contain;background-position: center; background-repeat: no-repeat; width:20px; height:20px; left:0px; top:2px}
      }
      .text_open{font-size: 17px; padding-top:10px; width:90%; margin:0 auto;margin-bottom:25px; line-height: 26px; font-weight: 300;}
    }
}
@media(max-width:1420px){
    #courses #wrapper .icon_fir{padding: 85px 10px;}
}
@media(max-width:600px){
  #courses #wrapper .icon_fir{min-height: 365px;}
  #courses .active .wrapper .date{padding-left: 5px;}
  #courses .active .wrapper .classes{padding-left: 5px;}
  #courses .active .wrapper .date:before{left:50px}
  #courses .active .wrapper .classes:before{left:63px}
}
@media(max-width:414px){
  #courses {padding: 20px 0px;
    h2{font-size: 25px; padding-bottom:20px}
    .active .wrapper .date{padding-left: 30px;}
    .active .wrapper .classes{padding-left: 20px;}
    .active .wrapper .date:before{left:25px}
    .active .wrapper .classes:before{left:25px}
    .active{margin-top:0px!important;}
      
      .icon_fir{padding-top:0px!important; padding: 25px 15px 25px 15px!important;background-blend-mode: overlay; background: #373741;}
      .line_cor{height:2px; width:90%; background:#fff; margin:0 auto; margin-top:15px; margin-bottom:15px}
      .wrapper{display:flex; justify-content: center; font-size: 16px; justify-content: space-around;
        .date{padding-right: 20px; margin-bottom:0px; position: relative; padding-left: 28px;}
        .date::before{content:""; position: absolute; background-image:url(../../assets/date_pic.png); background-size: contain;background-position: center; background-repeat: no-repeat; width:20px; height:20px; left:25px; top:2px}
        .classes{margin-bottom:0px; position: relative; padding-left: 28px;}
        .classes::before{content:""; position: absolute; background-image:url(../../assets/classes.png); background-size: contain;background-position: center; background-repeat: no-repeat; width:20px; height:20px; left:30px; top:2px}
      }
      .text_open{font-size: 17px; padding-top:10px; width:90%; margin:0 auto;margin-bottom:25px; line-height: 26px; font-weight: 300;}
      button{color:#fff; border-radius: 4px; text-transform: uppercase; font-size: 13px; font-weight: 600; padding:5px 35px; line-height: 28px;background:#1480dc}
    
  }
  @media(max-width:375px){
    #courses .wrapper .date{padding-right: 0px; padding-left: 35px;;}
    #courses .wrapper .date::before {left: 20px;}
    #courses .wrapper .classes::before{left: 25px;}
  }
}
</style>