<template v-if="loaded">
    <div id="reviews">
      <div class="overlay_reviews">
        <div class="centered-big">
          <div class="page_title">What Our Graduates Say</div>
            <h5>{{$store.words.reviews_title}}</h5>
            <div class="title" v-html="$store.words.reviews_sub_title">{{$store.words.reviews_sub_title}}</div>
          
                <carousel :autoplay="is_mobile()"  :perPageCustom="[[320, 1], [1000, 3]]" :mouse-drag="true" :loop="true" :navigationEnabled="true"  :navigationNextLabel="'<div class=right></div>'" :navigationPrevLabel="'<div class=left></div>'">
    <slide v-for="item in reviews" :key="item.id" class="item">
     <div class="review">
                      <!-- <div class="quete"></div> -->
                      <div class="pics_wrapper">
                        <div class="logo" v-if="item.logo > 0" :style="'background-image:url('+ item.logo_parsed+')'"></div>
                        <div class="pic"  :style="'background-image:url('+ item.pic_parsed+')'"></div>
                      </div>
                      <div class="name">{{item.name}}</div>
                      <div class="job">{{item.job}}</div>
                      <p class="p_desc">{{item.description}}</p>
                      <!-- <div class="quete_sec"></div> -->
                      </div>
    </slide>
 
  </carousel>
                <div class="clr">  </div>
        </div>
        </div>
    </div>
</template>
<script>
// @ is an alias to /src



  export default {
        
    props:["reviews"],
    data() {
    return {
      loaded:false,
    }
  },
   
  
}
</script>
<style scoped lang="scss">
#reviews{background:#373b3a; padding: 50px 0 50px 0; background-image: url(../assets/review_pic.png); ;background-position: center; background-size: cover; background-repeat: no-repeat; background-blend-mode: overlay}
// #reviews{background-image: url(../assets/pic_building.png) ;padding: 50px 0 50px 0;background-position: center;background-size: cover;}
#reviews{
  // .overlay_reviews{background-image: url(../assets/overlay_bulding.jpg); background-position: center;background-size: cover; }
  .title{width: 100%; margin: 0 auto;margin-bottom: 35px;line-height: 46px;margin-top: 8px; color:#fff; font-weight: 400;font-size: 28px !important;}
  h5{padding-top: 12px; font-size: 18px;font-weight: 400;}
  p{width: 100%; margin: 0 auto; padding: 10px 0; font-size: 15px !important; text-align: center;     color: #4e5074; font-weight: 600;}
  .name{font-weight: 700;font-size: 22px;line-height: 17px; text-align: center; color:#4e5074}
  .job{font-size: 15px; text-align: center; color:#a6a7b9; line-height: 35px;}

}

.pics_wrapper{width: 65%;margin: 0 auto;height: 20px;position: relative;}
.pic{height: 100px;width: 100px;background-size: cover;border-radius: 100%;margin: 0 auto;position: absolute;top: -110px;left: 0 ;background-position: center;z-index: 1;  }
.logo{height: 100px;width: 100px;background-size: cover;border-radius: 100%;margin: 0 auto;position: absolute;top: -110px;right: 0 ;background-position: center;z-index: 2;  box-shadow: 0 0 46.4px 4.6px rgba(1, 1, 1, 0.16);}
.review{position: relative;padding: 50px 55px 30px; min-height: 200px;text-align: center; width: 90%;  margin: 0 auto; border-radius: 15px;background: #fff;}
.quete{background-image: url(../assets/quote_top.png);position: absolute;top: 10px;left: 10px;height: 40px;width: 40px;background-size: contain;}
.quete_sec{background-image: url(../assets/quote_bot.png);position: absolute;bottom: 10px;right: 10px;height: 40px;width: 40px;background-size: contain;}
.item{padding-top:80px;height: auto;}
.page_title{    color: #fff;
    font-size: 38px;
    text-align: center;
    font-weight: 800;}
p{margin-top: 20px;}

@media only screen and (max-width: 1200px){
  #reviews .title{font-size: 23px !important;}
  .review{width: 90% !important;margin:  0 auto;}
    .VueCarousel-navigation-next{right:50px;}
    .VueCarousel-navigation-prev{left:50px!important}
}
@media only screen and (max-width: 900px){
  .p_desc{line-height: 18px!important;}
}
@media only screen and (max-width: 414px){
  .pic{height: 60px; width:60px; left:-25px; top:-50px; }
  .logo{height: 80px; width:80px; right:-25px; top:-38px; }
  #reviews{padding:20px 0px;}
  #reviews .item{padding-top:40px;}
  .review{width:80% ; padding:20px 10px 20px 10px; height: auto;}
  #reviews .name{text-align: center;}
  #reviews .job{text-align: center;}
  #reviews .title{margin-bottom:0px;}
  #reviews p{font-size: 14px!important; padding:0px;}
  .p_desc{line-height: 18px!important;}
}  
@media only screen and (max-width: 360px){
  .pic{display:none}
  .review{width:100% ; padding:20px 10px 20px 10px;}
  #reviews p{font-size: 14px!important; padding:0px;}
  .p_desc{line-height: 18px!important;}
}  
</style>
