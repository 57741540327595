<template v-if="loaded">
  <div id="event">
    <div class="wrapper_event">
      <div class="wraper">
        <p class="p_title" v-html="$store.words.event_date"></p>
        <h2 v-html="date"></h2>
      </div>
      <div class="wraper">
        <p class="p_title" v-html="$store.words.event_time"></p>
        <h2 v-html="time"></h2>
      </div>
      <div class="wraper">
        <p class="p_title" v-html="$store.words.event_location"></p>
        <h2 v-html="location"></h2>
      </div>
      <div class="wraper">
        <p class="p_title" v-html="$store.words.event_meeting"></p>
        <h2 v-html="length"></h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["event","time","date","location","length"],
  data(){
    return{
      loaded: false,
    }
  }
};
</script>

<style lang="scss" scoped>
#event {
  width: 100%;
  min-height: 140px;
  background: #fff;
  .wrapper_event {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 45px;
    position: relative;
    width: 65%;border-bottom: 2px solid rgb(204 204 204 / 20%);
    padding-bottom: 30px;
    margin: 0 auto;
    .wraper {
      position: relative;
      padding-left: 15px;
    }
    p {
      margin-bottom: 0px !important;
      font-size: 15px !important;
      color: #717390;
      font-weight: 600;
    }
    h2 {
      color: #4e5074;
      font-size: 18px;
      line-height: 20px;
      font-weight: 700;
      font-family: "Nunito", sans-serif!important;
    }
  }
}
.p_title::before {
  content: "";
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 45px;
  width: 45px;
  position: absolute;
  left: -40px;
}
.wrapper_event .wraper:nth-child(1) .p_title:before {
  background-image: url(../assets/calender.png);
}
.wrapper_event .wraper:nth-child(2) .p_title:before {
  background-image: url(../assets/clock.png);
}
.wrapper_event .wraper:nth-child(3) .p_title:before {
  background-image: url(../assets/location.png);
}
.wrapper_event .wraper:nth-child(4) .p_title:before {
  background-image: url(../assets/meeting.png);
}
@media only screen and (max-width: 1200px) {
  #event .wrapper_event {
    width: 85%;
  }
}
@media only screen and (max-width: 900px) {
  #event .wrapper_event {
    width: 95%;
    margin-left: 35px;
  }
  #event .wrapper_event p {
    font-size: 14px !important;
  }
  #event .wrapper_event h2 {
    font-size: 18px;
    line-height: 8px;
  }
  .p_title[data-v-793361ba]::before {
    top: 9px;
  }
  // .p_title::before {top: 18px; left: -45px;}
}
@media only screen and (max-width: 500px) and (min-width:415px){
  #event .wrapper_event {
    width: 100%;
    padding-bottom: 40px;
    padding-top: 20px;
    margin-left: 60px;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  #event .wrapper_event .wraper {
    padding-top: 20px;
    padding-left: 0px;
    width: 40%;
  }
  .p_title::before {
    top: 27px !important;
    left: -45px;
    height: 36px;
    width: 40px;
  }
  #event .wrapper_event h2 {
    font-size: 15px;
    line-height: 14px;
  }
  #event .wrapper_event .wraper:nth-child(2) h2 {
    padding-left: 39px;
  }
  #event .wrapper_event .wraper:nth-child(2) p {
    padding-left: 39px;
  }
  #event .wrapper_event .wraper:nth-child(4) h2 {
    padding-left: 39px;
  }
  #event .wrapper_event .wraper:nth-child(4) p {
    padding-left: 39px;
  }
  #event .wrapper_event .wraper:nth-child(2) .p_title:before {
    left: 3px !important;
  }
  #event .wrapper_event .wraper:nth-child(4) .p_title:before {
    left: 5px !important;
  }
  #event .wrapper_event .wraper {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 414px) {
  #event .wrapper_event {
    width: 100%;
    padding-bottom: 40px;
    padding-top: 20px;
    margin-left: 60px;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  #event .wrapper_event .wraper {
    padding-top: 20px;
    padding-left: 0px;
    width: 40%;
  }
  .p_title::before {
    top: 27px !important;
    left: -45px;
    height: 36px;
    width: 40px;
  }
  #event .wrapper_event h2 {
    font-size: 15px;
    line-height: 14px;
  }
  #event .wrapper_event .wraper:nth-child(2) h2 {
    padding-left: 39px;
  }
  #event .wrapper_event .wraper:nth-child(2) p {
    padding-left: 39px;
  }
  #event .wrapper_event .wraper:nth-child(4) h2 {
    padding-left: 39px;
  }
  #event .wrapper_event .wraper:nth-child(4) p {
    padding-left: 39px;
  }
  #event .wrapper_event .wraper:nth-child(2) .p_title:before {
    left: 3px !important;
  }
  #event .wrapper_event .wraper:nth-child(4) .p_title:before {
    left: 5px !important;
  }
  #event .wrapper_event .wraper {
    padding-left: 20px;
  }
}
@media only screen and (max-width: 360px) {
  #event .wrapper_event {
    width: 100%;
    padding-bottom: 40px;
    padding-top: 20px;
    margin-left: 60px;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  #event .wrapper_event .wraper {
    padding-top: 20px;
    padding-left: 0px;
  }
  .p_title::before {
    top: 27px !important;
    left: -45px;
    height: 36px;
    width: 40px;
  }
  #event .wrapper_event h2 {
    font-size: 18px;
    line-height: 14px;
  }
  #event .wrapper_event .wraper:nth-child(2) h2 {
    padding-left: 33px;
  }
  #event .wrapper_event .wraper:nth-child(2) p {
    padding-left: 65px;
  }
  #event .wrapper_event .wraper:nth-child(4) h2 {
    padding-left: 35px;
  }
  #event .wrapper_event .wraper:nth-child(4) p {
    padding-left: 35px;
  }
  #event .wrapper_event .wraper:nth-child(2) .p_title:before {
    left: 15px !important;
  }
  #event .wrapper_event .wraper:nth-child(4) .p_title:before {
    left: 19px !important;
  }
}
</style>
