<template>
  <div id="video_page">
    <div id="iframe-wrapper">

<vue-plyr :key="video_url" ref="plyr">
  <video controls crossorigin playsinline data-poster="poster.jpg">
    <source :src="'https://cdnapi.bamboo-video.com/api/entry/' + video_url + '/flavors/playlist.m3u8?iid=5acb09316986fb539d5007a4'" type="video/mp4" />
  </video>
</vue-plyr>

    </div>
  </div>
</template>
<style lang="scss" scoped>
#iframe-wrapper{width: 100% !important;height: auto;margin-bottom: 30px;}
@media only screen and (max-width: 600px) {
  #iframe-wrapper{height: 220px !important;
    position: fixed;z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    right: 0;}
}
</style>

<script>
import Hls from 'hls.js';

export default {
  props: ["video_url"],
  data() {
    return {
      loaded: false,
    }
  },
  computed:{
    player(){
      return this.$refs.plyr.player;
    }
  },
  mounted() {

    if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource('https://cdnapi.bamboo-video.com/api/entry/' + this.video_url + '/flavors/playlist.m3u8?iid=5acb09316986fb539d5007a4');
        hls.attachMedia(this.player.media);

        window.hls = hls;
    }


    this.$refs.plyr.player.on('ready', () => this.$emit("video_loaded"));
      


    this.loaded = true;
  }
}
  
</script>