<template>
<div id="app" :class="show_side_menu ? 'menu_open' : ''">
        <div id="header_mobile" v-if="is_mobile()">
            <a class="logo" href="/"></a>
            <div id="menu_hamburger" v-on:click="show_side_menu = !show_side_menu">
                <div id="nav-icon3">
                <span></span><span></span><span></span><span></span>
                </div>
            </div>
            <a id="mobile_logo" href="/"></a>
        </div>
        <div id="side_menu" v-if="is_mobile()">
            <ul class="sharing_list">
                <li class="menu" v-for="item in menu_homepage" :key="item.id">
                    <a v-text="item.name" href="/"></a>
                </li>
                <li><a @signup="scroll('contact_pop')">Signup</a></li>
            </ul>
        </div>
        <div id="instructor">
            <Header
            @scrollTo="scroll($event)"
            :menu_homepage="menu_homepage"
            :blank="true"
            v-if="!is_mobile()"
            @sign="$emit('pop_up_contact')"
        />
   

        <section id="hero">
            <div class="pic_instructor" :style="'background-image:url('+item.pic_parsed+')'"></div>
            <h2>{{item.name}}</h2>
            <p>{{item.job}}</p>
            <a :href="item.link_logo" >
                <div class="logo" :style="'background-image:url(' + item.logo_parsed+ ')'"></div>
            </a>

            <div class="wrapper_soci">
                <a v-if="item.link_facebook" :href="item.link_facebook" target="_blank"><div class="icon facebook"></div></a>
                <a v-if="item.link_linkedin" :href="item.link_linkedin" target="_blank"><div class="icon linkedin"></div></a>
                <a v-if="item.link_instagram" :href="item.link_instagram" target="_blank"><div class="icon instagram"></div></a>
            </div>
            
            <p class="desc">{{item.description}}</p>
        </section>

        <section id="skill">
            <h2 v-html="$store.words.skills_title"></h2>
                <div class="centered_l">
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" v-for="item in $store.upcoming_courses" :key="item.id">
                    <div id="wrapper" :class="(active_item == item.id ? 'active' :'')"  @mouseover="active_item = item.id" >
                        <div class="icon_fir" v-if="active_item != item.id && !is_mobile()" :style="'background-image:url('+item.pic_parsed+')'">
                        <h4>{{item.title}}</h4>
                        </div>
                        <div class="icon_fir" v-if="active_item == item.id || is_mobile()" :style="'background-image:url('+item.pic_parsed+')'">
                        <h4>{{item.title}}</h4>
                        <div class="line_cor"></div>
                        <div class="wrapper">
                            <p class="date">{{item.date}}</p>
                            <p class="classes">{{item.classes}}</p>
                        </div>
                        <div class="line_cor"></div>
                        <p class="text_open">{{item.text}}</p>
                        <a :href="item.button" v-if="item.button"><button class="btn">SHOW ME MORE</button></a>
                        </div>
                    </div>
                    </div>   
                </div>
            <div class="clr"></div>  
        </section>

        <section id="articles">
            <div class="centered_l">
                <h2>More from {{item.name}}</h2>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" v-for="articles in item.articles" :key="articles.id">
                    <div class="item">
                        <div class="pic_article" :style="'background-image:url('+articles.pic_parsed+')'">
                            <!-- <h3>Lorem ipsum dolor</h3> -->
                            <h3>{{articles.title}}</h3>
                        </div>
                        <div class="wrapper">
                            <p class="date">{{articles.date_created}}</p>
                            <p class="exstra">{{articles.extra}}</p>
                        </div>
                        <div class="wrapper_text">
                            <p v-html="articles.short_desc"></p>
                            <a :href="'/article/'+ articles.id" target="_blank"> read more </a>
                        </div>
                    </div>
                </div>
                <div class="clr"></div>
            </div>
        </section>

        <div class="newsletter">
            <p>Sign up to our newsletter to get the most updated news and ideas here!</p>
            <div class="wrapper_input">
                <input type="text" placeholder="Email" >
                <input type="submit" value="SEND" class="btn">
            </div>
        </div>

    <FooterHome/>

    </div>
</div>
</template>

<script>
import Header from "../components/header.vue";
import FooterHome from "../components/main-home/footerHome.vue";
export default {
    components:{Header, FooterHome},
    props: ["menu_homepage"],
        metaInfo() { return this.parse_meta(this.meta); },

    data(){
        return{
            active_item:this.$store.upcoming_courses.id,
            show_side_menu: false,
            id: this.$route.params.id,
            //articles: {},
            item: {},
            meta: {
                title:'',
                description:''
            },
        }
    },
     mounted() {
        //  alert(this.id)
        this.net_api({ action: "instructor",data:{id:this.id} }, (data) => {
            this.item = data.data.instructor
            this.meta.title = data.data.instructor.name
            this.meta.description = data.data.instructor.description
            //this.articles = data.data.instructor.articles
        });
     }
}
</script>

<style lang="scss" scoped>
#header_mobile {
  position: fixed;
  height: 50px;
  top: 0;
  background: #fff;
  left: 0;
  width: 100%;
  z-index: 99999;
}

.sign {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  background: #1480dc;
  width: 180px;
  height: 40px;
  margin: 0 auto;
  border-radius: 4px;
  color: #fff;
  line-height: 42px;
  text-transform: uppercase;
  margin-top: 10px;
}
.sign:hover {
  background: #13505b !important;
}

li[data-v-29e8c3c6]:hover {
  color: #13505b;
}
#instructor{ padding-top:65px;
    #hero{background-image:url(../assets/background_inst2.png);margin:0 auto; text-align:center;color:#fff; min-height: 671px; width:100%; background-position: center; background-size: cover; background-repeat: no-repeat; padding-top:65px; 
        h2{font-size: 40px;}
        p{font-size: 24px;}
        .pic_instructor{background:#fff; background-position: center; background-size: contain; background-repeat: no-repeat; width:204px; height: 200px; border-radius: 33px; box-shadow: 0 0 21px 4px rgba(0, 0, 0, 0.14); margin: 0 auto; margin-bottom:25px;}
        .logo{width:200px; height:100px; background:#fff; margin:25px auto; border-radius: 22px; box-shadow: 0 0 31.9px 6.1px rgba(0, 0, 0, 0.08); background-size: contain; background-position: center; background-repeat: no-repeat;}
        .wrapper_soci{display:flex; justify-content: center; margin-bottom:25px;
            .icon{width:25px; height:25px; background-size: contain; background-repeat: no-repeat; background-position: center;}
            .facebook{background-image:url(../assets/facebook_inst.png); margin-left:25px;}
            .instagram{background-image:url(../assets/instegram_inst.png); margin-left:25px;}
            .linkedin{background-image:url(../assets/linkedin_inst.png); margin-left:25px;}
        }
        .desc{width:60%; margin:0 auto; text-align: center;}
    }
    
    #skill{padding:50px 0px; background:#f1f7ff;
        h2{margin:0 auto; text-align:center; color:#3d3d3d; padding-bottom:40px;font-family: 'Poppins', sans-serif; font-size: 35px;}
        #wrapper{ margin: 0 auto; text-align: center; width: 100%; min-height: auto; background:#fff; border-radius: 15px;
            .icon_fir{background-image:url(../assets/courses_one.png); margin-right: 30px;background-size: cover; background-repeat: no-repeat; width:100%; min-height: 200px; border-radius: 15px; border:none; color:#fff; padding-top:85px;background-blend-mode: overlay; background: #282833; background-position: center;}
            h4{font-weight: 600; font-size: 21px;}
            button{color:#fff; border-radius: 4px; text-transform: uppercase; font-size: 13px; font-weight: 600; padding:5px 35px; line-height: 28px;background:#1480dc; margin-top:30px;}
        }
        .active{min-height: auto; margin-top:-20px!important;
        .icon_fir{padding-top:0px!important; padding: 25px 15px 25px 15px!important;background-blend-mode: overlay; background: #373741;}
        .line_cor{height:2px; width:90%; background:#fff; margin:0 auto; margin-top:15px; margin-bottom:15px}
        .wrapper{display:flex; justify-content: center; font-size: 16px; justify-content: space-around;
            .date{padding-right: 20px; margin-bottom:0px; position: relative; padding-left: 28px; }
            .date::before{content:""; position: absolute; background-image:url(../assets/date_pic.png); background-size: contain;background-position: center; background-repeat: no-repeat; width:20px; height:20px; left:0px; top:2px;}
            .classes{margin-bottom:0px; position: relative; padding-left: 28px;}
            .classes::before{content:""; position: absolute; background-image:url(../assets/classes.png); background-size: contain;background-position: center; background-repeat: no-repeat; width:20px; height:20px; left:0px; top:2px}
        }
        .text_open{font-size: 17px; padding-top:10px; width:90%; margin:0 auto;margin-bottom:25px; line-height: 26px; font-weight: 300;}
        }
    }

    #articles{padding:45px 0px;
        h2{margin:0 auto; text-align:center; color:#3d3d3d; padding-bottom:40px;font-family: 'Poppins', sans-serif; font-size: 35px;}
        .item{background:#fff; border-radius: 44px; box-shadow: 0 0 31.9px 6.1px rgba(0, 0, 0, 0.08); min-height: 500px; width: 100%; padding-top:2px;
            .pic_article{background:#ccc; background-size: cover; background-position: center; background-repeat: no-repeat; width:95%; height:250px;  border-radius: 31px; box-shadow: 0 0 31.9px 6.1px rgba(52, 70, 92, 0.21); margin:10px; position: relative;
                h3{text-align: center; position: absolute;bottom: 30px; left: 0;right: 0;margin: 0 auto; color:#fff;  z-index: 2;}
            }
            .pic_article::after{content:""; position: absolute;; background:#34465c; height: 50px; width: 100%; bottom:0px; opacity: 0.5;   border-bottom-right-radius: 23px;border-bottom-left-radius: 23px; filter: blur(6px);}

            .wrapper{display:flex; justify-content: center; font-size: 16px; justify-content: space-around; padding:20px 10px;
                .date{padding-right: 20px; margin-bottom:0px; position: relative; padding-left: 28px; color:#4e5074}
                .date::before{content:""; position: absolute; background-image:url(../assets/date_inst.png); background-size: contain;background-position: center; background-repeat: no-repeat; width:20px; height:20px; left:0px; top:2px}
                .exstra{margin-bottom:0px; position: relative; padding-left: 28px;  color:#4e5074}
                .exstra::before{content:""; position: absolute; background-image:url(../assets/exstra_inst.png); background-size: contain;background-position: center; background-repeat: no-repeat; width:20px; height:20px; left:0px; top:2px}
            }
            .wrapper_text{text-align: center; padding:0px 30px 30px 30px;
                p{color:#4e5074; overflow: hidden; text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 3; line-clamp: 3; -webkit-box-orient: vertical;}
                a{text-transform: capitalize; color:#126ebc; text-decoration: underline; font-weight: 600;}
            }
        }
    }

    .newsletter{width:100%; min-height:100px; background-image: url(../assets/pic_building.jpg); background-position: initial; background-size: cover; background-repeat: no-repeat; background-blend-mode: overlay;padding:30px; display:flex; justify-content: center; align-items: center;  background-color: #293845;
        p{color:#fff; margin-right:20px; font-size: 22px; margin-bottom:0px;}
        input{background:#fff; padding-left: 15px; margin-right:20px; border-radius: 5px; height: 30px; font-size: 16px; height: 34px; outline:none;}
        .btn{background:#1480dc; color:#fff; padding: 0px 36px; height: 34px; font-weight: 500;}
    }

    @media(max-width:1275px){
        #hero{
            .wrapper_soci .facebook{margin-left: 0px;}
        }
    }
    @media(max-width:414px){ 
        padding-top:0px;
    #hero{
        h2{font-size: 30px;}
        p{font-size: 20px;}
        .wrapper_soci .facebook{margin-left: 0px;}
    }
    
    #skill {padding: 30px 0px;
        h1{font-size: 25px; padding-bottom:20px}
        .active .wrapper .date{padding-left: 30px;}
        .active .wrapper .exstra{padding-left: 20px;}
        .active .wrapper .date:before{left:25px}
        .active .wrapper .exstra:before{left:25px}
        .active{margin-top:0px!important;}
        
        .icon_fir{padding-top:0px!important; padding: 25px 15px 25px 15px!important;background-blend-mode: overlay; background: #373741;}
        .line_cor{height:2px; width:90%; background:#fff; margin:0 auto; margin-top:15px; margin-bottom:15px}
        .wrapper{display:flex; justify-content: center; font-size: 16px; justify-content: space-around;
            .date{padding-right: 20px; margin-bottom:0px; position: relative; padding-left: 28px;}
            .date::before{content:""; position: absolute; background-image:url(../assets/date_pic.png); background-size: contain;background-position: center; background-repeat: no-repeat; width:20px; height:20px; left:0px; top:2px}
            .exstra{margin-bottom:0px; position: relative; padding-left: 28px;}
            .exstra::before{content:""; position: absolute; background-image:url(../assets/exstra_inst.png); background-size: contain;background-position: center; background-repeat: no-repeat; width:20px; height:20px; left:0px; top:2px}
        }
        .text_open{font-size: 17px; padding-top:10px; width:90%; margin:0 auto;margin-bottom:25px; line-height: 26px; font-weight: 300;}
        button{color:#fff; border-radius: 4px; text-transform: uppercase; font-size: 13px; font-weight: 600; padding:5px 35px; line-height: 28px;background:#1480dc}
    
        }
    }

    #articles{
        h1{font-size: 30px; line-height: 30px;}
    }

    .newsletter{flex-direction: column;
        p{line-height: 25px; text-align: center; font-size: 18px; margin-bottom:10px}
        .wrapper_input{display: flex;
            input{margin-bottom:10px;}
            .btn{width:50%;margin: 0 auto;}
        }
    }
}
</style>