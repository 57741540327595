<template v-if="loaded">
    <div id="logos">
        <div class="centered_l">
            <div class="wrapper ">
                <h3>Our Instructors Are Featured In</h3>
            </div>
            <div id="logos_wrapper">
            <div class="logos"  v-for="item in $store.featured_logos" :key="item.id">
                    <div class="logo" :style="'background-image:url('+item.pic_parsed+')'"></div>
            </div>
            </div>
            <div class="clr"></div>
        </div>
    </div>
</template>
<script>
  export default {
    data () {
      return {
        loaded:false,
      }
    },
  }
</script>
<style lang="scss" scoped>
#logos_wrapper{display: grid; grid-template-columns: repeat(8, 1fr);gap: 10px;grid-auto-rows: 1fr;}

#logos{padding:40px 0px;
    .wrapper{
             h3{text-transform: uppercase; color:#4e5074; font-weight: 800;text-align: center;font-size: 25px;margin-bottom: 20px;}
    }
    .logos{
            .logo{background-size: contain; background-position: center; background-repeat: no-repeat; width:100%; height: 100px;}
        
    }
} 
@media(max-width:1200px){
  #logos .wrapper h3{
    width:30%;
  }
}
@media(max-width:600px){
  #logos_wrapper{display: grid; grid-template-columns: repeat(4, 1fr);gap: 10px;grid-auto-rows: 1fr;}

  #logos{padding-bottom:0px;}
  #logos .wrapper{flex-direction: column; align-items: center; align-content: center;}
  #logos .wrapper h3{text-align:center; width:100%; height: 70px;}
}
@media(max-width:414px){
  #logos{padding-bottom:0px;}
  #logos .wrapper{flex-direction: column; align-items: center; align-content: center;}
  #logos .wrapper h3{text-align:center; width:100%; height: 0px; padding-bottom:35px}
  #logos .logos .wrapper_logos{display: flex;justify-content: center; }
}
</style>