<template>
    <div id="footer">
        <div class="inner"> 
            <!-- <div class="up" @click="$emit('scroll_top')">&#8593;</div> -->
            <div class="contact" @click="$emit('open_pop')">Enroll Now</div>
            <!-- <div class="phone"><i class="fas fa-phone"></i></div> -->
        </div>
    </div>
</template>
<script>
export default {
    methods:{
    },
 
}
</script>
<style scoped>
.inner{position: relative;height: 100%;text-align: center;padding-top: 10px;}
.contact{background: #f68b35;
    padding: 5px 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: 90%;
    font-size: 21px;
    margin: 0 auto;
    color: #fff;
    font-weight: 800 !important;
    border: none;
    border-radius: 12px !important;}
#footer{height: 60px; z-index: 9999; position: fixed; bottom: 0; width: 100%; background: #fff;}
.phone{ height: 40px;border-radius: 0 90px 90px 0;padding-top: 8px;text-align: center;width: 40px;bottom: 10px;position: absolute;font-size:18px;left:0; color: #fff; background: #30abb7;}
.up{ height: 40px;border-radius: 90px 0px 0px 90px;;padding-top: 8px;text-align: center;width: 40px;bottom: 10px;position: absolute;font-size:18px;right:0; color: #fff; background: #30abb7;}
</style>