<template>
<div id="app" :class="show_side_menu ? 'menu_open' : ''"  v-if="loaded">
        <div id="header_mobile" v-if="is_mobile()">
            <a class="logo" href="/"></a>
            <div id="menu_hamburger" v-on:click="show_side_menu = !show_side_menu">
                <div id="nav-icon3">
                <span></span><span></span><span></span><span></span>
                </div>
            </div>
            <a id="mobile_logo" href="/"></a>
        </div>
        <div id="side_menu" v-if="is_mobile()">
            <ul class="sharing_list">
                <li
                class="menu"
                v-for="item in menu_homepage"
                :key="item.id"
                @click="
                    scroll(item.selector);
                    show_side_menu = false;
                "
                >
                <a v-text="item.name"></a>
                </li>
                <!-- <li><a href="/login">Log in</a></li> -->
                <li><a @signup="scroll('contact_pop')">Signup</a></li>
            </ul>
        </div>
        <div id="article_page">
            <Header
            @scrollTo="scroll($event)"
            :menu_homepage="menu_homepage"
            v-if="!is_mobile()"
            @sign="$emit('pop_up_contact')"
        />

            
    
        
        <div class="centered_h">

        <div class="side_arti col-lg-8 col-md-8 col-xs-12">
            <div class="bread">
                <h1><a href="/Blog">Our blog <i class="fas fa-chevron-right"></i> </a><span>{{item.title}}</span></h1>
            </div>
            <div class="top_section">
                <div class="wrapper_title">
                    <div v-if="item.category" class="sub_title">{{item.category.name}}</div>
                    <h2 v-html="item.title"></h2>
                </div>
                <div class="pic_arti" :alt="item.pic_alt" :title="item.pic_alt" :style="'background-image:url('+item.pic_parsed+')'"></div>
                <div class="wrapper">
                    <p v-html="item.content"></p>
                </div>
                <!-- <div class="social">
                    <div class="soci email">email</div>
                    <div class="soci facebbok">facebook</div>
                    <div class="soci twitter">twitter</div>
                    <div class="soci linkedin">linkedin</div>
                    <p class="date">{{item.date_created}}</p>
                </div> -->
            </div>

            <div class="instructor">
                <div class="wrapper" v-if="item.writer">
                    <div class="rotate">
                        <div class="pic" :style="'background-image:url('+item.writer.pic_parsed+')'"></div>
                    </div>
                    <div class="wrapper_info">
                        <h3>{{item.writer.name}}</h3>
                        <p v-html="item.short_desc"></p>
                    </div>
                </div>
                <div class="comment">
                    <h3>Leave a replay</h3>
                    <textarea name="" id="" cols="85" rows="4" placeholder="You must be logged in to post a comment" v-model="message"></textarea>
                    <div class="btn" @click="add_comment()">SEND</div>
                </div>
                <div id="comments" v-if="item.comments.length > 0">
                    <div class="title">Comments</div>
                    <div class="comment_wrapper" v-for="(commment, i) in item.comments" :key="i">
                        <div class="num" v-text="(i+1)+'. '"></div>
                        <div class="text" v-text="(commment.message)"></div>
                        <div class="date" v-text="(commment.parse_date)"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="side col-lg-4 col-md-4 col-xs-12">
            <!-- <div class="wrapper_cates">
                <h2>Categories</h2>
                <div class="wrapper">
                    <p>business</p>
                    <p class="cate">{{item.category_id}}</p>
                </div>
            </div> -->

             <div class="wrapper_popular">
                <h2>popular Articles</h2>
                <a class="wrapper" v-for="item in articles.slice(2, 5)" :key="item.id" :href="item.link">
                    <div class="pic_popular" :style="'background-image:url('+item.pic_parsed+')'"></div>
                    <div class="info_wrapp">
                    <p>{{item.title}}</p>
                    <p class="date">{{item.date_created}}</p>
                    </div>
                </a>
            </div>

            <!-- <div class="banner"></div> -->

            <!-- <div class="wrapper_tags">
                <h2>tags</h2>
                <div class="wrapper">
                   <p>{{item.tags_arr}}</p>
                </div>
            </div>  -->

             <div class="wrapper_news">
                <h2>Join Our Newsletter</h2>
                <div class="wrapper">
                   <p>Join +10K Real Estate Proffesional who get updated</p>
                   <form class="wrapper_input" @submit.prevent="save_newsletter()">
                    <input type="text" placeholder="Your Email Address" required v-model="email"> 
                    <input type="submit" value="SIGN UP"  class="btn">
                   </form>
                </div>
            </div>

        </div>
        <div class="clr"></div>

        <!-- <div class="last_post">
            <div class="title_posts">
                <h2>Realated Posts</h2>
                <p>view all</p>
            </div>
                <div class="col-lg-4 col-md-4 col-xs-12" v-for="items in item.articles.slice(0,3)" :key="items.id">
                    <div class="item">
                        <div class="pic" :style="'background-image:url('+items.pic_parsed+')'"></div>
                        <div class="wrapper">
                            <div class="sub_title">{{item.sub_title}}</div>
                            <h2>{{items.title}}</h2>
                            <p v-html="items.short_desc"></p>
                        </div>
                        <div class="wrapper_bottom">
                            <div class="pic" :style="'background-image:url('+instructor.pic_parsed+')'"></div>
                            <p>{{instructor.name}}</p>
                            <p class="date">{{items.date_created}}</p>
                        </div>
                    </div>
                </div>
        </div> -->
        <div class="clr"></div>

        </div>


        <div class="newsletter">
            <p>Sign up to our newsletter to get the most updated news and ideas here!</p>
            <form class="wrapper_input" id="newsletter_form" @submit.prevent="save_newsletter()">
                <input type="text" placeholder="Email" required v-model="email">
                <input type="submit" value="SEND" class="btn" style="color: #fff;font-weight: 700;">
            </form>
        </div>

    <FooterHome/>

    </div>
</div>
</template>

<script>
import Header from "../components/header.vue";
import FooterHome from "../components/main-home/footerHome.vue";
export default {
  
 
    components:{Header, FooterHome},
    
    metaInfo() { return this.parse_meta(this.item.meta); },
    props: ["menu_homepage"],
    data(){
        return{
            loaded: false,
            show_side_menu: false,
            id: this.$route.params.id,
            articles: [],
            item: [],
            items: [],
            message:null,
            email:null,
            instructor:[],

        }
    },
    methods:{
        save_newsletter(){
            if (this.email.length < 10) {
                this.alert('Please Enter A Valid Email!');
            } else {
                 this.net_api({ action: "newsletter",data:{email:this.email} });
                  this.alert('Your email has been sent successfully');
            }
             
            
        },
        add_comment(){
            if(this.message.length > 5)
            {
                this.net_api({ action: "add_comment",data:{message:this.message, id: this.item.id} });
                 this.alert('Comment send successfully!');
                 this.message = '';
            }
            else{
                this.alert('Please insert comment');
            }
              
            
        },
    },
     mounted() {
        this.net_api({ action: "article/results",data:{id:this.id} }, (data) => {
            this.items = data.data;

            
        });
        this.net_api({ action: "article/get",data:{id:this.id} }, (data) => {
            this.item = data.data;
            this.$emit('meta',this.item.meta)
            this.loaded = true;
        });
        this.net_api({ action: "article/add_view",data:{id:this.id} }, () => {
   
        });
      this.net_api({ action: "article/blog",data:{id:this.id} }, (data) => {
            this.articles = data.data.articles
        });
        this.net_api({ action: "instructor",data:{id:this.id} }, (data) => {
            this.instructor = data.data.instructor
        });
       
     }
}
</script>

<style lang="scss" scoped>
.bread{font-size: 7px;margin-bottom: 20px;}
#comments{
    .title{text-align: left !important;margin-top: 20px !important;font-size: 30px !important;}
    .comment_wrapper{display: flex; flex-direction: column; padding-left: 25px; position: relative;margin-bottom:20px;
        .num{    position: absolute;  left: 0; font-size: 20px; font-weight: 800; top: 0;}
        .text{font-size: 18px;font-weight: 600;}
        .date{font-size: 14px;}
    }
}
#header_mobile {
  position: fixed;
  height: 50px;
  top: 0;
  background: #fff;
  left: 0;
  width: 100%;
  z-index: 99999;
}

.sign {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  background: #1480dc;
  width: 180px;
  height: 40px;
  margin: 0 auto;
  border-radius: 4px;
  color: #fff;
  line-height: 42px;
  text-transform: uppercase;
  margin-top: 10px;
}
.sign:hover {
  background: #13505b !important;
}

li[data-v-29e8c3c6]:hover {
  color: #13505b;
}

#article_page{padding-top:80px;
    #hero{ background-image:url(../assets/background_inst2.png); background-position: center; background-size:cover; background-repeat:no-repeat; width:100%; height:220px; margin-bottom:55px;
        .cates{color:#fff; text-align: left;; padding:80px 60px;font-size:15px;
            a{color: #fff;}
            a:hover{text-decoration: none;}
            span{font-weight: 500;}
            p{margin-bottom:0px;}
            .fa-chevron-right{margin: 0px 15px;}
        }
    }

    .side_arti{
        .top_section{border-bottom:1px solid #ccc; padding-bottom:50px;
        .wrapper_title{border-bottom:1px solid #ccc; margin-bottom:40px; padding-bottom:20px;
            p{margin-bottom:0px; text-transform: uppercase; color:#242424; font-weight: 700; font-size: 18px;}
            h2{font-size: 42px; color:#242424; text-transform: capitalize;}
        }
            .pic_arti{background-size: cover; background-position: center; background-repeat: no-repeat; width:100%; height:450px; margin-bottom:30px;}

            .social{display:flex; align-items: center;
                .soci{background:#1480dc; color:#fff; border-radius: 6px; padding: 5px 25px; max-width:20%; cursor: pointer; margin-left:10px;}
                .date{margin-left: 40px; margin-bottom:0px;position: relative; font-size: 16px;}
                .date::before{content:""; position:absolute; background-image: url(../assets/timeGrey.png); background-size:contain; background-position: center; background-repeat: no-repeat; width: 20px; height: 20px; left: -25px; top: 1px;}
            }
            .social .soci:first-child{margin-left: 0px;}
        }
        .instructor{padding:30px 0;
            .wrapper{display:flex; padding:20px  0px 40px;
                .rotate{width: 85px;position: relative;height: 85px;box-shadow: 0 0 21px 4px rgb(0 0 0 / 14%);border-radius: 23px;overflow: hidden;transform: rotate(45deg);}

                .pic{max-width: 1000px;transform: rotate(-45deg);position: absolute;left: -13px;top: -13px;bottom: -13px;right: -13px;background-size: cover;background-position: top;background-repeat: no-repeat;}
                .wrapper_info{padding-left: 25px;}
            }
            .comment{background:#f6f6f6; width:100%;; padding:30px 40px; border-radius: 6px;
            .btn{color: #fff;font-weight: 600;}
                textarea{margin-top:20px; outline:none}
            }
        }
    }

    .side{padding-right:40px;
        .wrapper_cates{margin-bottom:30px;
            h2{text-transform: uppercase;padding-bottom:30px; color:#3d3d3d;font-size: 25px;}
            .wrapper{display:flex; justify-content: space-between; 
                p{color:#3d3d3d; font-size: 18px; text-transform: capitalize; font-weight: 800;}
                .cate{background:#171e3c; width:25px; height: 25px; border-radius:50%; color:#fff; text-align: center; line-height:25px; font-size: 15px;}
            }
            
        }
        .wrapper_popular{margin-bottom:30px;
            h2{text-transform: uppercase;padding-bottom:30px; color:#3d3d3d; font-size: 25px;}
            .wrapper{display:flex;align-items: center; margin-bottom:20px;
                .pic_popular{background-size: cover; background-position: center; background-repeat:no-repeat; min-width:75px; height:75px; border-radius: 50%;} 
                p{color:#3d3d3d; font-size: 18px; text-transform: capitalize; font-weight: 800; padding-left: 20px; margin-bottom:0px;;}
                .date{font-size: 16px; color:#3d3d3d; font-size: 14px; font-weight: 400; position: relative;padding-left: 45px; margin-top:5px;}
                .date::before{content:""; position:absolute; background-image: url(../assets/timeGrey.png); background-size:contain; background-position: center; background-repeat: no-repeat; width: 20px; height: 20px; left: 18px; top: 0px;}
            }
        }
        .banner{width: 100%; height:350px; background:#ccc;margin-bottom:30px;}

        .wrapper_tags{margin-bottom:30px;
            h2{text-transform: uppercase;padding-bottom:30px; color:#3d3d3d; font-size: 25px;}
            .wrapper{display:flex; flex-wrap: wrap;
                p{color:#3d3d3d; font-size: 16px; text-transform: capitalize; font-weight: 600; border:1px solid #e0e0e0; padding:8px 10px;  border-radius: 6px; margin-right: 10px;;}
            }
        }

        .wrapper_news{margin-bottom:10px;
            h2{text-transform: uppercase;padding-bottom:10px; position: relative; color:#3d3d3d; font-size: 25px;}
            h2::after{content:""; position:absolute; top:0px; right:0px; width:50px}
            .wrapper{
                p{color:#3d3d3d; font-size: 15px; text-transform: capitalize;}
                .wrapper_input{display:flex; align-items: center;}
                input{border:1px solid #e0e0e0; padding-left:15px; padding:10px 20px; border-radius: 6px; outline: none;}
                .btn{background: #1480dc; color:#fff; height: 50px; margin-left: 10px; font-weight: 600;}
            }
        }
    }
        .last_post{
            .title_posts{display: flex;justify-content: space-between; align-items: center;
                h2{text-transform: uppercase; padding-left: 15px; margin-bottom:25px; font-size: 36px;}
                p{color:#1480dc; font-size: 18px; font-weight: 800; cursor: pointer;}
            }
            
            .item{border:1px solid #e0e0e0; margin-bottom:30px;cursor: pointer;
            .pic{background-size: cover; background-position:center; background-repeat: no-repeat; width:100%; height: 300px;}
                .wrapper{ border-bottom:1px solid #e0e0e0; padding: 0px 20px;
                    .sub_title{color:#1480dc; font-size: 18px; font-weight: 700; padding:30px 0 10px 0;}
                    h2{color:#3d3d3d; line-height:35px}
                    p{color:#3d3d3d; font-size: 24px; line-height: 30px; padding:20px 0; margin-bottom:0px;}
                }
                .wrapper_bottom{display:flex; justify-content:center; padding:20px 0px;align-items: center;
                    .pic{width:35px; height:35px; border-radius: 50%;}
                    p{margin-bottom:0px;color:#3d3d3d; font-size:14px; padding-left: 5px;}
                    .date{margin-bottom:0px;color:#3d3d3d; font-size:14px; position: relative; margin-left: 40px;;}
                    .date::before{content:""; position:absolute; background-image: url(../assets/timeGrey.png); background-size:contain; background-position: center; background-repeat: no-repeat; width: 20px; height: 20px; left: -20px; top: 0px;}
                }
            }
        }

    .newsletter{width:100%; min-height:100px; background-image: url(../assets/pic_building.jpg); background-position: initial; background-size: cover; background-repeat: no-repeat; background-blend-mode: overlay;padding:30px; display:flex; justify-content: center; align-items: center;  background-color: #293845;
        p{color:#fff; margin-right:20px; font-size: 22px; margin-bottom:0px;}
        input{background:#fff; padding-left: 15px; margin-right:20px; border-radius: 5px; height: 30px; font-size: 16px; height: 34px; outline:none;}
        .btn{background:#1480dc; color:#fff; padding: 0px 36px; height: 34px; font-weight: 500;}
    }

    @media(max-width:600px){ 
        
        .side_arti .top_section .pic_arti{height: 200px;}
        #hero{ height: 170px;margin-bottom: 20px;}
        #hero .cates{padding:55px 20px}
        .side_arti .top_section .social{flex-wrap: wrap; 
            .soci{max-width: 32%; margin-bottom: 10px;margin-left: 0px; margin-right:10px;}
        }
        .side_arti .top_section .wrapper_title h2{font-size: 30px; line-height:35px;}
         .title_posts p{padding-right:15px;}
        .last_post .title_posts h2{font-size: 25px;;}
        .side_arti .instructor .comment{padding:30px 20px}
        .newsletter{flex-direction: column;
            p{line-height: 25px; text-align: center; font-size: 18px; margin-bottom:10px}
            .wrapper_input{display: flex;
                input{margin-bottom:10px;}
                .btn{width:50%;margin: 0 auto;}
            }
        
        }
    }
}

</style>