<template v-if="loaded">
  <div id="info_thanks">
    <div class="wrapper_icon">
      <div class="icon pic_live"></div>
      <div class="wrapper_text">
        <h3 v-html="$store.words.info_thanks_title_fir"></h3>
        <p id="p_info" v-html="$store.words.info_thanks_p_fir"></p>
      </div>
    </div>
    <div class="wrapper_icon">
      <div class="icon pic_practical"></div>
      <h3 v-html="$store.words.info_thanks_title_sec"></h3>
      <p id="p_info" v-html="$store.words.info_thanks_p_sec"></p>
    </div>
    <div class="wrapper_icon">
      <div class="icon pic_instructor"></div>
      <h3 v-html="$store.words.info_thanks_title_thr"></h3>
      <p id="p_info" v-html="$store.words.info_thanks_p_thr"></p>
    </div>
    <div class="wrapper_icon">
      <div class="icon pic_certificate"></div>
      <h3 v-html="$store.words.info_thanks_title_fou"></h3>
      <p id="p_info" v-html="$store.words.info_thanks_p_fou"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
    data(){
    return{
      loaded: false,
    }
  }
};
</script>
<style lang="scss" scoped>
#hero p{
  width: 100%;
}
#info_thanks {
  min-height: 240px;
  width: 100%;
  background: #ffff;
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
  text-align: center;
  padding: 40px 0 0 0;
  .icon {
    width: 75px;
    height: 75px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  h3 {
    color: #4e5074;
    font-size: 18px;
    margin-bottom: 5px;
  }
  #p_info{
    color: #4e5074;
    font-size: 17px;
    width: 100%!important;
    text-align: center;
    margin: 0 auto;
  }
  .wrapper_icon:nth-child(1) .pic_live {
    background-image: url(../assets/icon1.png);
  }
  .wrapper_icon:nth-child(2) .pic_practical {
    background-image: url(../assets/icon2.png);
  }
  .wrapper_icon:nth-child(3) .pic_instructor {
    background-image: url(../assets/icon3.png);
  }
  .wrapper_icon:nth-child(4) .pic_certificate {
    background-image: url(../assets/icon4.png);
  }
  @media only screen and (max-width: 1200px) {
    .icon {
      margin-bottom: 12px;
    }
    p {
      width: 75% !important;
    }
  }
  @media only screen and (max-width: 900px) {
    p {
      width: 85% !important;
    }
  }
}
@media only screen and (max-width: 414px) {
  #info_thanks {
    flex-wrap: wrap !important;
    justify-content: center !important;
    padding: 20px 0;
  }
  .wrapper_icon {
    padding-bottom: 30px;
  }
}
</style>
