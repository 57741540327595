<template v-if="loaded">
  <div id="about_thanks">
    <div class="centered_about">
      <h3>Gain Firsthand knowledge</h3>
      <h2>
        About The Fast-Paced World Of Real Estate And Get Inspired To Take
        Action
      </h2>
      <div class="b_col col-md-12">
        <div class="wrapper col-lg-6 col-md-6 col-sm-6" style="padding-left:40px;">
          <div class="wrapper_l">
            <div v-for="(item, i) in mudule" :key="i" class="wrapper_p">
              <p class="p_about" v-if="i < 10">
                <span>{{item.title}}: </span>
                <strong>{{ item.presentor }}</strong>
              </p>
            </div>
          </div>
        </div>
        <div class="wrapper col-lg-6 col-md-6 col-sm-6">
          <div class="wrapper_r">
            <div v-for="(item, i) in mudule" :key="i" class="wrapper_p">
              <p class="p_about" v-if="i >= 10">
                <span>{{item.title}}: </span>
                <strong>{{ item.presentor }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="clr"></div>

      <div class="wrpper_btn">
            <button class="btn">
                <a href="https://admin.urecenter.com/themes/MNS/images/urecenter.pdf" downland="urecenter" target="_blank">downland full curriculum</a>
            </button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["mudule"],
  data(){
    return{
      loaded: false,
    }
  }
};
</script>

<style lang="scss" scoped>
#about_thanks {
  background: #fff;
  padding: 50px 0;
  // .wrapper_p {
  //   border-bottom: 1px solid #e5e5e5;
  // }
  h3 {
    color: #4e5074;
    text-align: center;
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 20px;font-family: "Nunito", sans-serif;
  }
  span{font-weight: 300;}
  h2 {
    color: #4e5074;
    text-align: center;
    width: 60%;
    margin: 0 auto;
    line-height: 1.3;
    font-size: 35px;
  }
  .b_col {
    width: 100%;
    min-height: 350px;
    background: #fff;
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 34px;
    margin-bottom: 30px;
  }
  p.p_about {
    margin-bottom: 0px !important;
    padding: 12px;
    color: #4e5074;
    font-size: 16px;
    line-height: 5px;
    font-weight: 600; border-bottom: 1px solid #e5e5e5;width: 95%;
  }
  .wrapper .wrapper_l .wrapper_p:nth-child(10) .p_about{border-bottom:none;}
  .wrapper .wrapper_r .wrapper_p:nth-child(20) .p_about{border-bottom:none;}
  // .line_a{height: 1px; background: #e5e5e5; width: 95%;}
  .wrpper_btn {
    margin: 0 auto;
    text-align: center;
    padding-bottom: 10px;
  }
  button {
    text-transform: uppercase;
    background: #1480dc;
    padding: 15px 20px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 4px;
    line-height: 10px;
    height: 40px;
      a{text-decoration: none; color: #fff;}
  }
  p.p_about strong {
    line-height: 20px;
  }

  .wrapper_l .wrapper_p:nth-child(7) {
    border-bottom: none;
  }
  .wrapper_l .wrapper_p:nth-child(8) {
    border-bottom: none;
  }
  .wrapper_l .wrapper_p:nth-child(9) {
    border-bottom: none;
  }
  .wrapper_l .wrapper_p:nth-child(10) {
    border-bottom: none;
  }
  .wrapper_l .wrapper_p:nth-child(11) {
    border-bottom: none;
  }
  .wrapper_l .wrapper_p:nth-child(12) {
    border-bottom: none;
  }
  .wrapper_l .wrapper_p:nth-child(13) {
    border-bottom: none;
  }
  .wrapper_l .wrapper_p:nth-child(14) {
    border-bottom: none;
  }
  .wrapper_l .wrapper_p:nth-child(15) {
    border-bottom: none;
  }

  .wrapper_r .wrapper_p:last-child {
    border-bottom: none;
  }
  .wrapper_r .wrapper_p:nth-child(1) {
    border-bottom: none;
  }
  .wrapper_r .wrapper_p:nth-child(2) {
    border-bottom: none;
  }
  .wrapper_r .wrapper_p:nth-child(3) {
    border-bottom: none;
  }
  .wrapper_r .wrapper_p:nth-child(4) {
    border-bottom: none;
  }
  .wrapper_r .wrapper_p:nth-child(5) {
    border-bottom: none;
  }
  .wrapper_r .wrapper_p:nth-child(6) {
    border-bottom: none;
  }
  .wrapper_r .wrapper_p:nth-child(7) {
    border-bottom: none;
  }
}
@media only screen and (max-width: 1200px) {
  #about p.p_about {
    line-height: 25px;
  }
  @media only screen and (max-width: 1000px) {
    #about_thanks h2 {
      width: 70%;
    }
  }
  @media only screen and (max-width: 900px) {
    #about_thanks h2 {
      width: 95%;
    }
    .b_col {
      min-height: 450px !important;
    }
    .wrapper_l .p_about:last-child {
      // border-bottom: 1px solid #e5e5e5;
      width: 95%;
      padding-right: 0px;
    }
    .col-md-6 {
      padding-top: 0px;
    }
  }
  @media only screen and (max-width: 414px) {
    .wrapper {padding-left:15px!important}
    .b_col {
      padding: 12px;
    }
    #about_thanks h2 {
      font-size: 25px;
    }
    #about_thanks .wrapper_r .p_about:last-child {
      border-bottom: none !important;
    }
    #about_thanks .wrapper_l .wrapper_p:nth-child(7) {
        border-bottom: 1px solid #e5e5e5;
    }
  }
  @media only screen and (max-width: 600px) {
    #about_thanks h2 {
      font-size: 25px;
    }
  }
}
</style>
