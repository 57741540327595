<template>
  <div id="video_page_lect" v-if="pause">
    <div id="iframe-wrapper">

<vue-plyr :key="video_url" ref="plyr_lect" :options="options">
  <video  allowfullscreen="false" >
    <source :src="'https://cdnapi.bamboo-video.com/api/entry/' + video_url + '/flavors/playlist.m3u8?iid=5acb09316986fb539d5007a4'" type="video/mp4" />
  </video>
</vue-plyr>
    </div>
  </div>
</template>
<style lang="scss" scoped>
#iframe-wrapper{width: 100% !important;height: auto;margin-bottom: 30px;}
.plyr--init-hide-controls .plyr__controls {
    opacity: 0;
}
@media only screen and (max-width: 600px) {
  #iframe-wrapper{}
}
</style>

<script>
import Hls from 'hls.js';

export default {
  props: ["video_url","pause"],
  data() {
    return {
      loaded: false,
      options:{
        allowautoplay:true,
        autoplay:true,
        controls: ['play-large'],
        allowfullscreen:false
      }
    }
  },
  computed:{
    player(){
       this.$refs.plyr_lect.player.on('enterfullscreen', () =>  this.$emit("close"))
      return this.$refs.plyr_lect.player;
    },
    
  },
  mounted() {
// alert(this.video_url)
    if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource('https://cdnapi.bamboo-video.com/api/entry/' + this.video_url + '/flavors/playlist.m3u8?iid=5acb09316986fb539d5007a4');
        hls.attachMedia(this.player.media);

        window.hls = hls;
    }


    this.$refs.plyr_lect.player.allowFullscreen = false;
    this.$refs.plyr_lect.player.on('ready', () => this.$emit("video_loaded"));
      


    this.loaded = true;
  }
}
  
</script>