<template>
    <div id="hero_home">
        <div class="hero">
            <div class="text_side">
                <div class="wrapper centered_h">
                    <h1 v-html="$store.words.home_title_hero"></h1>
                    <p v-html="$store.words.home_text_hero"></p>
                    <div class="wrapper_btn">
                        <a class="btn"  @click="popup = true" v-html="$store.words.calendly_btn_pop"></a>
                        <a class="btn" href="/lp1">learn more</a>
                    </div>
                    <p class="present" v-html="$store.words.home_text_hero_under_btn"></p>
                    <div id="marks">
                        <h4 class="mrk" v-html="$store.words.check_hero_one"></h4>
                        <h4 class="mrk" v-html="$store.words.check_hero_two"></h4>
                        <h4 class="mrk" v-html="$store.words.check_hero_three"></h4>
                    </div> 
                </div>
            </div>
            <div class="video_side">
                <div class="pic_play" @click="dialog = true">
                    <!-- <VideoHero ></VideoHero>  -->
                </div>
            </div>
        </div>
        <div class="clr"></div>
         <v-dialog v-model="dialog" width="600" content-class="lect_vid" :key="ref">
            <v-card>
              <div class="header_pop">
                <div class="close" @click="dialog = false">X</div>
                    <iframe width="660px" :src="$store.words.lp1_video" height="200px" v-if="dialog"></iframe>
              </div>
            </v-card>
          </v-dialog>

         <v-dialog v-model="popup" content-class="calendly_popup" :key="ref">
            <v-card>
              <div class="header_pop">
                <div class="close" @click="popup = false">X</div>
                    <iframe  src="https://calendly.com/urecenter-introductory-call/urecenter-expert-consultation?hide_gdpr_banner=1"></iframe>
              </div>
            </v-card>
          </v-dialog>
    </div>
</template>

<script>
export default {
    components: {  },
    data(){
        return{
        popup:false,
        dialog:false,
        ref:0,
        }
    },
    mounted(){
    },
    methods: {
      open_video(data)
      {
        this.ref++
        this.video = data.video;
        this.dialog = true;
      },
      dialog_play() {
        this.$refs[`plyr`].stop();
      },
      close() {
        this.$refs[`plyr`].fullscreen.exit();
      },
  },
}
</script>

<style lang="scss" scoped>
 #marks{font-family: "Nunito", sans-serif;gap:40px;
        &{padding-left: 22px; display: flex; }
        .mrk{letter-spacing: 0.3px;font-size: 14px;font-weight: 600;position: relative; color:#fff; }
        .mrk::before{content: '';position: absolute;left:-24px;height: 18px;width: 18px; -webkit-mask-size: contain;-webkit-mask-image: url(../../assets/v_icon.png); background:#fff;  -webkit-mask-repeat: no-repeat;;}
    }
.present{width: fit-content !important;position: relative;font-size: 14px !important;}
.present::after{position: absolute;right: -30px;content: '';height: 20px;width: 20px;background-image:url(../../assets/gift.png);top: 0px;background-repeat: no-repeat;background-size: contain;}
.header_pop {
  & {
    background: #363c4a;
    height: 0;
  }
  video ,iframe{margin: 0 auto; display: block; height: 350px;margin-top: -20px; z-index: 9;border: none;}
  .close { position: absolute; right: 20px; font-size: 22px; cursor: pointer; color: #fff; z-index: 10; top: 20px;}
}
#hero_home{
    background: #242424;
    .hero{width:100%; min-height: 690px; background-image:url(../../assets/hero_dark_back.jpg); background-size: contain; background-repeat: no-repeat;background-position: center;
        display: flex;background-position: bottom;
       .text_side{padding-top:190px; padding-left:160px;}
        .video_side,.text_side{flex: 1;position: relative;}
        .wrapper{
            h1{color:#fff; width:90%; line-height: 45px; padding-bottom: 15px; font-family: 'Poppins', sans-serif; font-weight: 800;}
            p{font-size: 16px; width: 90%; color:#fff;}
            .wrapper_btn{    display: flex;gap: 20px;max-width: 550px;margin: 20px 0;}
            .btn{color: #fff;background: #1480dc;border-radius: 4px;text-transform: uppercase;font-size: 16px;padding: 0px 20px;font-weight: 600;height: 60px;display: flex;line-height: 1.5;align-items: center;flex: 1;justify-content: center;}
            .btn:last-child{background:#fff;color: #000;}
            .btn:hover{text-decoration: none;}
        }
    }
    .pic_play{background-image:url(../../assets/play.png); background-position: center;background-size: contain; background-repeat: no-repeat; width: 100%; height: 100px; ; cursor: pointer;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);}
}
@media(max-width:1800px){
    .hero{min-height: 600px !important;}
}
@media(max-width:1600px){
    #hero_home .hero .text_side{padding-top: 100px;}
    .hero{min-height: 500px !important;}
}
@media(max-width:1200px){
    #hero_home .hero .wrapper h1{width:100%}
    #hero_home .hero .wrapper p{width:90%}
    #hero_home .hero .wrapper{padding-left: 35px;}
    #hero_home .hero{min-height: 700px;}
}

@media(max-width:600px){
  
    #marks{    flex-direction: column; align-items: center; justify-content: center;gap: 0;padding-bottom: 40px;}
    .present{width: 80% !important;margin: 10px auto;}
    #hero_home .hero .text_side{padding-left: 0;}
    #hero_home .hero .wrapper .wrapper_btn .btn{padding: 10px;}
    #hero_home .hero,#hero_home .hero .wrapper .wrapper_btn{flex-direction: column;padding: 0 20px;margin-bottom: 10px;}
    iframe{height: 210px !important;}
  #hero_home .hero .wrapper{padding-top: 90px;;}
}
@media(max-width:414px){
    #hero_home .hero {min-height: 590px;  background-image:url(../../assets/header_mobile_pic.jpg);}
    #hero_home .hero .pic_play{    position: inherit;margin: 65px auto 0;}
    .header_pop video{height: 247px;}
    #hero_home .hero .wrapper{padding-left: 0px; padding-top:0px; margin-bottom:0px; margin-top:16px;}
    #hero_home .hero .wrapper h1{font-size: 22px; line-height: 26px; text-align: center;}
    #hero_home .hero .wrapper p{width:100%; text-align:center}
    #hero_home .hero .wrapper .wrapper_btn{margin: 0 auto; text-align: center;}
}
@media(max-width:375px){
    #hero_home .hero {min-height: 635px;}
}
@media(max-width:360px){
    #hero_home .hero {min-height: 640px;}
}
</style>