<template v-if="loaded">
<div id="app" :class="show_side_menu ? 'menu_open' : ''">
        <div id="header_mobile" v-if="is_mobile()">
            <a class="logo" href="/"></a>
            <div id="menu_hamburger" v-on:click="show_side_menu = !show_side_menu">
                <div id="nav-icon3">
                <span></span><span></span><span></span><span></span>
                </div>
            </div>
            <a id="mobile_logo" href="/"></a>
        </div>
        <div id="side_menu" v-if="is_mobile()">
            <ul class="sharing_list">
                <li  class="menu"  v-for="item in menu_homepage"  :key="item.id"  @click="  scroll(item.selector);  show_side_menu = false; ">
                <a v-text="item.name"></a>
                </li>
                <li><a @signup="scroll('contact_pop')">Signup</a></li>
            </ul>
        </div>
        <div id="blog">
            <Header
            @scrollTo="scroll($event)"
            :menu_homepage="menu_homepage"
            v-if="!is_mobile()"
            @sign="$emit('pop_up_contact')"
        />
                    <Hero />



    <FooterHome/>

    </div>
</div>
</template>

<script>
import Header from "../components/header.vue";
import Hero from "../components/main-home/dark_hero.vue";

import FooterHome from "../components/main-home/footerHome.vue";
export default {
    components:{Header, FooterHome, Hero},
    props: ["menu_homepage"],
    data(){
        return{
            loaded: false,
          
        }
    },
    mounted() {
       
    }

}
</script>

<style lang="scss" scoped>


</style>