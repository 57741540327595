<template>
  <div id="video_page_lect" >
    <div id="iframe-wrapper">
      <div class="video_pic" :style="'background-image:url('+video.pic_parsed+')'">
        <div v-if="video.video" @click="open_video(video)">
          <i class="far fa-play-circle" ></i>
        </div>
      </div>
    </div>
     <v-dialog v-model="dialog" width="600" content-class="lect_vid" :key="ref" v-if="dialog">
            <v-card>
              <div class="header_pop">
                <div class="close" @click="dialog = false">X</div>
                <Video :video_url="video_link" :pause="dialog" v-if="video.youtube == 0"/>
                <iframe frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen :src="video.video" v-else/>
              </div>
            </v-card>
          </v-dialog>
  </div>
</template>
<style lang="scss" scoped>
.fa-play-circle{position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);font-size: 90px;color: #fff;cursor: pointer;}
#iframe-wrapper{width: 100% !important;height: auto;}
video{border-radius: 20px;height: 400px !important;border: none;}
iframe{height: 340px !important;border: none;width: 100%;}
.video_pic{border-radius: 20px;height: 400px !important;background-size: cover;background-position: center;position: relative;}
.plyr--init-hide-controls .plyr__controls {
    opacity: 0;
}
@media only screen and (max-width: 600px) {
  .video_pic{    height: 400px!important;
    width: 80%;
    margin: 10px auto 0;}
  #iframe-wrapper{}
}
</style>

<script>
// import Hls from 'hls.js';
import Video from "@/components/lect_video.vue";
export default {
  components: { Video },

  props: ["video"],
  data() {
    return {
      ref: 0,
      dialog: false,
      loaded: false,
      video_link: '',
      options:{
        allowautoplay:true,
        autoplay:true,
        controls: ['play-large'],
        allowfullscreen:false
      }
    }
  },
  methods: {
    open_video(data)
    {
      this.ref++
      this.video_link = data.video;
      this.dialog = true;
    },
    dialog_play() {
      this.$refs[`plyr`].stop();
    },
    close() {
      this.$refs[`plyr`].fullscreen.exit();
    },
  },
  computed:{
    // player(){
    //    this.$refs.plyr_lect.player.on('enterfullscreen', () =>  this.$emit("close"))
    //   return this.$refs.plyr_lect.player;
    // },
    
  },
  mounted() {
// alert(this.video_url)
    // if (Hls.isSupported()) {
    //     const hls = new Hls();
    //     hls.loadSource('https://cdnapi.bamboo-video.com/api/entry/' + this.video_url + '/flavors/playlist.m3u8?iid=5acb09316986fb539d5007a4');
    //     hls.attachMedia(this.player.media);

    //     window.hls = hls;
    // }


    // this.$refs.plyr_lect.player.allowFullscreen = false;
    // this.$refs.plyr_lect.player.on('ready', () => this.$emit("video_loaded"));
      


    this.loaded = true;
  }
}
  
</script>