<template>
    <div id="reviews_home">
      <div class="overlay_reviews">
        <div class="centered-big">
            <h2 v-html="$store.words.home_title_reviews"></h2>
                <div class="title" v-html="$store.words.reviews_sub_title">{{$store.words.reviews_sub_title}}</div>
                <carousel :autoplay="is_mobile()" v-if="is_mobile()"
                perPage="3" :per-page-custom=[[260,1],[1024,3]] :mouse-drag="true" :loop="false" :navigationEnabled="true"  :navigationNextLabel="'<div class=right></div>'" :navigationPrevLabel="'<div class=left></div>'">
                    <slide v-for="item in reviews" :key="item.id" class="item">
                    <div class="review">
                          <!-- <div class="quete"></div> -->
                          <div class="pic"  :style="'background-image:url('+ item.pic_parsed+')'"></div>
                          <div class="name">{{item.name}}</div>
                          <div class="job">{{item.job}}</div>
                          <p class="p_desc">{{item.description}}</p>
                          <!-- <div class="quete_sec"></div> -->
                    </div>
                </slide>
            </carousel>
            <div id="rev_wrapper"  v-else>
              <div class="item" v-for="item in reviews" :key="item.id">
                <div class="review">
                      <!-- <div class="quete"></div> -->
                      <div class="pic"  :style="'background-image:url('+ item.pic_parsed+')'"></div>
                      <div class="name">{{item.name}}</div>
                      <div class="job">{{item.job}}</div>
                      <p class="p_desc">{{item.description}}</p>
                      <!-- <div class="quete_sec"></div> -->
                </div>
              </div>
            </div>
            </div>
                <div class="clr">  </div>
        </div>
        </div>
</template>

<script>
  export default {   
    props:["reviews"],
    data() {
    return {

    }
  },
}
</script>

<style scoped lang="scss">
#rev_wrapper{display: grid;grid-template-columns: repeat(3, 1fr);gap: 10px;grid-auto-rows: 1fr;}
#reviews_home{background-image:url(../../assets/reviews.png); padding: 50px 0 50px 0 ;background-position: center; background-size: cover; background-repeat: no-repeat; min-height: 550px;font-family: 'Poppins', sans-serif;
  h2{color:#fff; margin: 0 auto; text-align: center; font-weight: 600; padding-bottom:20px; font-size: 35px;}
  .title_sec{color:#fff; padding-top:0px; padding-bottom:30px; text-align: center; width:90%; margin-left: 0px;} 
  .title{width: 100%; margin: 0 auto;margin-bottom: 35px;line-height: 46px;margin-top: 8px; color:#fff; font-weight: 600;}
  h5{padding-top: 12px; font-size: 18px;font-weight: 400;}
  p{width: 100%; margin: 0 auto; padding: 10px 0; font-size: 20px !important; text-align: center; color: #4e5074; font-weight: 300!important;}
  span{font-weight: 700;}
  #stars{background-image:url(../../assets/starts.png); background-size: contain;background-position: center; background-repeat: no-repeat; width:100px; height:17px;display: inline-block; margin: 0 10px}
  .title_sec{position:relative;}
  .title_sec::after{content:""; position: absolute; background-image:url(../../assets/logore.png); background-size: contain;background-position: center; background-repeat: no-repeat; width:120px; height:120px; right:12%;; bottom:-16px}
  .name{font-weight: 700;font-size: 21px;line-height: 17px; text-align: center; color:#4e5074;margin-bottom: 10px;}
  .job{font-size: 15px; text-align: center; color:#a6a7b9; line-height: 1.3;}
  .p_desc{font-size: 16px!important; font-weight: 600;}
}
.pic{height: 100px;width: 100px;background-size: cover;border-radius: 100%;margin: 0 auto;position: absolute;top:-72px;left: 0px; right:0px; background-position: center; box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;}
.review{position: relative; padding: 50px 18px 5px 18px; min-height: 320px;text-align: center; width: 85%;  margin: 0 auto;border-radius: 15px;background: #fff;}
.quete{position: absolute;top: 10px;left: 10px;height: 40px;width: 40px;background-size: contain;}
.quete_sec{position: absolute;bottom: 10px;right: 10px;height: 40px;width: 40px;background-size: contain; text-align: center;}
.item{padding-top:72px;height: auto;}
p{margin-top: 20px;}

@media(max-width:1370px){
  .review{min-height: 345px!important;}
}
@media(max-width:1200px) and (min-width:1160px){
  #reviews_home .title_sec::after {right:6%}
}

@media(max-width:1150px) and (min-width:1000px){
  #reviews_home .title_sec::after {right:3%}
}

@media(max-width:1090px) and (min-width:1000px){
  #reviews_home .title_sec::after {right:-3%}
}

@media(max-width:600px){
  #reviews_home .title{width: 85%;}
  #reviews_home{padding:30px 0;}
  #reviews_home h2{font-size: 25px;}
  #reviews_home .title_sec{font-size: 16px!important; margin: 0 auto;}
  #reviews_home .title_sec::after{right:140px; top:0px}
}
</style>
