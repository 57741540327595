<template v-if="loaded">
  <div id="event_thanks">
    <div class="wrapper_event">
      <div class="wraper">
        <p class="p_title" v-html="$store.words.event_date"></p>
        <h2 v-html="$store.words.event_date_info"></h2>
      </div>

      <div class="wraper">
        <p class="p_title" v-html="$store.words.event_time"></p>
        <h2 v-html="$store.words.event_time_info"></h2>
      </div>

      <div class="wraper">
        <p class="p_title" v-html="$store.words.event_location"></p>
        <h2 v-html="$store.words.event_location_info"></h2>
      </div>

      <div class="wraper">
        <p class="p_title" v-html="$store.words.event_meeting"></p>
        <h2 v-html="$store.words.event_meeting_info"></h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
    data(){
    return{
      loaded: false,
    }
  }
};
</script>

<style lang="scss" scoped>
#event_thanks {
  width: 100%;
  min-height: 180px;
  background-image: url(../assets/eventbackgroundNew.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px 0;
  .wrapper_event {
    display: flex;
    justify-content: space-around;
    padding-top: 45px;
    position: relative;
    width: 70%;
    margin: 0 auto;
    .wraper {
      position: relative;
      padding-left: 15px;
    }
    p {
      margin-bottom: 0px !important;
      font-size: 15px !important;
      color: #fff;
      font-weight: 600;
    }
    p.p_title {
      font-weight: 400;
    }
    h2 {
      color: #fff;
      font-size: 16px !important;
      line-height: 25px;
      font-weight: 700;
    }
  }
}
.p_title::before {
  content: "";
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  height: 45px;
  width: 45px;
  position: absolute;
  left: -40px;
  background: #fff;
}
.wrapper_event .wraper:nth-child(1) .p_title:before {
  -webkit-mask-image: url(../assets/calender.png);
}
.wrapper_event .wraper:nth-child(2) .p_title:before {
  -webkit-mask-image: url(../assets/clock.png);
}
.wrapper_event .wraper:nth-child(3) .p_title:before {
  -webkit-mask-image: url(../assets/location.png);
}
.wrapper_event .wraper:nth-child(4) .p_title:before {
  -webkit-mask-image: url(../assets/meeting.png);
}
@media only screen and (max-width: 1200px) {
  #event_thanks .wrapper_event {
    width: 80%;
  }
}
@media only screen and (max-width: 1000px) {
  #event_thanks .wrapper_event {
    width: 90%;
  }
}
@media only screen and (max-width: 900px) {
  #event_thanks .wrapper_event {
    width: 95%;
    margin-left: 35px;
  }
  #event_thanks .wrapper_event p {
    font-size: 14px !important;
  }
  #event_thanks .wrapper_event h2 {
    font-size: 20px;
    line-height: 25px;
  }
  .p_title[data-v-793361ba]::before {
    top: 9px;
  }
  // .p_title::before {top: 18px; left: -45px;}
}

@media only screen and (max-width: 414px) {
  #event_thanks {
    padding: 10px 0px;
  }
  #event_thanks .wrapper_event h2 {
    font-size: 14px !important;
  }
  #event_thanks .wrapper_event {
    width: 80%;
    padding-bottom: 40px;
    padding-top: 20px;
    margin-left: 60px;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  #event_thanks .wrapper_event .wraper {
    padding-top: 20px;
    padding-left: 10px !important;
  }
  .p_title::before {
    top: 27px !important;
    left: -45px;
    height: 36px;
    width: 40px;
  }
  #event_thanks .wrapper_event h2 {
    font-size: 19px;
    line-height: 14px;
  }
  #event_thanks .wrapper_event .wraper:nth-child(2) h2 {
    padding-left: 65px;
  }
  #event_thanks .wrapper_event .wraper:nth-child(2) p {
    padding-left: 68px;
  }
  #event_thanks .wrapper_event .wraper:nth-child(4) h2 {
    padding-left: 105px;
  }
  #event_thanks .wrapper_event .wraper:nth-child(4) p {
    padding-left: 105px;
  }
  #event_thanks .wrapper_event .wraper:nth-child(2) .p_title:before {
    left: 23px !important;
  }
  #event_thanks .wrapper_event .wraper:nth-child(4) .p_title:before {
    left: 62px !important;
  }
  #event_thanks .wrapper_event .wraper {
    padding-left: 20px;
  }
}
@media only screen and (max-width: 360px) {
  #event_thanks {
    padding: 10px 0px;
  }
  #event_thanks .wrapper_event h2 {
    font-size: 12px !important;
  }
  #event_thanks .wrapper_event {
    width: 100%;
    padding-bottom: 40px;
    padding-top: 20px;
    margin-left: 60px;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  #event_thanks .wrapper_event .wraper {
    padding-top: 20px;
    padding-left: 10px !important;
  }
  .p_title::before {
    top: 27px !important;
    left: -45px;
    height: 36px;
    width: 40px;
  }
  #event_thanks .wrapper_event h2 {
    font-size: 19px;
    line-height: 14px;
  }
  #event_thanks .wrapper_event .wraper:nth-child(2) h2 {
    padding-left: 68px;
  }
  #event_thanks .wrapper_event .wraper:nth-child(2) p {
    padding-left: 68px;
  }
  #event_thanks .wrapper_event .wraper:nth-child(4) h2 {
    padding-left: 90px;
  }
  #event_thanks .wrapper_event .wraper:nth-child(4) p {
    padding-left: 90px;
  }
  #event_thanks .wrapper_event .wraper:nth-child(2) .p_title:before {
    left: 23px !important;
  }
  #event_thanks .wrapper_event .wraper:nth-child(4) .p_title:before {
    left: 46px !important;
  }
  #event_thanks .wrapper_event .wraper {
    padding-left: 20px;
  }
}
</style>
