import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false


import VueResource from 'vue-resource'
Vue.use(VueResource);
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {}
import VueCarousel from 'vue-carousel';
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
Vue.use(VuePlyr, {
  plyr: {}
})

Vue.use(VueCarousel);
// import { StripePlugin } from '@vue-stripe/vue-stripe';

// const options = {
//   pk: 'pk_test_51ImaYmGkG8BgSLUDKI8OY1axjb3LHgDPXI42mJhJtzGGbt35UFbaskkN1lZL3uTbXHw9tQj9qpsWtQhRJr7p166V00DevJGfU8',
//   stripeAccount: 'michael@nadlancenter.co.il',
//   apiVersion: 'price_1J2yJcGkG8BgSLUDYtvB3X9g',
//   locale: 'true',
// };

// Vue.use(StripePlugin, options);
export default new Vuetify(opts)
/*
import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)
*/


import VueEllipseProgress from 'vue-ellipse-progress';

Vue.use(VueEllipseProgress);
// import vueSmoothScroll from 'vue2-smooth-scroll'
// Vue.use(vueSmoothScroll)
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add icons to the library */
library.add(faUserSecret)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
//import 'vue-material/dist/theme/default-dark.css'
Vue.use(VueMaterial)


import Meta from 'vue-meta';
Vue.use(Meta);


import VueSimpleAlert from "vue-simple-alert";
Vue.use(VueSimpleAlert, { reverseButtons: true });




import './app.scss'
import vuetify from './plugins/vuetify';

window.api_url = 'https://admin.urecenter.com/api/';
window.is_production = false;




Vue.mixin({
 
  data(){
    return {
     // url_pre: (window.is_production ? 'https://www.nadlancenter.co.il/community/' : 'http://fff/')
    }
  },
  computed: {
  
  },
  methods: {
     validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  },
    validatePhone(str)
      {
        let newstr = str.replace('-', '').replace('+', '');
        var patt =  new RegExp(/^\d+$/);
   
        if(patt.test(newstr))
        {
          return true
        }
        else{
          return false;
        }
       
     
      },
      alert(msg){
        this.$alert(msg, '', '', { showDenyButton: false, confirmButtonText: 'OK'});
      },
    scroll(value) {  
      var elmnt = document.getElementById(value);
        elmnt.scrollIntoView({behavior:'smooth'});
        },
    time_convert(num)
    { 
      var hours = Math.floor(num / 60);  
      var minutes = num % 60;
      if (hours > 0 && minutes > 0)
      {
        return hours + " Hours and " + minutes + " minutes " ;         
      }
      else if(hours == 0 && minutes > 0)
      {
        return  minutes + " Minutes" ;         
      }
      else if(hours > 0 && minutes == 0)
      {
        return  hours + " Hours" ;         
      }
    },
    parse_date(str){
      var d=new Date(str); return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
    },

   get_word(key)
   {
     return this.$store.words[key];
   },
    format_number: function(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    isNull: function (str)
    {
      return (str == '' || str == null || str == undefined);
    },
    get_me(){
      return this.$store.user;
    },


  net_api: function(settings, func, failFunc){
    
    // var is_test = false;


     var headers = {
       'Content-Type': 'application/json; charset=utf-8',
     };
    
     if(!this.isNull(localStorage.getItem('user_token'))) 
     { 
       headers.Authorization = localStorage.getItem('user_token');
     }
   

    // if (!is_test)
    // {
       if (settings.method == 'get')
       {
         this.$http.get(window.api_url + settings.action, /* settings.data,*/ {/*emulateJSON:true,*/ headers: headers})
         .then(response => {
            if (response.body == 'error')
            {
              localStorage.setItem('user_token', '');
              document.location.reload();
            }
             func(response.body);
         }, response => { 
           if (!this.isNull(failFunc)) failFunc(response);
           else func(false);
         });

       }
       else
       {
         this.$http.post(window.api_url + settings.action, settings.data, {/*emulateJSON:true,*/ headers: headers})
         .then(response => {
          if (response.body == 'error')
          {
            localStorage.setItem('user_token', '');
            document.location.reload();
          }
             func(response.body);
             //console.log(response.body);
         }, response => { 
           if (!this.isNull(failFunc)) failFunc(response);
           else func(false);
             //console.error(response.body);
         });
     }
     

   },




    api_call: function(settings){

        if (this.isNull(settings.onComplete))
        {
          settings.onComplete = function(){};
        }
        else
        {
          var real_func = settings.onComplete;
          settings.onComplete = function(data){
            real_func(data);
          };
        }
        
        if (this.isNull(settings.data)) settings.data = {};
        settings.data.api_user_token = localStorage.getItem('user_token');
        
        //return this.$http.post(window.api_url + settings.url + '?&ts=' + (new Date()).getHours() + '_' + ((new Date()).getMinutes() < 30 ? '0' : '30') , settings.data).then(settings.onComplete);
          return this.$http.post(window.api_url + settings.url , settings.data).then(settings.onComplete);

    },

    is_mobile: function(){ 
      // if( /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      // {
      //   return true
      // }
      //  else if(window.innerWidth <= 800 && window.innerHeight <= 700)
      //  {
      //    return true
      //  }
      //  else{
      //    return true
      //  }
// alert(navigator.userAgent)
      return ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) 
      // return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 700 ) );
      //     return ( navigator.userAgent.match(/Android/i)
      // || navigator.userAgent.match(/webOS/i)
      // || navigator.userAgent.match(/iPhone/i)
      // // || navigator.userAgent.match(/iPad/i)
      // || navigator.userAgent.match(/iPod/i)
      // || navigator.userAgent.match(/BlackBerry/i)
      // );
    },

    open_chat(id) {
      if (window.appVue.open_chats.indexOf(id) === -1)
      {
        window.appVue.open_chats.push(id);
      }
    },

    parse_meta(meta)
    {
      if (this.isNull(meta)) return {};
   
      var ret = {};
      ret.meta = [];
      ret.link = [];
      if (meta.title != '') 
      {
        ret.title = meta.title;
        ret.meta.push({name: 'title', content: meta.title }) 
        ret.meta.push({name: 'og:title', content: meta.title }) 
      }
      if (meta.description != '')
      {
        ret.meta.push({name: 'og:description', content: meta.description }) 
        ret.meta.push({name: 'description', content: meta.description }) 
      }
      ret.link.push({rel: 'canonical', href:'www.urecenter.com/'+this.$route.path}) 
      ret.meta.push({name: 'og:url', href:'www.urecenter.com/'+this.$route.path}) 
      if (meta.image != '') 
      {
        ret.title = meta.title;
        ret.meta.push({property: 'og:image', content: meta.image});
      }
      // if (meta['og:image'] != '') ret.meta.push({property: 'og:image', content: meta['og:image']});
  
      return ret;
    },
    get_file_class(ext){
      var ret = '';
      switch (ext)
      {
        case 'doc':
        case 'docx': { ret = 'fas fa-file-word'; break;}

        case 'pdf': { ret = 'fas fa-file-pdf'; break;}
        
        case 'xls':
        case 'xlsx': { ret = 'fas fa-file-excel'; break;}
      }

      return ret;
    },
    

   }
  })




  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
  
  