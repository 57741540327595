<template>
        <section id="lecturers_area">
            <h2 class="strip_title nobg"><span v-html="title">המרצים שלנו</span></h2>

            <div class="items">
                <!-- <carousel :perPageCustom="[[300, 2], [1024, 4]]" paginationActiveColor="#30abb7" paginationColor="#000" :paginationEnabled="true" :mouse-drag="true" :centerMode="true" :loop="true" :navigationEnabled="true" :navigationNextLabel="'<div class=right></div>'" :navigationPrevLabel="'<div class=left></div>'"> -->
                   <div v-for="item in writers" :key="item.id" class="item" >
                    <div class="pic" :style="'background-image: url('+item.pic_parsed+')'" @click="scroll('lecturer'+item.id)"></div>
                    <div class="text">
                        <h3 >{{item.name}}</h3>
                        <label>{{item.title}}</label>
                    </div>
                   </div>
                 </div>
                <!-- </carousel> -->
      

    </section>
</template>
<script>
export default {
    props:["writers","title"],
  
}
</script>
<style scoped lang="scss">
#lecturers_area {  padding-top:0px; margin-top: 4.2rem;padding-bottom: 50px; position: relative; }
.items{display: flex;text-align: center;flex-wrap: wrap;;justify-content: left;}
.item{  flex-basis: 15%; /* explanation below */}
#lecturers_area .item { border-radius: 5px; position: relative; padding: 25px; min-height: 230px; text-align: center;background: transparent; box-shadow: none; }
#lecturers_area .item .pic { width: 95%; margin: 0 auto; background: center center no-repeat; background-size: cover; border-radius: 100%; border:6px solid #0098a0; margin-bottom: 15px !important; cursor: pointer;}
#lecturers_area .item .pic:after { content: ""; display: block; padding-bottom: 100%; }
#lecturers_area .item h3 { font-size: 16px; font-weight: bold; }
.strip_title{text-align: center;margin-bottom: 10px;}
#lecturers_area .owl-nav { position: absolute; left: 0px; top: -72px;}
#lecturers_area .owl-nav .owl-prev, #lecturers_area .owl-nav .owl-next { display: inline-block; margin-right: 8px; }
#lecturers_area .owl-nav .owl-arrow { background: none; border:2px solid #68c2c5; height: 26px; width: 26px; }
#lecturers_area .owl-nav .owl-arrow:after { content: ""; display: block; text-align: center; line-height: 26px; color: #68c2c5;}
#lecturers_area .item .text{
    label{font-size: 14px;
    display: block;
    line-height: 18px;
}
}
</style>