<template v-if="loaded">
    <div id="thanks">
        <div id="thanks_hero">
            <div class="centered_l">
                <h1>Book a time to speak with one of our directors of admissions</h1>
                <!-- <div id="man"></div> -->
            </div>
        </div>
        <div id="intro">
            <iframe  :src="url" style="height:800px;width:100%"/>
        </div>
    </div>
</template>
<script>

export default {
    data(){
        return{
            loaded:false,
            url:""
        }
    },
    methods:{
 
 
    },
    mounted(){
          this.net_api({ action: "calendly" }, (data) => {
           this.url = data.data.url;
            })
        this.loaded = true
    }
}
</script>
<style lang="scss" scoped>
    #thanks{text-align: center;}
    #logo_hero{position: absolute;width: 162px;height: 50px;background-size: cover;background-image: url(../assets/logo.png);top: 10px;left: 0px;right: 0;margin: 0 auto;}
    #thanks_hero{background: #353d4a;color: #fff;;text-align: center;padding: 10px 0 ; background-image: url(../assets/pic_calendly.jpg); background-size: cover; height: 150px;    background-position: center;}
    // #man{height: 200px;width: 100%;background-size: contain;background-image: url(../assets/man.png);background-position: center;margin: 20px 0;}
    #vid{width: 50%;margin: 0 auto;min-width: 400px;}
    h1{font-size: 40px;; text-align: center; line-height: 40px;}
    h2{font-weight: 400;font-size: 34px;margin-top: 50px;}
    h3{color: #30abb7;font-size: 35px;margin: 20px 0;font-weight: 800;}
       @media (max-width: 700px) {
       h1{font-size: 25px;margin: 10px 0;}
       h2{font-size: 20px;margin-top: 20px;}
       h3{font-size: 30px;margin-top: 20px;}
       #vid{width: 90%;margin: 0 auto;}
  }
    @media (max-width: 414px) {
        #thanks_hero{height:80px;}
       h1{width: 80%;text-align: center; line-height: 25px;font-size: 20px;margin: 0 auto;}
    }
</style>