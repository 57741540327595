<template v-if="loaded">
    <div id="app" :class="show_side_menu ? 'menu_open' : ''">
        <div id="header_mobile" v-if="is_mobile()">
            <a class="logo" href="/"></a>
            <div id="menu_hamburger" v-on:click="show_side_menu = !show_side_menu">
                <div id="nav-icon3">
                <span></span><span></span><span></span><span></span>
                </div>
            </div>
            <a id="mobile_logo" href="/"></a>
        </div>
        <div id="side_menu" v-if="is_mobile()">
            <ul class="sharing_list">
                <li
                class="menu"
                v-for="(item,i) in menu_homepage"
                :key="i"
                @click="
                    scroll(item.selector);
                    show_side_menu = false;
                "
                >
                <a v-text="item.name"></a>
                </li>
                <!-- <li><a href="/login">Log in</a></li> -->
                <li><a @click="$emit('pop_up_contact')">Signup</a></li>
            </ul>
        </div>
        <div id="about_page">
            <Header
            :link="false"
            @scrollTo="scroll($event)"
            :menu_homepage="menu_homepage"
            v-if="!is_mobile()"
            @sign="$emit('pop_up_contact')"
        />
        

        <div class="hero" v-for="(item,i) in about_info" :key="i">
            <div class="pic_hero" :style="'background-image:url('+item.pic_parsed+')'">
                <h1>{{item.title_hero}}</h1>
            </div>
        </div>

        <div class="section_one" v-for="(item,i) in about_info" :key="i">
            <div class="centered_l">
                <div class="info_text col-lg-6 col-md-6 col-xs-12">
                    <h2>{{item.section_1_title}}</h2>
                    <p v-html="item.section_1_text"></p>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12">
                    <div class="pic_section_one" :style="'background-image:url('+item.pic_parsed_section_1+')'"></div>
                </div>
                <div class="clr"></div>
            </div>
        </div>
    <div class="clr"></div>

        <div class="section_two" v-for="(item,i) in about_info" :key="i">
            <div class="centered_l">
                <div class="col-lg-6 col-md-6 col-xs-12">
                    <!-- <div class="pic_section_two" :style="'background-image:url('+item.pic_parsed_section_2+')'"></div> -->
                <!-- <video id="video1" width="660px" height="200px" ref="myid" autoplay muted loop playsinline>
                    <source src="https://admin.urecenter.com/themes/MNS/videos/newYork_new_mp4.mp4" type="video/mp4" />
                    <source src="https://admin.urecenter.com/themes/MNS/videos/newYork_new_mp4.mp4" type="video/webm" />
                    Your browser does not support HTML video.
                </video> -->
                <iframe width="660px" :src="$store.words.lp1_video" ></iframe> 
                
                </div>
                <div class="info_text col-lg-6 col-md-6 col-xs-12">
                    <h2>{{item.section_2_title}}</h2>
                    <p v-html="item.section_2_text"></p>
                </div>
                <div class="clr"></div>
            </div>
        </div>
    <div class="clr"></div>

<div class="team">
    <div class="centered_l">
        <h2 v-html="$store.words.team_title"></h2>
        <div id="team_wrapper">
            <div class="item" v-for="(item,i) in team" :key="i">
            <v-hover  :open-delay="210" >
            <v-card :class="'mx-auto ' " color="grey lighten-4" >
            <v-img :aspect-ratio="16/9"  class="pic" :style="'background-image:url('+item.pic_parsed+')'">
                <div  class="liner on_hover  white--text" style="height: 100%;" > 
                    <div class="content">
                        <p class="desc" v-html="item.desc_p"></p>
                        <a class="linkedin" v-if="item.linkedin" :href="item.linkedin" target="_blank"></a>
                    </div>
                </div>
            </v-img>
            <v-card-text class="wrap"  style="position: relative;" >
                <div class="dets">
                    <h3>{{item.name}}</h3>
                    <p>{{item.title}}</p>
                </div>

                </v-card-text>
                </v-card>
                </v-hover>
            </div>
        </div>
        <div class="clr"></div>
    </div>
</div>

<div class="logos">
    <div class="centered_l">
        <h2 v-html="$store.words.companies_title"></h2>
        <div class="wrapper_logos">
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6" v-for="(item,i) in comp_logos" :key="i">
                <div class="logo_pic" :style="'background-image:url('+item.pic_parsed+')'"></div>
            </div>
        </div>
        <div class="clr"></div>
    </div>
</div>

<div class="newsletter">
    <p>Sign up to our newsletter to get the most updated news and ideas here!</p>
    <form @submit.prevent="send()">
        <div class="wrapper_input">
            <input type="text" placeholder="Email" v-model="form.email">
            <input type="submit" value="SEND" class="btn">
        </div>
    </form>
</div>

<FooterHome/>

        </div>
    </div>
</template>

<script>
import Header from "../components/header.vue";
import FooterHome from "../components/main-home/footerHome.vue";
export default {
    components:{Header, FooterHome},
    props: ["menu_homepage"],
        data() {
            return {
                loaded: false,
                show_side_menu: false,
                form:{
                    email:'',
                },
                team:[],
                about_info:[],
                comp_logos:[],
            };
        },
        mounted() {
             
            this.net_api({ action: "team"}, (data) => {
                this.team = data.data.team;
            });

            this.net_api({ action: "aboutInfo"}, (data) => {
                this.about_info = data.data.about_info;
            });

            this.net_api({ action: "compLogos"}, (data) => {
                this.comp_logos = data.data.comp_logos;
            });
        },
        methods:{
            send(){
                var utm = null;
                var link = localStorage.getItem('link');
                if(JSON.parse(localStorage.getItem('utm')))
                {
                    utm = JSON.parse(localStorage.getItem('utm'))
                }
            
                if(this.form.email.length < 1 )
                {
                this.alert("Please Fill the form currectly")
                }
                else
                {
                    this.net_api({ action: 'contact',data:{form:this.form,utm:utm,link:link} }, function(data){ 
                        console.log(data)
                        window.location = "/thanks"
                    });
                }
            },
        },
}
</script>

<style lang="scss" scoped>
    iframe{height: 320px !important;border: none;}
    #about_page{padding-top:80px;
    #team_wrapper{display: grid;grid-template-columns: repeat(5, 1fr);gap: 30px;grid-auto-rows: 1fr;}
        .hero{
            .pic_hero{width:100%; height: 135px; background-position: center; background-size: cover; background-repeat: no-repeat;}
            h1{color:#fff; text-align: center; padding-top:38px; text-transform: capitalize; font-size: 40px;}
        }
        .section_one{ padding:40px 0; background:#f1f7ff;
            .info_text{padding-top:80px;
                h2{color:#3d3d3d; font-family: 'Poppins', sans-serif; text-transform: capitalize; margin-bottom:30px; font-size: 32px;}
                p{color:#4e5074; width:90%;}
            }
            .pic_section_one{width:100%; height: 500px; background-position: center; background-size: contain; background-repeat: no-repeat;}
        }
        .section_two{ padding:50px 0 10px 0;
            .info_text{padding-top:30px;
                h2{color:#3d3d3d; font-family: 'Poppins', sans-serif; text-transform: capitalize; margin-bottom:30px;font-size: 32px}
                p{color:#4e5074; width:90%;}
            }
            .pic_section_two{width:100%; height: 300px; background-position: center; background-size: cover; background-repeat: no-repeat;}
        }
        .team{ padding:40px 0; 
        // .col-md-3:nth-child(5n+1){clear: both;}
            h2{text-transform: capitalize; color:#3d3d3d; text-align: center; border-top:1px solid rgb(214, 213, 213); padding-top:50px; padding-bottom:20px; font-size: 32px;}
            .v-responsive__content p{ font-size: 14px!important; display: block; text-align: center; width: 80%;margin: 0 auto; line-height: 18px!important;position:absolute;  top:25px; left:0px; right:0px; margin:0 auto;}
            h3{font-size: 18px; font-weight: 700; color:#3d3d3d}
            .linkedin{background-image: url(../assets/linkedin.png);height: 25px;width: 30px;;background-position: center;background-repeat: no-repeat;background-size: contain;margin: 0 auto;position: absolute;left: 0 ;right: 0;bottom: 30px;
    background-color: #0a66c2; border-radius: 5px;}
            
            .pic{width:100%; height: 290px; position: relative; background-size: cover; background-position: center; background-repeat: no-repeat;}
            .item:hover .v-card__text{background:#eef1f5}
            .dets{text-align: center;}
            .v-responsive__content .liner[data-v-7dd1f4c4] {
                background: linear-gradient(
            90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 35%, rgba(0, 0, 0, 0.4) 100%);
            }
            .wrap{background:#fff}
            .trailer{text-align: center;font-size: 14px;color: #000;margin-top: 5px;padding-top: 5px;padding-bottom: 5px;margin-right: -16px;margin-left: -16px;position: relative;font-weight: 600;cursor: pointer;}
            .trailer::after{position: absolute;top: 0;right: 10px;left: 10px;height: 1px;background: #000;content: '';}
            .trailer:hover{background: #000;color: #fff;}
            .dets{text-align: center;width: 100%;padding-top: 10px;margin-bottom: 5px;min-height: 75px;}

            .v-card {margin-bottom: 20px;padding: 10px;margin: 10px;}
            .v-responsive__content{
                p{font-size: 14px;display: block;text-align: center;line-height: 30px;width: 90%;margin: 0 auto;line-height: 15px;}
                h4{font-size: 21px;display: block;text-align: center;line-height: 30px;padding-top: 30px;}
                h5{font-size: 15px;display: block;text-align: center;line-height: 30px;}
                .liner{background: linear-gradient(90deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 35%, rgba(0,0,0,0.4) 100%);}
            }
            .on_hover{opacity: 0;}
            .v-card:hover .on_hover{opacity: 1;font-size: 1px !important;
                animation-name: fadeInOpacity;
                animation-iteration-count: 1;
                animation-timing-function: ease-in;
                animation-duration: 0.5s;}
            .v-sheet.v-card{    border-radius: 0;padding: 0; box-shadow: none!important;}
            .v-application .grey.lighten-4 {background-color: #f6f9fd!important;}
            .v-card--reveal {  bottom: 0; justify-content: center; position: absolute; width: 100%;padding: 5px;}
            .content{height: 100%;width: 100%; }
            .wrap{padding-top: 0 !important;padding-bottom: 0;}
            .v-card{min-height: 320px;}
            @keyframes fadeInOpacity {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
        }
        .logos{ padding:40px 0px; background:#f1f7ff;
            h2{text-align: center; color:#3d3d3d; text-transform: capitalize; margin-bottom: 30px;font-size: 32px;}
            .wrapper_logos{background:#fff; width:100%; min-height:500px; border-radius: 30px; padding:20px 30px;margin-bottom: 35px;}
            .logo_pic{width:100%; height: 60px; background-position: center; background-size: contain; background-repeat: no-repeat; margin-top:20px;}
        }
        .newsletter{width:100%; min-height:100px; background-image: url(../assets/pic_building.jpg); background-position: initial; background-size: cover; background-repeat: no-repeat; background-blend-mode: overlay;padding:30px; display:flex; justify-content: center; align-items: center; background-color: #293845;
            p{color:#fff; margin-right:20px; font-size: 22px; margin-bottom:0px;}
            input{background:#fff; padding-left: 15px; margin-right:20px; border-radius: 5px; height: 40px; font-size: 16px; height: 34px; outline:none;}
            input::placeholder{line-height: 30px;}
            .btn{background:#1480dc; color:#fff; padding: 0px 36px; height: 34px; font-weight: 500;}
        }
        #footer .centered_h{border-top:none}


        @media(max-width:1148px){
            .newsletter{flex-direction: column;
                p{line-height: 25px; text-align: center; margin-bottom:20px}
                .wrapper_input{display: flex;margin: 0 auto;
                    input{margin-bottom:10px;}
                    .btn{width:50%;margin: 0 auto;}
                }
            }
        }
        @media(min-width:950px){
            .col-md-15:nth-child(6n+1){clear: both;}
        }
        @media(max-width:600px){
            .linkedin{bottom: 5px !important;}
                iframe{height: 210px !important;border: none;}

                #team_wrapper{grid-template-columns: repeat(2, 1fr);}

            padding-top:0px;
            .hero h1{font-size: 30px; line-height: 35px;}
            .hero h2{font-size: 30px; padding-top:40px; line-height: 35px;}
            .section_one .info_text{padding-top:0px;}
            .section_one .pic_section_one{height:295px;}
            .section_two .info_text h2{font-size: 25px;}
            .team .pic{height: 280px;}
            .item:hover .v-card__text{background:none}
            .team .col-xs-6:nth-child(2n+2){clear: both;}
            .team .v-card{min-height: 200px; width: 100%;}
            .logos .wrapper_logos{min-height: 895px;}
            .logos h2{font-size:30px;}
            .team h2{font-size: 30px;}
            .newsletter{flex-direction: column;
                p{line-height: 25px; text-align: center; font-size: 18px; margin-bottom:10px}
                .wrapper_input{display: flex;
                    input{margin-bottom:10px;}
                    .btn{width:50%;margin: 0 auto;}
                }
            }
        }
    }
</style>