<template>
    <div id="signup">
        <div class="centered-big">
            <div class="steps" v-if="!is_mobile()">
                <div :class="'step ' + (first_step ? 'active' : '')"><div class="num">1</div>Billing Information</div>
                <div :class="'step ' + (sec_step ? 'active' : '')"><div class="num">2</div>Payment option </div>
                <div :class="'step ' + (third_step ? 'active' : '')"><div class="num">3</div>ORDER COMPLETE</div>
            </div>
            <div class="inner ">
                <div class="col-md-6">
                <div class="box billing ">
                    <template >
                    <div class="title">Billing Information</div>
                    <form @submit.prevent="submit()">
                        <v-text-field label="Full Name" outlined v-model="form.name" :disabled="payment"></v-text-field>
                        <v-text-field label="Email Address" outlined v-model="form.email" :disabled="payment"></v-text-field>
                        <v-text-field label="Phone Number" outlined v-model="form.phone" :disabled="payment"></v-text-field>
                        <input type="submit" class="btn" value="Next" v-if="!payment"/>
                    </form>
                    </template>
                    <template v-if="payment">
                          <h3>Card information <div id="cards"></div></h3>
                    <Stripe :user_id="user_id" :username="form.email" :link="link"  @done="sec_step = false;payment =false;third_step = true;user = $event" :id_prod="prod_id"/>
                    </template>
                    <template v-if="third_step">
                       {{user}}
                        <div id="vote"></div>
                    </template>
                </div>
                </div>
                <div class=" col-md-6">
                <div class="box summery ">
                    <div class="title">ORDER SUMMARY</div>
                    <p>URBAN REAL ESTATE CENTER All Access Membership {{date}} Subscription  ${{price}} {{date}}. Billed automatically every month unless you cancel the subscription.</p>
                    <span class="left_float">Item Price</span><label class="right_float">USD {{price}} </label>
                    <div class="logo"></div>
                    <!-- <div class="logo_text">URBAN REAL ESTATE CENTER Membership </div> -->
                    <div class="line_devide"></div>
                     <span class="left_float">Tax/VAT</span><label class="right_float">USD 0 </label>
                     <div class="line_devide"></div>
                     <span class="left_float">Total</span><label class="right_float"> USD {{price}} </label>
                </div>
                </div>
                <div class="clr"></div>
            </div>
        </div>
        <div class="centered-big">
            <div class="col-md-6 col-xs-12">
                <div class="box_in first">
                    <h3>OUR AWARD-WINNING CUSTOMER SUPPORT IS HERE FOR YOU</h3>
                    <div class="col-md-7">
                        <div class="col-md-6 col-xs-6">
                            <div class="pic" :style="'background-image:url('+lectures.pic_parsed+')'"></div>
                        </div>
                        <div class="col-md-6 col-xs-6">
                            <div class="name">{{lectures.name}}</div>
                            <div class="job">{{lectures.job}}</div>
                            <div id="sign" :style="'background-image:url('+lectures.logo_parsed+')'"></div>
                        </div>
                        <div class="clr"></div>
                    </div>
                    <div class="col-md-5">
                        <div class="link">Write  us <a href="mailto:support@urecenter.com">Support Center</a></div>
                        <div class="link">Or call<a :href="'tel:'+lectures.phone"> {{lectures.phone}}</a>
                            (Monday-Friday, 9 AM - 5 PM pacific Time)
                        </div>
                    </div>
                    <div class="clr"></div>
                </div>
            </div>
            <div class="col-md-6 col-xs-12">
                <div id="credit"></div>
            </div>
            <div class="clr"></div>
            <div class="col-md-4">
                <div class="box_in">
                    <div class="box_title">refund / cancellation Policy</div>
                    <p>Feel Safe <br> 30 Days For A Full Refund</p>
                    <div class="icon icon_a"></div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box_in">
                    <div class="box_title">privacy & security</div>
                    <p>All Personal information you submit <br> is Encrypted and Secure.</p>
                    <div class="icon icon_b"></div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box_in">
                    <div class="box_title">legendary customer service</div>
                    <p>Award-winning support team</p>
                    <div class="icon icon_c"></div>
                </div>
            </div>
            <div class="clr"></div>
        </div>
    </div>
</template>
<script>
import Stripe from '../components/stripe.vue';
export default {
     components:{Stripe},
    data(){
        return{
            form:{
                name:'',
                email:'',
                phone:'',
                source_page:'lp1',
            },
            utm:'',
            link:'',
            date:'',
            prod_id:'',
            user_id:'',
            lectures:false,
            user:[],
            first_step:true,
            sec_step:false,
            third_step:false,
            payment:false,
            price:0,        }
    },
    mounted(){
        if(this.$route.path == "/signup/year")
        {
            this.price = '588.00';
             this.prod_id= 'prod_JyGR2D3z2gKhhv';
             this.date = 'Annualy'
             this.link = '/thank-you-yearly'
        }
        else if(this.$route.path == "/signup/month")
        {
            this.price = '99.00';
            this.prod_id= 'prod_JyGRaX5TW897K3';
            this.date = 'Monthly'
            this.link = '/thank-you-monthly'
        }
            var self = this
       this.net_api({ action: 'award' }, function(data){ 
                self.lectures = data.data.lecturers
      });
    },
    methods:{
        
           submit(){
               var utm = null
               var link = localStorage.getItem('link');
               if( JSON.parse(localStorage.getItem('utm')))
               {
                    utm = JSON.parse(localStorage.getItem('utm'))
               }
      // && this.validatePhone(this.form.phone)
            if(this.form.name.length > 1 && this.validateEmail(this.form.email)  && this.validatePhone(this.form.phone) )
            {
                var self = this
                this.net_api({ action: 'add_user' ,data:{form:this.form,utm:utm,link:link}}, function(data){ 
                        self.user_id = data.data
                  
                            self.payment = true
                            self.first_step = false
                            self.sec_step = true
                
                });
            }
            else{
              this.alert('please fill the form');
            }
    },
    },
}
</script>
<style lang='scss' scoped>
 h3{text-align: left;    font-size: 17px;margin-bottom: 10px;position: relative;}
  #cards{background-image: url(../assets/credit_cards.png);position: absolute;    right: 0;height: 41px;width: 137px;background-size: contain;top: 3px;}
.centered-big{max-width: 1100px;}
.first{
    h3{font-size: 14px;font-weight: 300;font-weight: 400;
    padding-left: 25px;
    color: #9a9a9a;}
    &{text-align: left !important;}
    .link{    font-size: 12px;margin: 10px 0;}
    .link a{color: #4d4dee;}
    .pic{height: 150px;width: 100%;background-size: cover;background-position: center;}
    .name{font-size: 16px;    font-weight: 700;}
    .job{    font-size: 13px;color: #858585;margin: 10px 0;}
    #sign{    height: 50px;width: 100%;background-size: contain;}
}
             .box_in{  box-shadow: 0px 6px 24px 4px rgba(0, 0, 0, 0.08); padding: 10px; border-radius: 1px; margin-bottom: 20px;height: 100%; flex-basis: 50%;background: #fff;;border: 1px solid #ccc;text-align: center;}
 .box_in{
    
     .box_title{font-weight: 700;text-transform: uppercase;}
     p{margin-bottom: 10px; color: #6f6b6b;font-size: 15px;}
     .icon{height: 40px;width: 100px;background-size: contain;background-repeat: no-repeat;margin: 10px auto;background-position: center;}
     .icon_a{background-image: url(../assets/icon_a.png);}
    .icon_b{background-image: url(../assets/icon_b.png);}
      .icon_c{background-image: url(../assets/icon_c.png);}
 }
  .col-md-4{.box_in{height: 160px;}}
#credit{height: 100px;width: 100%;background-position: center;background-size: contain;background-image: url(../assets/icons.png);}
#vote{background-image: url(../assets/vote.png);height: 150px;width: 200px;background-size: contain;}
#signup{
    &{background: #eeeeee;padding-top: 30px;min-height: 100vh;}
  .steps{
      &{display: flex; flex-wrap: wrap;align-content: stretch;justify-content: center;}
      .step{ background: #fff;
    color: #30abb7;
    margin: 0 12px;
    position: relative;
    height: 60px;
    font-size: 17px;
    width: 340px;
    padding-left: 70px;
    line-height: 60px;
    cursor: pointer;text-transform: uppercase;}
      .step:nth-child(2),.step:nth-child(3){padding-left: 70px;}
      .num{color: #30abb7;position: absolute;height: 30px;width: 30px;border-radius: 100%;border: 1px solid #30abb7;text-align: center;left: 20px;top: 15px;line-height: 29px;}
      .step:first-child::after{ content: '';position: absolute;right: -40px;top: 20px;width: 0;height: 0;border-left: 30px solid transparent; border-right: 30px solid transparent; border-top: 20px solid #fff; clear: both;transform: rotate(270deg);}
      .step:nth-child(3)::after{ content: '';position: absolute;left: -20px;top: 20px;width: 0;height: 0;border-left: 30px solid transparent; border-right: 30px solid transparent; border-top: 20px solid #eeeeee; clear: both;transform: rotate(270deg);}
      .step:nth-child(2)::after{ content: '';position: absolute;left: -20px;top: 20px;width: 0;height: 0;border-left: 30px solid transparent; border-right: 30px solid transparent; border-top: 20px solid #eeeeee; clear: both;transform: rotate(270deg);}
      .step:nth-child(2)::before{ content: '';position: absolute;right: -40px;top: 20px;width: 0;height: 0;border-left: 30px solid transparent; border-right: 30px solid transparent; border-top: 20px solid #fff; clear: both;transform: rotate(270deg);}
        .step:nth-child(2) .num,.step:nth-child(3) .num{left: 30px;}
      .step:hover,.step.active{
          &{background: #30abb7;color: #fff;}
          &:first-child::after{border-top-color: #30abb7;}
          &:nth-child(2)::before{border-top-color: #30abb7;}
          &:nth-child(3)::before{border-top-color: #30abb7;}
          .num{background: #fff;}
      }
  }  
  .inner{
             .box{ height: 450px; box-shadow: 0px 6px 24px 4px rgba(0, 0, 0, 0.08); padding: 25px; border-radius: 1px; margin-bottom: 20px; flex-basis: 50%;background: #fff;;border: 1px solid #ccc;text-align: center;}

      .line_devide{clear: both;width: 100%;height: 2px;background:#bbb8b8 ;margin: 10px 0;}
    //   .logo_text{width: 30%;;display: inline-block;float: right;}
      .logo{clear: both;height: 100px;width: 100%;background-image: url(../assets/logo_pay.jpg);display: inline-block;margin: 20px 0;background-position: center; }
      .left_float{float: left;padding-left: 20px;}
      .right_float{float: right;padding-right: 20px;}
      .title{    font-size: 20px !important; color: #a8a5a5; text-align: initial;text-transform: none;    font-weight: 500;margin-bottom: 0;}
      p{ font-size: 14px;text-align: left;color: #a8a5a5;margin-bottom: 10px;}
       &{padding-top:30px ;}
       .btn{background: #30abb7;color: #fff;font-size: 20px;margin: 0px auto 10px;font-weight: 600;padding: 0 70px;height: 45px;}

  }
  
}
</style>