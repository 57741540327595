<template v-if="loaded">
<div id="app" :class="show_side_menu ? 'menu_open' : ''">
        <div id="header_mobile" v-if="is_mobile()">
            <a class="logo" href="/"></a>
            <div id="menu_hamburger" v-on:click="show_side_menu = !show_side_menu">
                <div id="nav-icon3">
                <span></span><span></span><span></span><span></span>
                </div>
            </div>
            <a id="mobile_logo" href="/"></a>
        </div>
        <div id="side_menu" v-if="is_mobile()">
            <ul class="sharing_list">
                <li  class="menu"  v-for="item in menu_homepage"  :key="item.id"  @click="  scroll(item.selector);  show_side_menu = false; ">
                <a v-text="item.name"></a>
                </li>
                <li><a @signup="scroll('contact_pop')">Signup</a></li>
            </ul>
        </div>
        <div id="blog">
            <Header
            @scrollTo="scroll($event)"
            :menu_homepage="menu_homepage"
            v-if="!is_mobile()"
            @sign="$emit('pop_up_contact')"
        />
         <div v-if="loaded" style="padding-top:80px">
            <div v-for="item in items" :key="item.id" class="lecturer_item">
                <div class="centered-big">
                    <div class="item_wrapper">
                        <div class="item_data">
                            <div class="job" v-text="item.job"></div>
                            <div class="title_item" v-html="item.title"></div>
                            <div class="description" v-html="item.description"></div>
                            <a class="btn" href="/lp1">Learn MORE</a>
                      </div>
                       <div class="video" v-if="!item.recomandation">
                          <Video :video="item" />
                            <div class="name_job">
                                <div class="div1">
                                    <div class="name" v-text="item.name"></div>
                                    <div class="name_description" v-text="item.name_description"></div>
                                </div>
                                <div class="div2">
                                   <div class="logo" :style="'background-image:url('+item.logo_parsed+')'"></div>
                                </div>
                            </div>
                      </div>
                      <div class="video" v-else id="recomandations">
                          <div class="recomandation" v-html="item.recomandation"></div>
                            <div class="name_job">
                                 <div class="div2" style=" grid-area: 1 / 1 / 2 / 2;">
                                   <div class="pic" :style="'background-image:url('+item.pic_parsed+')'"></div>
                                </div>
                                <div class="div1" style="grid-area: 1 / 2 / 3 / 3;">
                                    <div class="name" v-text="item.name"></div>
                                    <div class="name_description" v-text="item.name_description"></div>
                                </div>
                               
                            </div>
                            <div class="logo" :style="'background-image:url('+item.logo_parsed+')'"></div>
                      </div>
                    </div>
          </div>
          </div>   

    </div>



    <FooterHome/>

    </div>
</div>
</template>

<script>
import Header from "../components/header.vue";
import Video from "../components/lecturers_widget_video.vue";
import FooterHome from "../components/main-home/footerHome.vue";
export default {
    components:{Header, FooterHome,Video},
    props: ["menu_homepage"],
    data(){
        return{
            loaded: false,
            items: [],
          
        }
    },
    mounted() {
        this.net_api({ action: "reviews" }, (data) => {
            this.items = data.data.items;
            this.loaded = true;
           
           
            })
    }

}
</script>
<style lang="scss" scoped>
.VueCarousel-inner{padding: 50px;}
.item_wrapper{display: flex;}
#lecturerd_widget{margin-top: 20px;padding-bottom: 40px;
.title{color: #4e5074;font-weight: 700;margin-bottom: 20px;font-size: 35px !important;}
}

#tabs{
    &{display: flex;flex-wrap: wrap;justify-content: space-between;margin-bottom: 40px;}
    .tab{ text-align: center;color: #4e5074;border-bottom: 4px solid transparent;padding-bottom: 5px;transition: all 0.4 ;text-decoration: none;    transition-duration: 0.3s;}
    .tab.active_item{color: #1480dc;font-weight: 700;border-bottom: 4px solid #1480dc;;border-radius: 3px;}
    .tab:hover{color: #1480dc;font-weight: 700;border-bottom: 4px solid #1480dc;;border-radius: 3px;}
}
.name_job{
    &{display: grid;grid-template-columns: 2fr 2fr;grid-template-rows: repeat(2, 1fr);grid-column-gap: 20px;grid-row-gap: 0px;margin-top: 20px;}
    .div1 { grid-area: 1 / 1 / 3 / 2; }
    .div2 { grid-area: 1 / 2 / 2 / 3; }
    .name{font-size: 18px;font-weight: 700;}
    .name_description{font-size: 16px;}
}
.lecturer_item{
    .btn{color: #fff;text-transform: uppercase;font-weight: 600;margin-top: 30px;}
    .btn:hover{color: #fff !important }
    &{column-gap: 20px;width: 100%;background-size: cover;min-height:500px;background-image: url(../assets/blue.jpg);padding: 50px 100px 20px;}
    .title_item{    font-family: "Poppins";font-weight: 700;color: #000000; font-size: 30px; width: 80%;}
    .video{flex: 2;}
    .item_data{flex: 3;margin: 0 45px;padding: 20px 0;
    .content{color: #000;font-size: 17px;margin-bottom: 15px;}
    .name{color: #2e2e2e; font-size: 18px;font-weight: 800;line-height: 1;}
    .job{    text-transform: uppercase;font-weight: 600;color: #1480dc;font-size: 20px;}
    .description{ font-size: 18px;margin-top: 10px;}
    }
}
.recomandation{padding: 20px 20px 50px;position: relative;border: 2px solid #fff;}
.recomandation::after{position: absolute;content: ',,';left: -6px;letter-spacing: -8px;top: -31px;font-size: 85px;color: #fff;height: 50px; width: 50px; background: #cee1f2; line-height: 0; font-family: 'Circular-Loom';}
.recomandation::before{position: absolute;content: ',,';right: -6px;letter-spacing: -8px;bottom: -10px;font-size: 85px;color: #fff;height: 50px; width: 50px; background: #cee1f2; line-height: 0; font-family: 'Circular-Loom';}
    .logo{    height: 70px; background-size: contain; background-repeat: no-repeat;margin-bottom: 30px;background-position: right;}

.pic{height: 82px;background-size: contain;background-position: center; width: 160px;margin-right: 30px; height: 50px; margin-top:15px;}
#recomandations{
    .pic{background-size: contain;background-position: center;width: 110px;height: 110px;border-radius: 100%;;border: 2px solid #fff;position: absolute;top: -51px;left: -8px;}
    .name_job{display: block;position: relative;}
    .div1{padding-left: 120px; margin-top: -15px;margin-bottom: 15px;}
    .logo{background-position: left!important;margin-top: 40px;}
}
@media(max-width:900px) {
    .recomandation::before, .recomandation::after{display: none;}
  .item_wrapper{margin: 0 10px;    flex-direction: column;}
.lecturer_item .item_data{margin: 0;padding: 0;margin-bottom: 10px;}
  #tabs .tab{ flex-basis: 50%;margin-bottom: 10px;}
  .lecturer_item{flex-direction: column;justify-content: center;text-align: center;margin-bottom: 10px;padding-top: 0px;padding: 10px;text-align: center;}
  .lecturer_item .title_item{width: 100%;font-size: 24px;}
  .lecturer_item .item_data .logo{background-position: center;}
}
</style>