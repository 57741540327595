<template v-if="loaded">
  <div id="estate">
    <div class="centered_j">
      <div class="pic_col col-md-6 col-sm-6 col-xs-12">
        <div class="pic_estate"></div>
      </div>

      <div class="text_e col-md-6 col-sm-6 col-xs-12">
        <h2 v-html="title"></h2>
        <div class="line_j"></div>
        <p id="text" v-html="text"></p>

        <button class="btn sign" @click="$emit('scrollTo','contact2')" >Get Free Brochure</button>
  
      </div>
      <div class="clr"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title","text"],
  data(){
    return{
      loaded: false,
    }
  },

};
</script>

<style lang="scss" scoped>
#estate {
  padding: 55px 0px 55px 0px;
  .centered_j {
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 1300px;
  }
  .pic_estate {
    background-image: url(../assets/pic_estate.jpg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 430px;
  }
  .text_e {margin-top:25px;
    h2 {
      color: #4e5074;
      font-size: 30px;
      line-height: 32px;
      margin-bottom:40px;
    }
    #text {
      color: #4e5074;
      width: 95%;
      font-size: 20px;
      font-weight: 400;
      line-height: 25px;
      font-family: "Nunito", sans-serif;
    }
    .sign {
      background: #1480dc;
      color: #fff;
      border-radius: 4px;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 600;
      line-height: 22px;
      margin-top: 5px;
      font-family: "Nunito", sans-serif;
      height: 40px;;
    }
  }
  @media only screen and (max-width: 900px) {
    .text_e {
      h2 {
        font-size: 25px;
      }
    }
  }
  @media only screen and (max-width: 414px) {
    padding: 0 0 20px 0;
    .text_e {
      margin: 20px auto;
      text-align: center;
      h2 {
        font-size: 25px;
        line-height: 30px;
        width: 95%;
        padding-bottom: 15px;
      }
      #text {
        width: 90%;
        margin: 0 auto;
      }
    }
    .pic_estate{height: 325px;}
  }
  @media only screen and (max-width: 360px) {
    padding: 20px 0 20px 0;
    .pic_estate {
      height: 260px;
    }
    h2 {
      font-size: 20px !important;
      line-height: 10px;
    }
  }
}
</style>
