import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    article:[],
    reviews_instructors:[],
    buttons_company:[],
    buttons_programs:[],
    instructors_page:[],
    estate_school:[],
    resourse:[],
    upcoming_courses: [],
    syllabus: [],
    logos_comps:[],
    featured_logos:[],
    reviews: [],
    words: [],
    price: null,
    reviews_title: null,
    banner: null,
    courses_text: null,
    courses_title: null,
    lecturers_title: null,
    lecturers_subtitle: null,
    list_subtitle: null,
    list_title: null,
    mobile_list: null,
    mobile_title: null,
    mobile_subtitle: null,
    contact: null,
    kalendly: null,
    join_text: null,
    utm: null,
    thanks_hero_title: null,
    estate_title: null,
    estate_title_text: null,
    event_date:null,
    event_time: null,
    event_time_info: null,
    event_location: null,
    event_location_info: null,
    event_meeting: null,
    event_meeting_info: null,
    students_working_title: null,
    info_thanks_title_fir: null,
    info_thanks_title_sec: null,
    info_thanks_title_thr: null,
    info_thanks_title_fou: null,
    info_thanks_p_fir: null,
    info_thanks_p_sec: null,
    info_thanks_p_thr: null,
    info_thanks_p_fou: null,
    timer: null,
    check_hero:null,
  },
  mutations: {},
  actions: {},
  modules: {},
});
