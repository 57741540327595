<template>
    <div id="events">
        <div class="centered_l">
            <h2>from our last events</h2>
            <div class="event_pic"></div>
        </div>
    </div>
</template>

<script>
  export default {   
    data() {
    return {

    }
  },
}
</script>

<style scoped lang="scss">
#events{background:#fff; padding:20px 0;
    h2{text-transform: capitalize; margin:0 auto; text-align: center;color:#595b7d; padding-bottom:40px;font-family: 'Poppins', sans-serif; font-size: 36px;;}
    .event_pic{background-image:url(../../assets/events.jpg); background-position: center; background-size: contain; background-repeat: no-repeat; width:100%; height: 500px;}
}
@media(max-width:600px){
  #events h2{padding-bottom:0px;}
  #events .event_pic{height:375px}
}
@media(max-width:414px){
  #events h2{font-size: 25px; padding-bottom:15px;}
  #events .event_pic{height:185px}
}
</style>
