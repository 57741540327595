<template v-if="loaded">
  <div id="hero">
    <div class="centered_h">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h1>Download Your Brochure And Schedule A Call With Us!</h1>
        <div id="form_hero">
          <v-text-field  label="Your Email Address *"  v-model="email"  outlined  v-if="!lp"></v-text-field>
          <div style="text-align:center">
            <a :href="item.pic_parsed" downland="urecenter" target="_blank">
              <v-btn depressed @click="$emit('signup', email)"  class="btn"> DOWNLOAD THE BROCHURE </v-btn>
            </a>
            <h3 v-html="$store.words.thanks_text_under_btn"></h3>
          </div>
        </div>
      </div>
     
    </div>
             <iframe  :src="url" style="height:800px;width:100%;border:none"/>
        <!-- <iframe :src="$store.kalendly" style="height:800px;width:100%;border:none"/> -->
  </div>
</template>
<script>
export default {
  props: ["thanks_hero_title", "hero_description", "lp", "hero_thanks", "syllabus"],
  data() {
    return {
      url:'',
      item:{},
      loaded: false,
    };
  },
  mounted(){
      this.net_api({ action: "thanks",data:{id:(this.$route.path == '/thanks_multifamilylp1' ? 10 : (this.$route.path == '/thanks_Realestate_finance' ? 11 : 9))} }, (data) => {
        this.item = data.data
        this.loaded = true;
      });
        this.net_api({ action: "calendly" }, (data) => {
           this.url = data.data.url;
            })
  },

 
};
</script>
<style lang="scss" scoped>
#hero {
  padding: 30px 0 50px 0 !important;
 .v-btn__content a{font-size: 14px;}
  .v-btn{background:#1480dc!important; }

  & {
    margin-top: 45px!important;
    padding: 30px 0;
  }
 
  & {
    background: #f6f9fd;
    color: #000;
    font-family: sans-serif;
    position: relative;
    margin-top: 80px;
  }
 
  h1 {
    text-align: center;
    font-weight: 700;
    font-size: 35px;
    color: #4e5074;
    line-height: 40px !important;
    padding-top: 5px;
    padding-bottom: 20px;
  }
  h3{ color: #4e5074;margin: 10px 0;}
  p:nth-child(1) {
    font-weight: 600;
    font-size: 18px;
  }
  p:nth-child(2) {
    font-size: 18px;
  }
  p {
    width: 85%;
    color: #4e5074;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    font-family: "Nunito", sans-serif;
  }

  .v-btn {
    background: #1cd2ad;
    color: #fff;
    padding: 0px 40px;
    margin-top: 14px;
    font-size: 15px;
    font-weight: 600;
    height: 47px;
    border-radius: 4px !important;
    padding: 0 70px;
    margin-bottom: 0;
    margin-bottom: 30px; font-family: "Nunito", sans-serif;
    margin: 0 auto;
  }

  .v-btn--disabled {
    background: #1cd2ad !important;
    color: #fff !important;
  }


  .v-btn__content{
  a{
    color: #ffff;
    text-decoration: none;}
  }
}

@media only screen and (max-width: 1350px) {
  
  #hero h1 {
    font-size: 24px;
  }
  #hero p {
    font-size: 16px;
    margin-bottom: 0;
  }


}

@media only screen and (max-width: 600px) {
  #hero {margin-top:0px!important;
    h1 {
      width: 100%;
      padding-top: 0px;
    }
    p {
      width: 100%;
      text-align: center;

  }
  }
}

</style>
