<template v-if="loaded">
    <div id="contact" :class="(active_fix ? 'fix' : '')">
        <div class="con_title title" v-if="!is_mobile()">Send Your Details to sign up & join the free live webinar</div>
        <div class="title" v-if="is_mobile()" >Send Your Details to sign up & join the free live webinar</div>
        <div id="contact3" ref="myform" style="padding-top:40px; ;"></div>
        <div id="approve_text">By sharing your email,you agree to our <a target="_blank" href="/terms">Privacy Policy</a> and <a target="_blank" href="/terms">Terms of Service</a></div>
       
    </div>
</template>
<script>

export default {
    props:["lp" , "source_page"],
    components:{},
    data(){
        return{
            loaded: false,
            screen:window.screen.height,
            user_height:window.scrollY,
            dialog:false,
            active_fix:false,
            form:{
                name:'',
                email:'',
                phone:'',
                company:'',
                accept_adv:1,
                source_page:''
            }
        }
    },
    methods:{
        send(){
           
                var utm = null;
                var link = localStorage.getItem('link');
               if(JSON.parse(localStorage.getItem('utm')))
               {
                    utm = JSON.parse(localStorage.getItem('utm'))
               }
        
            if(this.form.name.length < 1 || this.form.email.length < 1  || this.isNull(this.form.phone) )
            // if(this.form.name.length > 1 && this.validateEmail(this.form.email) && this.validatePhone(this.form.phone))
            {
                this.alert("Please Fill the form correctly")
            }
           else
            {
                this.form.source_page = this.source_page
                this.net_api({ action: 'contact',data:{form:this.form,utm:utm,link:link,lp:this.lp} }, (data) =>{ 
                   console.log(data)
                    if(this.source_page == 'Finance') 
                    {
                        window.location = "/thanks_Realestate_finance"
                    }
                    else if(this.source_page == 'multi-family'){
                          window.location = "/thanks_multifamilylp1"
                    }
                    else{
                          window.location = "/thanks"
                    }
               });
            }
        },
        handleScroll(){
                     return (window.scrollY > this.screen ? this.active_fix = true : this.active_fix = false)  
        }
    },
    mounted(){
        let foo = document.createElement('script');    
  foo.setAttribute("src","https://ams.urecenter.com/form/generate.js?id=11");
  setTimeout( ()=> {
  document.getElementById("contact3").appendChild(foo);

  },500);
        // window.addEventListener('scroll', this.handleScroll);
        // console.log(window)
        // console.log(window.scrollY)
    },
      destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>
<style lang='scss' scoped>
.con_title{font-weight: 500!important;}
#check{color: #fff !important;width:fit-content;margin: 0 auto;}
#contact{ background:#1e3c5a;min-height: 175px;width: 100%;text-align: center;color: #17234f !important;padding-top: 30px; padding-bottom:40px; /*background-image:url(../assets/wave.png);*/ background-position: center; background-size: cover; background-repeat: no-repeat; background-blend-mode: multiply;}
.fix{position: fixed;bottom: 0;}
.title{color:#fff; font-family: 'Poppins', sans-serif; font-weight: 700!important;}
form{display: flex;width: 85%;margin: 5px auto;}
.input{flex-basis: 20%;margin: 0 10px !important;height: 50px !important; border-radius: 6px;}
.btn{background: #1480dc;color: #fff;flex-basis: 17%;flex-grow: 1;border-radius: 3px;height: 48px;font-weight: 600;font-size: 15px; border-radius: 6px; line-height: 52px; margin-left: 12px;width: 200px;margin-top: 6px}
#contact .title{ font-size: 25px !important; text-transform: inherit;margin-bottom: 0;}


@media only screen and (max-width: 700px) {
#contact .title{font-size: 20px !important;width: 90%;margin:  0 auto;display: block;}
#contact{min-height: 270px;position: relative;}
form{flex-direction: row;width: 95%;
.btn{max-width: 200px; margin-top: 6px!important;margin-left: 10px; height: 48px;}
}
.input{flex-basis: 50%;
    margin: 3px 0 !important;
    padding: 0px 10px !important;
    max-width: 50%;}
    #check{position: absolute;bottom: -10px;}
}
#inter {padding: 6px 10px;}
.v-input .v-label {line-height: 16px!important;}

@media only screen and (max-width: 1400px) and (max-width: 1300px){
    form{width:85%}
}
@media only screen and (max-width: 1200px){
    form{width:90%}
}
@media only screen and (max-width: 792px) and (min-width:600px){
   #inter{ margin-top: 13px;}
   .btn{margin-right:10px!important}
}
@media only screen and (max-width: 900px){
    form{width:95%}
}
@media only screen and (max-width: 700px){
    #inter{margin-top:0px;}
    form .btn{margin-top:15px!important}
}
@media only screen and (max-width: 793px) and (min-width:415px){
    form{align-items:center;flex-wrap: wrap; justify-content: center}
    
    #contact{padding-bottom:10px}
    .input{max-width: 100%;}
    .btn{font-size: 17px; line-height: 45px;margin-top:20px;}

}
@media only screen and (max-width: 414px){
    form{align-items:center;flex-wrap: wrap; justify-content: center}
    
    #contact{padding-bottom:10px}
    .input{max-width: 100%; flex-basis:100%}
    .btn{font-size: 17px; line-height: 45px;margin-top:20px;}
    #inter{margin-top:3px; width:100%!important}
}

@media only screen and (max-width: 405px){
    .vue-phone-number-input .select-country-container{min-width: 112px;;}
}

@media only screen and (max-width: 375px){
  #inter{width: 100% !important;}
}

@media only screen and (max-width: 360px){
    #contact{padding-bottom:10px}
    .input{max-width: 100%;}
    .btn{font-size: 17px; line-height: 45px;}
}


</style>
