<template>
    <div id="about_us" class="centered_j">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="pic_about"></div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <h2 v-html="$store.words.about_title"></h2>
            <p v-html="$store.words.homepage_about_text"></p>
            <div class="btn " @click="$emit('sign')" v-html="$store.words.hp_btn_about">SIGN UP</div>

        </div>
        <div class="clr"></div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
#about_us{padding:20px 0px; 
.btn{color: #fff;font-weight: 500;}
    .pic_about{width: 100%; height: 585px; background-image:url(../../assets/about_pic.png); background-size: contain; background-position: center; background-repeat: no-repeat;}
    h2{color:#4e5074; padding-bottom:30px; line-height: 45px; width:75%;font-family: 'Poppins', sans-serif; font-size: 36px;}
    p{color:#4e5074; width:70%}
}
@media(max-width:1275px){
    #about_us h1{width:90%}
    #about_us p{width:90%}
}
@media(max-width:1200px){
    #about_us p{width:90%}
}
@media(max-width:600px){
    #about_us {text-align: center; width:100%; margin: 0 auto;
        h1{width:100%}
        p{width:100%}
    }
}
@media(max-width:414px){
    #about_us{padding-bottom:0px}
    #about_us .pic_about{height: 340px;}
    #about_us h1{text-align: center; padding-bottom:15px; font-size: 25px; line-height: 35px;}
    #about_us h2{width:100%; font-size: 25px; line-height: 30px;}
    #about_us p{text-align: center; width:100%}
}
</style>