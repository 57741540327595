import { render, staticRenderFns } from "./thanksFull.vue?vue&type=template&id=97cf56e6&scoped=true&v-if=loaded&"
import script from "./thanksFull.vue?vue&type=script&lang=js&"
export * from "./thanksFull.vue?vue&type=script&lang=js&"
import style0 from "./thanksFull.vue?vue&type=style&index=0&id=97cf56e6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97cf56e6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VSnackbar})
