<template>
    <form id="payment-form" @submit.prevent="submit">
      <div id="card-element"><!--Stripe.js injects the Card Element--></div>
      <button id="submit">
        <div class="spinner hidden" id="spinner"></div>
        <span id="button-text">PLACE SECURE ORDER</span>
      </button>
      <p id="card-error" role="alert"></p>
      <!-- <p class="result-message hidden"> -->
        <thanks v-if="thanks"/>
      <!-- </p> -->
    </form>
</template>
<script>
import thanks from './thanks.vue'
export default {
  components:{
    thanks
  },
  props:["user_id","id_prod","username",'link'],
  data(){
    return {
      stripe: null,
      card: null,
      data: null,
      thanks: false,
 
    };
  },
  mounted(){
    this.stripe = window.Stripe("pk_live_51J09Y2GmQUpFkAFUT2CFzyY7DGX2AUDCpXLyygZ6WYXMcNiPWNM5GB3oob7nG1chGodxoGcy8urBY9o4ugy1EBJ600nB9LHhdy");
    // this.stripe = window.Stripe("pk_test_51ImaYmGkG8BgSLUDKI8OY1axjb3LHgDPXI42mJhJtzGGbt35UFbaskkN1lZL3uTbXHw9tQj9qpsWtQhRJr7p166V00DevJGfU8");
// The items the customer wants to buy
var purchase = {
  items: [{id:this.id_prod},{username:this.username}] 
};
// this.alert(this.id_prod)
// Disable the button until we have Stripe set up on the page
document.querySelector("button").disabled = true;

fetch("https://admin.urecenter.com/api/pay", { 
  method: "POST", 
  headers: {
    "Content-Type": "application/json"
  },
  body: JSON.stringify(purchase)
})
  .then(function(result) {
    return result.json();
  })
  .then((data) => {
    this.data = data.data;

    let elements = this.stripe.elements();
    let style = {
      base: { 
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };
    this.card = elements.create("card", { style: style });
    // Stripe injects an iframe into the DOM
    this.card.mount("#card-element");
    this.card.on("change", function (event) {
      // Disable the Pay button if there are no card details in the Element
      document.querySelector("button").disabled = event.empty;
      document.querySelector("#card-error").textContent = event.error ? event.error.message : "";
    });

  });
// Calls stripe.confirmCardPayment
// If the card requires authentication Stripe shows a pop-up modal to
// prompt the user to enter authentication details without leaving your page.
  },
methods:{

  submit() {
      // Complete payment when the submit button is clicked
      // alert(this.data.clientSecret)
      this.payWithCard(this.stripe, this.card, this.data.clientSecret);
    },

payWithCard(stripe, card, clientSecret) {
  this.loading(true);
  stripe
    .confirmCardPayment(clientSecret, {
      payment_method: {
        card: card
      }
    })
    .then((result) => {
      if (result.error) {
        // Show error to your customer
        this.showError(result.error.message);
      } else {
        // The payment succeeded!
        this.orderComplete(result.paymentIntent.id);
      }
    });
},
 orderComplete(paymentIntentId){
  this.loading(false);
    // this.thanks = true
console.log(paymentIntentId)
  // document
    // .querySelector(".result-message a")
    // .setAttribute(
    //   "href",
    //   "https://dashboard.stripe.com/test/payments/" + paymentIntentId
    // );
  // document.querySelector(".result-message").classList.remove("hidden");
  document.querySelector("button").disabled = true;

   var self = this
       this.net_api({ action: 'confirm_pay' ,data:{id:this.user_id,stripe:this.data.id}}, function(data){ 
                self.$emit('done', data.data.user);
                // console.log(self.data);
                 document.location = self.link
      });
     
},
 showError(errorMsgText) {
  this.loading(false);
  var errorMsg = document.querySelector("#card-error");
  errorMsg.textContent = errorMsgText;
  setTimeout(function() {
    errorMsg.textContent = "";
  }, 4000);
},
 loading(isLoading) {
  if (isLoading) {
    // Disable the button and show a spinner
    document.querySelector("button").disabled = true;
    document.querySelector("#spinner").classList.remove("hidden");
    document.querySelector("#button-text").classList.add("hidden");
  } else {
    document.querySelector("button").disabled = false;
    document.querySelector("#spinner").classList.add("hidden");
    document.querySelector("#button-text").classList.remove("hidden");
  }
}
}
 
}
</script>

<style lang="scss" scoped>
/* Variables */
* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

// form {
//   width: 30vw;
//   min-width: 500px;
//   align-self: center;
//   box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
//     0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
//   border-radius: 7px;
//   padding: 40px;
// }

input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
}

.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}
#submit{margin-top: 10px;font-weight: 700;font-family: "Nunito", sans-serif !important;}
#card-error {
  color: rgb(105, 115, 134);
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}

#card-element {
  border-radius: 4px 4px 0 0 ;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  width: 100%;
  background: white;
}

#payment-request-button {
  margin-bottom: 32px;
}

/* Buttons and links */
button {
  background: #5469d4;
  color: #ffffff;
  font-family: Arial, sans-serif;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
  }
}


</style>