<template v-if="loaded">
    <div id="header">
      <div class="centered_header">
            <div id="logo" @click="goto_home()"></div>
            <div class="menu">
                <ul @click="(blank ? goto_home() : '')">
                    <li v-for="item in menu" :key="item.id" v-text="item.name" @click="$emit('scrollTo',item.selector)"></li> 
                   <template v-if="!link">
                       <template v-for="item in menu_homepage">
                          <li :key="item.id" v-if="item.link">
                            <a :href="item.url" v-text="item.name"></a>  
                          </li> 
                          <li :key="item.id" v-text="item.name" @click="$emit('scrollTo',item.selector)" v-else></li> 
                      </template>
                    </template>
                    <template v-else>
                      <a href="/lp1"><li v-for="item in menu_homepage" :key="item.id" v-text="item.name" href="lp1"></li></a>
                    </template>
                    <li v-for="item in menu_thanksleadgen" :key="item.id" v-text="item.name" @click="$emit('scrollTo',item.selector)"></li> 
                    <li v-for="item in menu_instructors" :key="item.id" v-text="item.name" @click="$emit('scrollTo',item.selector)"></li> 
                   </ul>
            </div>
          </div>
            <div class="btn_wrapper" v-if="!dont_show_btn">
              <div class="btn" @click="$emit('sign')" v-if="homepage" v-html="$store.words.signup_btn">SIGN UP</div>
              <!-- <div class="btn" @click="$emit('sign')" v-else> GET STARTED</div> -->
              <div class="btn" @click="$emit('sign')" v-else><span class="circle"><font-awesome-icon icon="fa-solid fa-play" /></span> GET STARTED</div>
              <!-- <router-link to="/login" target='_blank'><div class="btn">LOG IN</div></router-link> -->
            </div>
        </div>
</template>
<script>
export default {
  props:["menu", "menu_homepage", "menu_thanksleadgen", "menu_instructors","link", "homepage","blank","dont_show_btn"],
     data () {
      return {
        type:'selector',
        loaded: false,

      }},
      methods:{
        goto_home(){
          document.location = '/'
        }
      },
      computed: {
      

      
}
}
</script>
<style lang="scss" scoped>
a{color: #fff !important;}
.circle{background: #55a9ee;border-radius: 100%;text-align: center;line-height: 2.1;font-size: 10px;height: 20px;width: 20px;vertical-align: middle;display: inline-block;}
// #login{background-color: #30abb7;color: #fff;font-size: 30px;padding: 0px 20px;position: absolute;width: fit-content;right: 0;top: 20px;font-weight: 600;border-radius: 4px 0 0 4px;}
    #header{background: #242424;color: #fff;font-family: sans-serif;position: fixed;top: 0;width: 100%;;height: 80px;z-index: 9999;}
    #logo{position: absolute;width: 140px;height: 50px;background-size: contain; background-image: url(../assets/logoWhiteNew.png);top: 15px;left: 0px; background-repeat: no-repeat; margin-left: 10px;}
    // #logo{position: absolute;width: 140px;height: 50px;;top: 15px;left: 0px;  margin-left: 10px; -webkit-mask-image: url(../assets/svg_logo.svg);-webkit-mask-size: contain;-webkit-mask-position: center;-webkit-mask-repeat: no-repeat;}
.btn_wrapper{display: inline-block;float: right; position:absolute; right: 50px; top:25px; padding-right:50px;}
#header .btn{margin: 0 10px;background: #1480dc;color: #fff; border-radius: 4px; font-family: "Nunito", sans-serif; font-size: 15px; }
.btn:hover{background:#34465c!important}
.btn:last-child{background: #1480dc; font-size: 14px; font-weight: 600;}
.btn:first-child{background: #1480dc; font-size: 14px; font-weight: 600;}
.menu{ margin-left: 250px;display: inline-block; text-transform: uppercase; position:absolute; left:0px;}
ul{padding-top: 30px;}
li{display: inline-block; font-size: 15px; font-weight: 700; padding: 0 30px;cursor: pointer;}
li:hover{color: #1480dc;}

@media(max-width: 1746px) and (min-width:1275px){
  .menu {right: 230px;}
}
@media only screen and (max-width: 1250px) {
li{padding: 0 15px;}
}
@media only screen and (max-width: 1070px) {
li{padding: 0 10px;}
.menu{margin-left: 190px;}
}
.centered_header{position:relative;width: 100%;margin:0 auto;max-width: 1400px;}

@media only screen and (max-width: 900px){
  #logo[data-v-29e8c3c6] {margin-left: 0px;}
  ul {padding-left: 10px;}
  li[data-v-29e8c3c6] {font-size: 14px;}
  .btn_wrapper[data-v-29e8c3c6]{padding-right:0px; right: 20px;}
}
@media(max-width:1200px){
  #logo{left:50px}
}
@media(max-width: 1275px){
  .menu {right: 225px;}
}
@media(max-width: 863px){
  .menu {right: 165px;}
}

</style>