import Vue from 'vue'
import VueRouter from 'vue-router'
import Reviews from '../views/Reviews.vue'
import stripe from '../components/stripe.vue'
import signup from '../views/signup.vue'
import login from '../components/login.vue'
import pricing from '../views/Newpricing.vue'
import Home2 from '../views/Home2.vue'
import Lp2development from '../views/Lp2development.vue'
import multifamilylp1 from '../views/multifamilylp1.vue'
import zoom from '../views/zoom.vue'
import Realestatefinance from '../views/Realestate-finance.vue'
import Home3 from '../views/home3.vue'
import Thanks_new from '../views/thanks.vue'
// import old from '../views/home.vue'
import course_index from '../components/courses/index.vue'
import Course from '../components/courses/item.vue'
import terms from '../components/terms.vue'
import privacy from '../components/privacy.vue'
import thanks from '../components/thanks.vue'
import calendly from '../components/calendly.vue'
import thanksFull from '../views/thanksFull.vue'
import aboutUs from '../views/aboutUs.vue'
import instructors from '../views/instructors.vue'
import instructor from '../views/instructor.vue'
import blog from '../views/blog.vue'
import article from '../views/article.vue'
// import sample from '../components/videos.vue'
// import stripe from  '../components/stripe.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/Reviews', name: 'Reviews', props: { hideSideBars:false, hideLogin:false }, component: Reviews },
  { path: '/stripe', name: 'stripe', props: { hideSideBars:false, hideLogin:false }, component: stripe },
  { path: '/lp1', name: 'Home2', props: { hideSideBars:false, hideLogin:true }, component: Home2 },
  // { path: '', name: 'Home2', props: { hideSideBars:false, hideLogin:true }, component: Home2 },
  { path: '/Lp2development', name: 'Lp2development', props: { hideSideBars:false, hideLogin:true }, component: Lp2development },
  { path: '/multifamilylp1', name: 'multifamilylp1', props: { hideSideBars:false, hideLogin:true }, component: multifamilylp1 },
  { path: '/Realestate-finance', name: 'Realestate-finance', props: { hideSideBars:false, hideLogin:true }, component: Realestatefinance },
  { path: '/zoom', name: 'zoom', props: { hideSideBars:false, hideLogin:true }, component: zoom },
  { path: '/', name: 'Home3', props: { hideSideBars:false, hideLogin:true }, component: Home3 },
  { path: '/thanksleadgen', name: 'thanksFull', props: { hideSideBars:false, hideLogin:true }, component: thanksFull },
  { path: '/pricing', name: 'pricing', props: { hideSideBars:false, hideLogin:true }, component: pricing },
  { path: '/thanks', name: 'Thanks_new', props: { hideSideBars:false, hideLogin:true }, component: Thanks_new },
  { path: '/thanks_multifamilylp1', name: 'Thanks_new', props: { hideSideBars:false, hideLogin:true }, component: Thanks_new },
  { path: '/thanks_Realestate_finance', name: 'Thanks_new', props: { hideSideBars:false, hideLogin:true }, component: Thanks_new },
  { path: '/about-us', name: 'about', props: { hideSideBars:false, hideLogin:true }, component: aboutUs },
  { path: '/instructors', name: 'instructors', props: { hideSideBars:false, hideLogin:true }, component: instructors },
  { path: '/instructors/:id', name: 'instructor', props: { hideSideBars:false, hideLogin:true }, component: instructor },
  { path: '/Resources', name: 'blog', props: { hideSideBars:false, hideLogin:true }, component: blog },
  { path: '/Resources/:id', name: 'article', props: { hideSideBars:false, hideLogin:true }, component: article },
  { path: '/signup/year', name: 'signup', props: { hideSideBars:false, hideLogin:true }, component: signup },
  { path: '/signup/month', name: 'signup_month', props: { hideSideBars:false, hideLogin:true }, component: signup },
  { path: '/privacy', name: 'privacy', props: { hideSideBars:false, hideLogin:false }, component: privacy },
  { path: '/thank-you-yearly', name: 'thanks/annualy', props: { hideSideBars:false, hideLogin:false }, component: thanks },
  { path: '/thank-you-calendly', name: 'thanks', props: { hideSideBars:false, hideLogin:false }, component: thanks },
  { path: '/calendly', name: 'calendly', props: { hideSideBars:false, hideLogin:false }, component: calendly },
  { path: '/thank-you-yearly-online', name: '/thank-you-yearly-online', props: { hideSideBars:false, hideLogin:false }, component: thanks },
  { path: '/terms', name: 'terms', props: { hideSideBars:false, hideLogin:false }, component: terms },
  { path: '/login', name: 'login', props: { hideSideBars:false, hideLogin:false }, component: login },
  { path: '/course_index/:id', name: 'course_index', props: { hideSideBars:false, hideLogin:false  }, component: course_index },
  { path: '/Course/:id', name: 'course', props: { hideSideBars:true , hideLogin:false}, component: Course },

  {path: '/about',name: 'About',component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
