<template v-if="loaded">
    <div id="app" :class="show_side_menu ? 'menu_open' : ''">
        <div id="header_mobile" v-if="is_mobile()">
            <a class="logo" href="/"></a>
            <div id="menu_hamburger" v-on:click="show_side_menu = !show_side_menu">
                <div id="nav-icon3">
                <span></span><span></span><span></span><span></span>
                </div>
            </div>
            <a id="mobile_logo" href="/"></a>
        </div>
        <div id="side_menu" v-if="is_mobile()">
            <ul class="sharing_list">
                <div  v-for="item in menu_homepage"  :key="item.id" >
                    <li class="menu" @click="scroll(item.selector);show_side_menu = false;" v-if="!item.link">
                        <a v-text="item.name"></a>
                    </li>
                    <li class="menu"  v-else>
                        <a :href="item.url" v-text="item.name"></a>
                    </li>
                </div>
                <li><a @click="$emit('pop_up_contact')">Signup</a></li>
            </ul>
        </div>
        <div id="home_page">
            <Header
            @scrollTo="scroll($event)"
            :menu_homepage="menu_homepage"
            :homepage="true"
            v-if="!is_mobile()"
            @sign="$emit('pop_up_contact')"
        />

    
            <Hero :hero="hero"/>

            <LogosComps/>

            <AboutUs :about="about"  @sign="$emit('pop_up_contact')"/>

            <LecturersHome :lecturers="lecturers" />


            

           <lecturerswidget :logos="logos_widget" :items="items"  />
            <ComingCourses/>
            <EstateInfo/>

            <Reviews :reviews="reviews" />

            <Logos/>

            <Learn :learn="learn"/>

            <!-- <Resources/> -->

            <Events/>


            <FooterHome/>

        </div>
    </div>
</template>
<script>
import Header from "../components/header.vue";
import Hero from "../components/main-home/dark_hero.vue";
import LogosComps from "../components/main-home/logosComps.vue";
import AboutUs from "../components/main-home/aboutUs.vue";
import lecturerswidget from "../components/lecturers_widget.vue";
import LecturersHome from "../components/main-home/lecturersHome.vue";
import ComingCourses from "../components/main-home/comingCourses.vue";
import EstateInfo from "../components/main-home/estateInfo.vue";
import Reviews from "../components/main-home/reviews.vue";
import Logos from "../components/main-home/logos.vue";
import Learn from "../components/main-home/learn.vue";
// import Resources from "../components/main-home/resources.vue";
import Events from "../components/main-home/events.vue";
import FooterHome from "../components/main-home/footerHome.vue";
    export default {
        components:{
            lecturerswidget,
            Header,
            Hero,
            LogosComps,
            AboutUs,
            LecturersHome,
            ComingCourses,
            EstateInfo,
            Reviews,
            Logos,
            Learn,
            // Resources,
            Events,
            FooterHome,
        },
        props: ["menu_homepage"],
        metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    pay_link: "",
    title: "Real Estate Decvelopment Master Class ",
    // all titles will be injected into this template
    titleTemplate: "Real Estate Development Master Class",
    meta: [
      { name: "description", content: "Real Estate Development Master Class " },
      {
        property: "og:title",
        content: "Real Estate Development Master Class ",
      },
      { property: "og:site_name", content: "urecenter" },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://urecenter.com/img/logo.6874f727.png",
      },
      { name: "robots", content: "index,follow" },
    ],
  },
        data() {
            return {
                dialog:true,
                loaded: false,
                show_side_menu: false,
                hero: [],
                about:[],
                lecturers:[],
                reviews:[],
                logos:[],
                learn:[],
                items:[],
                logos_widget:[],
                
            };
        },
        mounted(){
            var self = this;
            this.net_api({ action: "general" }, function(data) {
            self.logos = data.data.logos;
            self.reviews = data.data.reviews;
            self.lecturers = data.data.lecturers;
            self.logos_widget = data.data.logos_widget;
            self.items = data.data.items;
           
            })
            
        }
    }
</script>
<style lang="scss" scoped>
.home_page{ max-width: 100%; overflow: hidden;}
#header_mobile {
  position: fixed;
  height: 50px;
  top: 0;
  background: #fff;
  left: 0;
  width: 100%;
  z-index: 99999;
}

.sign {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  background: #1480dc;
  width: 180px;
  height: 40px;
  margin: 0 auto;
  border-radius: 4px;
  color: #fff;
  line-height: 42px;
  text-transform: uppercase;
  margin-top: 10px;
}
.sign:hover {
  background: #13505b !important;
}

li[data-v-29e8c3c6]:hover {
  color: #13505b;
}


</style>